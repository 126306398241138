import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, Pagination, TextField } from "@mui/material";
import { useGetProfile } from "api/users";
import { isAuthorized, RoleEnum } from "helpers/isAuthorized";
import { useMemo, useState } from "react";
import { useGetOrganizationsWithAssetsAndProjects } from "../../api/organizations";
import Loader from "../../components/Loader";
import TitleWithAddButton from "../../components/TitleWithAddButton";
import useDebounce from "../../hooks/useDebounce";
import AddOrganizationModal from "./AddOrganizationModal";
import OrganizationsList from "./OrganizationsList";

const PAGE_SIZE = 7;

type Props = {};

const Organizations = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query.toLowerCase());

  const { data: organizations, isLoading } = useGetOrganizationsWithAssetsAndProjects();
  const { data: profile } = useGetProfile();

  const filteredOrganizations = useMemo(
    () =>
      organizations?.filter((organization) => {
        return (
          organization.name
            .toLowerCase()
            .includes(debouncedQuery.toLowerCase()) ||
          organization.assets.some((asset) =>
            asset.assetName.toLowerCase().includes(debouncedQuery.toLowerCase())
          ) ||
          organization.assets.some((asset) =>
            asset.projects.some((project) =>
              project.name.toLowerCase().includes(debouncedQuery.toLowerCase())
            )
          )
        );
      }) || [],
    [organizations, debouncedQuery]
  );

  const orgLength = useMemo(() => organizations?.length || 1, [organizations]);

  const pages = Math.ceil(filteredOrganizations.length / PAGE_SIZE);
  const startIndex = page * PAGE_SIZE - PAGE_SIZE;
  const endIndex = Math.min(startIndex + PAGE_SIZE, orgLength);

  const currentPageOrganizations = useMemo(
    () => filteredOrganizations.slice(startIndex, endIndex),
    [filteredOrganizations, startIndex, endIndex]
  );

  if (isLoading || !profile) {
    return <Loader />;
  }

  return (
    <div data-testid="Organizations">
      <TitleWithAddButton
        title={"Organizations"}
        textUnderTitle={"Add and manage organizations"}
        modal={
          <AddOrganizationModal
            isOpen={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
          />
        }
        openModal={() => setIsModalOpen(true)}
        buttonText={"Add organization"}
        isButtonVisible={isAuthorized({
          role: profile.roleName as RoleEnum,
          requiredRoles: [RoleEnum.Administrator, RoleEnum.OrganizationOwner]
        })}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",

          justifyContent: "flex-end",
          marginBottom: "0.5rem"
        }}
      >
        <Pagination
          count={pages}
          page={page}
          onChange={(_, page) => setPage(page)}
        />
        <TextField
          size="small"
          value={query}
          onChange={(e: { target: { value: string; }; }) => {
            setQuery(e.target.value);
            setPage(1);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </Box>

      {orgLength === 0 && (
        <Box sx={{ textAlign: "center" }}>
          No organizations have been added yet.
        </Box>
      )}

      {orgLength !== 0 && currentPageOrganizations.length === 0 && (
        <Box sx={{ textAlign: "center" }}>
          No organizations match filter criteria.
        </Box>
      )}

      <OrganizationsList organizations={currentPageOrganizations} />
    </div>
  );
};

export default Organizations;
