import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { AddRiskRankDto, UpdateRiskRankDto } from "types";
import { handleError } from "../helpers/handleError";

export type CustomRiskRankDto = {
  id: number;
  index: number;
  name: string;
  description: string;
  color: string;
};

export const addCustomRiskRank = async (
  projectId: string,
  newCustomRiskRanking: AddRiskRankDto
) => {
  const { data: customRiskRanking } = await axios.post<CustomRiskRankDto>(
    `/api/projects/${projectId}/matrixes/selected/riskranks`,
    newCustomRiskRanking
  );
  return customRiskRanking;
};

export const useAddCustomRiskRank = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (newCustomRiskRanking: AddRiskRankDto) =>
      addCustomRiskRank(projectId, newCustomRiskRanking),
    {
      onError: (error: { response: { data?: { Index: string } } }) => {
        toast.error(
          `Error: ${
            error.response?.data?.Index ||
            "Something went wrong. Check the data you entered."
          }`,
          { autoClose: false, hideProgressBar: true }
        );
      },
      onSuccess: () => {
        toast.success("Successfully added new custom risk rank.");
        queryClient.invalidateQueries(["matrix-risk-ranks", projectId]);
      }
    }
  );
};

export const deleteRiskRank = async (
  projectId: string,
  riskRankId: number
) => {};

export const useDeleteRiskRank = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (riskRankId: number) => {
      const { data: isSuccess } = await axios.delete<boolean>(
        `/api/projects/${projectId}/matrixes/selected/riskranks/${riskRankId}`
      );
      return isSuccess;
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully deleted risk rank.");
        queryClient.invalidateQueries(["matrix-risk-ranks", projectId]);
      }
    }
  );
};

export const useEditRiskRank = (projectId: string, riskRankId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (updatedRiskRank: UpdateRiskRankDto) => {
      const { data: isSuccess } = await axios.put<boolean>(
        `/api/projects/${projectId}/matrixes/selected/riskranks/${riskRankId}`,
        updatedRiskRank
      );
      return isSuccess;
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully edited risk rank.");
        queryClient.invalidateQueries(["matrix-risk-ranks", projectId]);
      }
    }
  );
};
