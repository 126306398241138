import { Grid, TextField } from "@mui/material";
import { useAddHazopColumn, useGetHazopColumns } from "api/hazop";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { validCharacters } from "helpers/commonHelpers";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import ModalForm from "components/ModalForm";
import { useState } from "react";

export const HazopColumnSchema = z.object({
  title: z
    .string({ required_error: "Title is required" })
    .refine(
      (value) => validCharacters(value),
      "Title should contain only alphanumerical characters and &,- or ()"
    )
});

export type NewHazopColumn = z.infer<typeof HazopColumnSchema>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

type RouteParams = {
  projectId: string;
  assetId: string;
};

const AddHazopColumn = ({ isOpen, handleClose }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<NewHazopColumn>({
    resolver: zodResolver(HazopColumnSchema)
  });
  const { projectId } = useParams<RouteParams>() as RouteParams;
  const { data: hazopColumns } = useGetHazopColumns(projectId);
  const { mutateAsync, isLoading } = useAddHazopColumn(projectId);
  const [duplicationError, setDuplicationError] = useState<string | undefined>();

  return (
    <ModalForm
      title={"Add PHA column"}
      isOpen={isOpen}
      handleClose={() => { reset(); handleClose(); }}
      onSubmit={handleSubmit(async (newHazopColumn) => {
        if (hazopColumns?.map(col => col.title.toLocaleLowerCase()).includes(newHazopColumn.title.toLocaleLowerCase())) {
          setDuplicationError('Column name must by unique in PHA table');
        } else {
          setDuplicationError(undefined);
          await mutateAsync(newHazopColumn);
          reset();
          handleClose();
        }
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("title")}
            error={!!(errors.title?.message || duplicationError)}
            helperText={errors.title?.message || duplicationError}
            fullWidth
            label="Title"
          />
        </Grid>
      </Grid>
    </ModalForm >
  );
};

export default AddHazopColumn;
