import { Autocomplete, TextField } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useEffect, useMemo, useState } from "react";
import { Link, useMatches, useParams } from "react-router-dom";

import { useGetProjects } from "../../api/projects";

type RouteProps = {
  projectId: string;
  assetId: string;
};

const ProjectsBreadcrumbs = () => {
  const { projectId, assetId } = useParams<{ projectId: string; assetId: string; }>() as RouteProps;

  const { data: projects, isLoading } = useGetProjects(assetId);
  const matches = useMatches();
  const [option, setOption] = useState(null);

  const options = useMemo(() => {
    return (
      projects?.map((project) => ({ label: project.name, id: project.id })) ||
      []
    );
  }, [projects]);

  useEffect(() => {
    const optionByUrlId =
      options.find(
        (option) => option.id === Number.parseInt(projectId as string)
      ) ?? null;
    // @ts-ignore
    setOption(optionByUrlId);
  }, [options, projectId]);

  const crumbs = matches
    // @ts-ignore
    .filter((match) => Boolean(match.handle?.crumb))
    // @ts-ignore
    .map((match) => match.handle.crumb);

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{ marginBottom: "1rem", marginTop: "1rem" }}
    >
      <Link to="/">Home</Link>
      {!isLoading && (
        <Autocomplete
          id="combo-box"
          size="small"
          sx={{ width: "300px" }}
          options={options}
          renderInput={(params) => <TextField {...params} label="Project" />}
          renderOption={(param, option) => (
            <Link
              to={`/assets/${assetId}/projects/${option.id}`}
              key={option.id}
            >
              <li {...param}>{option.label}</li>
            </Link>
          )}
          // @ts-ignore
          value={option}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          // @ts-ignore
          onChange={(_, option) => setOption(option)}
          disableClearable
        />
      )}
      {crumbs.map((crumb) => (
        <p key={crumb}>{crumb}</p>
      ))}
    </Breadcrumbs>
  );
};

export default ProjectsBreadcrumbs;
