import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useInviteUser } from "../../api/users";
import ModalForm from "../../components/ModalForm";

const UserSchema = z.object({
  email: z.string().max(256)
});

type NewUserType = z.infer<typeof UserSchema>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

const InviteUserModal = ({ isOpen, handleClose }: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset
  } = useForm<NewUserType>({
    resolver: zodResolver(UserSchema),
    defaultValues: {
      email: ""
    }
  });
  const { mutateAsync: inviteUser, isLoading } = useInviteUser();

  return (
    <ModalForm
      title={"Invite user"}
      isOpen={isOpen}
      handleClose={() => { reset(); handleClose(); }}
      onSubmit={handleSubmit(async ({ email }) => {
        await inviteUser(email);
        reset();
        handleClose();
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("email")}
            error={!!errors.email?.message}
            helperText={errors.email?.message}
            label={`Email *`}
            fullWidth
          />
        </Grid>
      </Grid>
    </ModalForm >
  );
};

export default InviteUserModal;
