import { zodResolver } from "@hookform/resolvers/zod";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { useGetUploadedFiles } from "api/document-categories";
import { useAddNode } from "api/node-analysis";
import Loader from "components/Loader";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import ModalForm from "../../components/ModalForm";

const NodeSchema = z.object({
  name: z.string().min(1, "Node name is required").max(256),
  designIntention: z.string().min(1, "Design intention is required"),
  operatingConditions: z
    .string()
    .min(1, "Operating Conditions is required"),
  comments: z.string().optional(),
  drawings: z
    .object({
      id: z.number(),
      name: z.string()
    })
    .array()
  // .min(1, "At least 1 drawings needs to be selected")
});

type NewNode = z.infer<typeof NodeSchema>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

type RouteParams = {
  projectId: string;
};

export const PID_ID = "2";

const AddNodeModal = ({ isOpen, handleClose }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm<NewNode>({
    resolver: zodResolver(NodeSchema),
  });
  const { projectId } = useParams<RouteParams>() as RouteParams;
  const { mutateAsync, isLoading } = useAddNode(projectId);
  const { data: drawings, isLoading: isLoadingDrawings } = useGetUploadedFiles(
    projectId,
    PID_ID
  );

  if (isLoadingDrawings) {
    return <Loader />;
  }

  if (!drawings) {
    return <Typography>No drawings</Typography>;
  }

  return (
    <ModalForm
      title={"Add node"}
      isOpen={isOpen}
      handleClose={() => { reset(); handleClose(); }}
      onSubmit={handleSubmit(async (newNode) => {
        const { drawings, ...newNodeRest } = newNode;
        const fileIds = newNode.drawings.map((drawing) => drawing.id);
        await mutateAsync({ ...newNodeRest, nodeIdentifier: '', fileIds });
        reset();
        handleClose();
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            label={`Node name *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("designIntention")}
            error={!!errors.designIntention?.message}
            helperText={errors.designIntention?.message}
            label={`Design intention`}
            fullWidth
            multiline
            minRows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("operatingConditions")}
            error={!!errors.operatingConditions?.message}
            helperText={errors.operatingConditions?.message}
            label={`Operating conditions`}
            fullWidth
            multiline
            minRows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="drawings"
            defaultValue={[]}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState
            }) => {
              return (
                <Autocomplete
                  multiple
                  onChange={(event, newDrawings) => {
                    onChange(newDrawings);
                  }}
                  value={value}
                  sx={{ marginBottom: "0.5rem" }}
                  options={drawings.map((d) => ({
                    id: d.id,
                    name: d.fileName
                  }))}
                  defaultValue={[]}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Drawings"
                        error={!!errors.drawings}
                        helperText={errors.drawings?.message}
                      />
                    );
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("comments")}
            error={!!errors.comments?.message}
            helperText={errors.comments?.message}
            label={`Comment`}
            fullWidth
          />
        </Grid>
      </Grid>
    </ModalForm >
  );
};

export default AddNodeModal;
