import { useGetAsset } from "api/assets";
import { useGetProfile } from "api/users";
import { isAuthorized, RoleEnum, rolePrioritization } from "helpers/isAuthorized";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetDeviations } from "../../../api/deviations";
import Loader from "../../../components/Loader";
import TitleWithAddButton from "../../../components/TitleWithAddButton";
import AddDeviationModal from "./AddDeviationModal";
import DraggableTable from "./DraggableTable";

type Props = {};

type RouteParams = {
  assetId: string;
  projectId: string;
};

const Deviations = (props: Props) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const { assetId, projectId } = useParams<RouteParams>() as RouteParams;
  const { data: deviations, isFetching } = useGetDeviations(projectId);
  const { data: profile } = useGetProfile();
  const { data: asset } = useGetAsset(assetId);

  const organizationRoles: RoleEnum[] = useMemo(() =>
    rolePrioritization([], profile?.organizationRoles, asset?.organizationId)
    , [asset, profile])


  if (isFetching || !deviations || !profile || !asset) {
    return <Loader />;
  }

  const isEditable = isAuthorized({
    role: profile.roleName as RoleEnum,
    organizationRoles: organizationRoles,
    requiredRoles: [RoleEnum.Administrator, RoleEnum.OrganizationOwner]
  });

  return (
    <>
      <TitleWithAddButton
        title={"Deviations list"}
        textUnderTitle={""}
        modal={
          <AddDeviationModal
            isOpen={isAddModalOpen}
            handleClose={() => setIsAddModalOpen(false)}
          />
        }
        openModal={() => setIsAddModalOpen(true)}
        buttonText={"Add deviation"}
        isButtonVisible={isEditable}
      />
      <DraggableTable deviations={deviations} isEditable={isEditable} />
    </>
  );
};

export default Deviations;
