import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowsProp
} from "@mui/x-data-grid";
import { useGetAsset } from "api/assets";
import { useGetProfile } from "api/users";
import Loader from "components/Loader";
import { isAuthorized, RoleEnum, rolePrioritization } from "helpers/isAuthorized";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDeleteTimeframe, useGetTimeFrames } from "../../api/timeframes";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { formatDate } from "../../helpers/commonHelpers";
import { TimeframeDto } from "../../types";
import EditTimeframeModal from "./EditTimeframeModal";

type RouteParams = {
  assetId: string;
  projectId: string;
};

type Props = {};

const WorkshopTimeFramesDataGrid = (props: Props) => {
  const { assetId, projectId } = useParams<RouteParams>() as RouteParams;
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedTimeframe, setSelectedTimeframe] =
    useState<TimeframeDto | null>(null);

  const { data: profile } = useGetProfile();
  const { data: asset } = useGetAsset(assetId);
  const { data: timeframes } = useGetTimeFrames(projectId);
  const { mutateAsync } = useDeleteTimeframe(projectId);

  const rows: GridRowsProp =
    timeframes?.map((timeframe) => ({
      id: timeframe.id,
      address: timeframe.address,
      description: timeframe.description,
      startDate: formatDate(timeframe.startDate),
      endDate: formatDate(timeframe.endDate),
      duration: timeframe.duration
    })) ?? [];

  const organizationRoles: RoleEnum[] = useMemo(() =>
    rolePrioritization([], profile?.organizationRoles, asset?.organizationId)
    , [asset, profile])


  if (!profile || !asset) return <Loader />;

  const columns: GridColDef[] = [
    {
      flex: 1,
      field: "description",
      headerName: "Description"
    },
    {
      flex: 1,
      field: "startDate",
      headerName: "Start date"
    },
    {
      flex: 1,
      field: "endDate",
      headerName: "End date"
    },
    {
      flex: 1,
      field: "duration",
      headerName: "Duration",
      valueGetter: (value) => `${value} days`
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) =>
        [
          // @ts-ignore
          <GridActionsCellItem
            icon={<DeleteIcon />}
            onClick={() => {
              setIsDeleteConfirmationOpen(true);
              setSelectedTimeframe(params.row);
            }}
            label="Delete"
          />,
          // @ts-ignore
          <GridActionsCellItem
            icon={<EditIcon />}
            onClick={() => {
              setIsEditModalOpen(true);
              setSelectedTimeframe(params.row);
            }}
            label="Edit"
          />
        ].filter((x) =>
          isAuthorized({
            role: profile?.roleName as RoleEnum,
            requiredRoles: [RoleEnum.Administrator, RoleEnum.OrganizationOwner],
            organizationRoles
          })
        )
    }
  ];

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        disableRowSelectionOnClick
      />

      {isEditModalOpen && selectedTimeframe && (
        <EditTimeframeModal
          isOpen={isEditModalOpen}
          handleClose={() => setIsEditModalOpen(false)}
          timeframe={selectedTimeframe}
        />
      )}

      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        handleClose={() => setIsDeleteConfirmationOpen(false)}
        title={"Delete timeframe"}
        text={"Are you sure you want to delete timeframe?"}
        onAgree={() => {
          mutateAsync(selectedTimeframe!.id);
          setIsDeleteConfirmationOpen(false);
        }}
      />
    </div>
  );
};

export default WorkshopTimeFramesDataGrid;
