import './HazopTable.css';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import DeselectIcon from '@mui/icons-material/Deselect';


import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import {
  useAddCauses, useDeleteCauses, usePatchCause,
  useGetHazopColumns, useGetHazopRows,
  useAddConsequences, useDeleteConsequences, usePatchConsequences, useCopyCause,
  consequenceRecommendation,
  useEditHazopRecommendation,
  useAddHazopRecommendation,
  useDeleteHazopRecommendation
} from "api/hazop";
import Loader from "components/Loader";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { useGetNodes } from "api/node-analysis";

import { useGetSelectedMatrix, useGetSelectedMatrixConsequenceCategories, useGetSelectedMatrixRiskRanks } from "api/matrixes";
import { useGetSelectedMatrixLikelihoods } from "api/likelihood";
import { useGetSelectedSeverity } from "api/severity";
import { useGetMatrixClassifications } from "api/classifications";
import { RoleEnum, isAuthorized, rolePrioritization } from "helpers/isAuthorized";
import { useGetProfile, useGetProjectProfile } from "api/users";
import { useGetAsset } from "api/assets";
import { titleCorrection } from 'modules/study-setup/hazop-setup/HazopSetupTable';
import EditRecommendationModal from './EditRecommendationModal';

interface ColumnProps {
  id: number;
  mandatory: boolean;
  selected: boolean;
  title: string;
  dependant: boolean;
  isGrouped: boolean;
}

const getBackgroundColor = (dependant: boolean, mandatory: boolean) => {
  if (dependant) return "#FCF8E6";
  if (mandatory) return "#F3F2F2";
  return "#FFF";
};

// Custom column component
const Column: React.FC<ColumnProps> = ({
  mandatory,
  title,
  dependant,
  isGrouped
}) => {
  return (
    <TableCell
      sx={{
        whiteSpace: "nowrap",
        backgroundColor: getBackgroundColor(dependant, mandatory),
        cursor: "pointer",
        minWidth: "200px"
      }}
      align="center"
      colSpan={isGrouped ? 3 : 1}
    >
      <Typography sx={{ fontSize: "1.2rem" }}>{title}</Typography>
    </TableCell>
  );
};
const RecommendationList: React.FC<any> = (props) => {
  const { data, isSelected, onClick, onDelete } = props;

  if (!isSelected && data.length === 0) return <> - </>;
  return <><List sx={{ margin: isSelected ? '0px 4px' : '-8px -4px', padding: 0 }}> {data.map((recommendation: any, key: number) =>
    <ListItem key={`rec-${key}`}
      sx={{ paddingLeft: 0, paddingRight: 0 }} divider={data.length - 1 !== key}><div style={{ width: '100%', position: 'relative' }}>
        <div onClick={onClick} data-id={recommendation.id}>
          R{recommendation.number} - {recommendation.name}
          <br />
          {recommendation.partyResponsible && <small>for <span style={{ fontWeight: 'bold' }}>
            {recommendation.partyResponsible}
          </span></small>}
        </div>
        {isSelected && <IconButton aria-label="add" size="small"
          onClick={() => onDelete(recommendation.id)} style={{ position: 'absolute', right: '-4px', bottom: '-10px' }}
          data-id={recommendation.id}><DeleteOutlinedIcon /></IconButton>}
      </div>
    </ListItem>)}
  </List>
    {isSelected && <IconButton aria-label="add" size="small" onClick={onClick} style={{ float: 'right' }} data-id={-1}><AddCircleOutlineOutlinedIcon /></IconButton>}</>
}
type Type3ListElementProps = {
  areChangesInProgress: boolean,
  columnId: number,
  columnValue: string[],
  isSelected: boolean,
  suggestions: string[],
  save: (newData: string[], resetSection: boolean) => any
}

const Type3ListElement: React.FC<Type3ListElementProps> = (
  { areChangesInProgress, columnId, columnValue, isSelected, suggestions, save }
) => {
  if (isSelected) return <List sx={{ margin: '-8px -4px', padding: 0 }}>{
    columnValue.map((val, key) =>
      <ListItem key={`${columnId}-dev_${key}`} sx={{ padding: '4px 0' }} divider={true}>
        <Autocomplete
          options={suggestions}
          defaultValue={val}
          style={{ width: '100%' }}
          renderInput={(params) =>
            <TextField {...params} InputProps={{
              ...params.InputProps, spellCheck: true,
            }} size="small" multiline onBlur={(event: { currentTarget: { value: any; }; }) => {
              const currentValue = event.currentTarget.value;
              save([...columnValue.slice(0, key), currentValue, ...columnValue.slice(key + 1)], false);
            }} />} />
        {key > 0 ?
          <IconButton tabIndex={-1} aria-label="delete" size="small" disabled={key === 0 || areChangesInProgress}
            onClick={() => {
              columnValue.splice(key, 1);
              save(columnValue, false);
            }}
          ><DeleteOutlinedIcon /></IconButton>
          :
          <IconButton tabIndex={-1} aria-label="close" size="small"
            onClick={() => save(columnValue, true)}
          ><CancelOutlinedIcon /></IconButton>
        }
      </ListItem>
    )}
    <ListItem sx={{ justifyContent: "end", padding: '4px 0' }}>
      <IconButton aria-label="add" size="small" disabled={areChangesInProgress}
        onClick={() => save([...columnValue, ''], false)}
      ><AddCircleOutlineOutlinedIcon /></IconButton>
    </ListItem>
  </List >

  // normal list

  return <List sx={{ margin: '-8px -4px', padding: 0 }}>{
    columnValue.map((val, key) =>
      <ListItem key={`${columnId}-list_key_${key}`} sx={{ padding: '4px 0' }} divider={columnValue.length - 1 !== key}>
        {val ? val : ' - '}
      </ListItem>
    )
  }</List>
}

//
// Inner hooks
//

const useLikelihoodsMapGen = (projectId: string) => {
  const { data: likelihoods } = useGetSelectedMatrixLikelihoods(projectId);

  const likelihoodsMap = useMemo(() => Object.fromEntries(likelihoods?.map(likelihood => [likelihood.index, likelihood.id]) || [])
    , [likelihoods])

  const likelihoodsMin = useMemo(() => Math.min(...(Object.keys(likelihoodsMap).map(key => parseInt(key)))), [likelihoodsMap]);
  const likelihoodsMax = useMemo(() => Math.max(...Object.keys(likelihoodsMap).map(key => parseInt(key))), [likelihoodsMap]);

  if (Object.keys(likelihoodsMap).length === 0) {
    return {
      likelihoodsMap: Object.fromEntries([[0, '0']]),
      likelihoodsMin: 0,
      likelihoodsMax: 0
    };
  }
  return { likelihoodsMap, likelihoodsMin, likelihoodsMax }
}

const useSeveritiesMapGen = (projectId: string) => {
  const { data: severities } = useGetSelectedSeverity(projectId);

  const severitiesMap = useMemo(() => Object.fromEntries(severities?.map(severity => [severity.index, severity.id]) || [])
    , [severities])
  const severitiesMin = useMemo(() => Math.min(...(Object.keys(severitiesMap).map(key => parseInt(key)))), [severitiesMap]);
  const severitiesMax = useMemo(() => Math.max(...Object.keys(severitiesMap).map(key => parseInt(key))), [severitiesMap]);

  if (Object.keys(severitiesMap).length === 0) {
    return {
      severitiesMap: Object.fromEntries([[0, '0']]),
      severitiesMin: 0,
      severitiesMax: 0
    };
  }
  return { severitiesMap, severitiesMin, severitiesMax }
}

export const useClassificationsObjGen = (projectId: string) => {
  const { data: riskRanks } = useGetSelectedMatrixRiskRanks(projectId);
  const { data: selectedMatrix } = useGetSelectedMatrix(projectId);
  const { data: classifications } = useGetMatrixClassifications(
    projectId,
    selectedMatrix?.id
  );

  const riskRanksMap = useMemo(() => Object.fromEntries(
    riskRanks?.map((riskRank) => [riskRank.id, { name: riskRank.name, color: riskRank.color }]) || []
  ), [riskRanks])

  const riskRankColorsMap = useMemo(() => Object.fromEntries(
    riskRanks?.map((riskRank) => [riskRank.name, riskRank.color]) || []
  ), [riskRanks])

  const classificationsMap = useMemo(() =>
    Object.fromEntries(
      classifications?.map(classification => {
        return [
          `${classification.severityId}+${classification.likelihoodId}`,
          riskRanksMap?.[classification.riskRankId] || { name: '-', color: '#FFF' }
        ]
      }) || [])
    , [classifications, riskRanksMap])

  return { riskRankColorsMap, classificationsMap }
}

//
// Inner hooks -- end
//

type RouteProps = {
  assetId: string;
  projectId: string;
  nodeId: string;
};

// Table component
const HazopTable = () => {
  // const [sortedHeaders, setSortedColumns] = useState(columns);
  const { assetId, projectId, nodeId } = useParams<RouteProps>() as RouteProps;
  const { data: nodes, isLoading: isLoadingNodes } = useGetNodes(projectId);
  const { data: hazopColumns, isLoading } = useGetHazopColumns(projectId);

  const tableRef = useRef<HTMLTableElement>(null);
  const tableHeight = useRef(450)

  const { mutateAsync: addCause } = useAddCauses(projectId);
  const { mutateAsync: patchCause } = usePatchCause(projectId);
  const { mutateAsync: deleteCause } = useDeleteCauses(projectId);
  const { mutateAsync: copyCause } = useCopyCause(projectId);

  const { mutateAsync: addConsequence } = useAddConsequences(projectId);
  const { mutateAsync: patchConsequences } = usePatchConsequences(projectId);
  const { mutateAsync: deleteConsequence } = useDeleteConsequences(projectId);

  const { mutateAsync: addRecommendation, isLoading: isRecommendationLoading } = useAddHazopRecommendation(projectId);
  const { mutateAsync: patchRecommendation, isLoading: isPatchRecommendationLoading } = useEditHazopRecommendation(projectId);
  const { mutateAsync: deleteRecommendation } = useDeleteHazopRecommendation(projectId);

  const { data: hazopRowsForProject, isFetching: isLoadingHazopRows } = useGetHazopRows(projectId);
  const { data: consequenceCategories, isLoading: isLoadingConsequenceCategories } = useGetSelectedMatrixConsequenceCategories(projectId);

  const { likelihoodsMap, likelihoodsMin, likelihoodsMax } = useLikelihoodsMapGen(projectId);
  const { severitiesMap, severitiesMin, severitiesMax } = useSeveritiesMapGen(projectId);
  const { riskRankColorsMap, classificationsMap } = useClassificationsObjGen(projectId);

  const { data: profile } = useGetProfile();
  const { data: projectProfile } = useGetProjectProfile(parseInt(projectId));
  const { data: asset } = useGetAsset(assetId);

  //
  tableHeight.current = tableRef.current ? tableRef.current.offsetHeight : tableHeight.current;

  const onEditClose = (reset: boolean = true) => {
    sessionStorage.setItem('tableHeight', (tableBody.current?.offsetHeight || 0 + 160).toString())
    if (reset) setSelectedCell(resetSelectedCell);
  }

  const activeHazopRows = useMemo(() => { return hazopRowsForProject?.filter(r => r.nodeId === +nodeId) || [] }, [hazopRowsForProject, nodeId])
  const hazopRowsParams = useMemo(
    () => {
      const generateSpan = (idsArr: number[]): number[] =>
        idsArr.map((elem, key, arr) => {
          if (elem === null) return 1;
          if (arr[key - 1] === elem) return -1;
          return arr.filter(item => item === elem).length;
        })

      const generateSeq = (spansArr: number[]): number[] =>
        spansArr.reduce((arr, size) => {
          return [...arr, Array.from({ length: size }, (_, i) => i)];
        }, [] as number[][])
          .filter(e => e.length !== 0)
          .map((i, k) => i.map(() => k + 1))
          .flat()

      const rowSpans = generateSpan(activeHazopRows.map(row => row.rowId));
      const rowSeq = generateSeq(rowSpans);

      const causeSpans = generateSpan(activeHazopRows.map(row => row.causeId));
      const causeSeq = rowSpans.reduce((a, e, i) => {
        const singleNumberSpan = causeSpans.slice(i, i + e);
        const finalNumbers = singleNumberSpan.reduce((aa, ee, ii) => {
          const indexCorrection = aa.map(a => a.length).filter(e => e === 0).length;
          return [...aa, Array.from({ length: ee }, (_, j) => ii + 1 - indexCorrection)]
        }, [] as number[][]);
        return [...a, e === -1 ? [] : finalNumbers];
      }, [] as number[][][]).flat().flat();

      const consequenceSeq = causeSpans.reduce((a, e) => {
        return [...a, Array.from({ length: e }, (_, j) => j + 1)];
      }, [] as number[][]).flat();

      const rowIds = (activeHazopRows.map(row => row.deviationId + '-' + row.causeId + '-' + row.consequenceId))

      return { rowIds, rowSpans, rowSeq, causeSpans, causeSeq, consequenceSeq }
    },
    [activeHazopRows])

  const resetSelectedCell = '0.0.0.0.';
  const [selectedCell, setSelectedCell] = useState(resetSelectedCell);
  const [sourceCellCauseId, setSourceCellCauseId] = useState(-1);
  const [sourceCell, setSourceCell] = useState(-1);

  useEffect(() => { setSourceCell(-1); setSourceCellCauseId(-1); }, [nodeId]);

  const tableBody = useRef<HTMLTableSectionElement>(null);
  const changesInProgressFlag = useRef(false);
  const selectedCellData = useRef<{ sequencer: string, deviationId?: number, nodeId?: number, causeId?: number, consequenceId?: number, columnId?: number, columnData?: { [key: string]: string | string[] } }>({ sequencer: '0.0.0.0.' });

  useEffect(() => { if (selectedCell === resetSelectedCell) changesInProgressFlag.current = false }, [selectedCell]);

  useEffect(() => {
    const handleEsc = (event: { key: string; altKey: boolean; }) => {
      if (event.key === 'Escape') setSelectedCell(resetSelectedCell);

      if (event.key === 'Enter' && event.altKey === true && tableBody.current) {

        const focusedElem = document.querySelector(':focus') as HTMLElement;
        const { deviationId, nodeId } = selectedCellData.current;
        if (document.activeElement && document.activeElement.tagName.toLocaleLowerCase() !== 'body' && focusedElem !== null) {
          focusedElem.blur();
        } else if (deviationId !== undefined && nodeId !== undefined) {

          const { sequencer, causeId, consequenceId, columnId, columnData } = selectedCellData.current;

          if (causeId && !consequenceId) {
            const newCauseData = {
              deviationId: deviationId,
              nodeId: nodeId,
              name: '',
              sequence: +sequencer.split('.')[2] + 1
            }
            addCause(newCauseData).then(() => onEditClose())
          } else if (consequenceId && causeId && !columnId) {
            const consequenceData = {
              deviationId: deviationId,
              hazopNodeId: nodeId,
              hazopCauseId: causeId,
              name: '',
              sequence: +sequencer.split('.')[3] + 1,
              data: '{}'
            }
            addConsequence(consequenceData).then(() => onEditClose())
          } else if (consequenceId && causeId && columnId && columnData) {
            const newData = typeof columnData[columnId] === 'string' ? columnData[columnId] : [...columnData[columnId], ""];
            patchConsequences({
              hazopConsequenceData: { ...columnData, [columnId]: newData },
              consequenceId,
              nodeId
            }).then(() => onEditClose())
          }
        }
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [addCause, addConsequence, patchConsequences]);

  //   
  // Display subColumns "s" "l" and "rr"
  //

  const filteredColumns = useMemo(() => hazopColumns?.filter(({ selected, type }) => selected !== false && type !== 5) || [], [hazopColumns]);

  const secondRow = useMemo(() => filteredColumns
    ?.map((x) => {
      if (x.subColumns) {
        const values = Object.values(x.subColumns);
        return values;
      }
      return "";
    })
    .flat(), [filteredColumns]);

  const subColumns = useMemo(() => filteredColumns.slice(4)
    ?.map((col) => {
      if (col.subColumns) {
        return Object.entries(col.subColumns).map(item => {

          return {
            id: parseInt(item[0]),
            type: col.type,
            subType: item[1]
          }
        });
      }

      return [{ id: col.id, type: col.type, subType: null }];
    }).flat(), [filteredColumns]);

  const suggestionsForHazopRows = useMemo(() => {
    const subColumnsIds =
      filteredColumns.slice(4).filter(col => [1, 3].includes(col.type)).map(col => col.id);

    if (hazopRowsForProject) return {
      causes: [...new Set(hazopRowsForProject.map(row => row.causeName).filter(elem => !!elem && !['-', ' - ', '- ', ' -'].includes(elem)).sort())],
      consequences: [...new Set(hazopRowsForProject.map(row => row.consequenceName).filter(elem => !!elem && !['-', ' - ', '- ', ' -'].includes(elem)).sort())],
      customColumns: new Map(subColumnsIds.map((colId) => [colId, [...new Set(
        hazopRowsForProject.map(row => row.consequenceCustomColumns[colId]).filter(elem => !!elem && ['-', ' - ', '- ', ' -'].includes(elem) === false).flat().sort()
      )]]))
    }

    return {
      causes: [],
      consequences: [],
      customColumns: new Map(subColumnsIds.map((colId) => [colId, []]))
    }
  }, [hazopRowsForProject, filteredColumns])

  // 
  // 
  // 

  const currentNodeNumber = useMemo(() => (nodes?.map((node => node.id)).indexOf(+nodeId) || 0) + 1, [nodes, nodeId])

  const [showConfirm, setShowConfirm] = useState(0);

  const borderRightStyles = (seq: string, minifyPaddings: boolean = true, bgColor: string = '', fixedWidth: boolean = false) => {
    if (seq === selectedCell) {
      if (minifyPaddings && fixedWidth) return {
        background: '#fefcf3', borderRight: '1px solid rgba(224, 224, 224, 1)', padding: '8px', width: '90px'
      }
      if (minifyPaddings) return { background: '#fefcf3', borderRight: '1px solid rgba(224, 224, 224, 1)', padding: '8px' }
      return { background: '#fefcf3', borderRight: '1px solid rgba(224, 224, 224, 1)' }
    }

    if (bgColor !== '') return { borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: bgColor }
    return { borderRight: '1px solid rgba(224, 224, 224, 1)' }
  };

  //

  const organizationRoles: RoleEnum[] = useMemo(() =>
    rolePrioritization(projectProfile?.projectRoles, profile?.organizationRoles, asset?.organizationId)
    , [asset?.organizationId, profile?.organizationRoles, projectProfile?.projectRoles])

  const [recommendationModalData, setRecommendationModalData] = useState<consequenceRecommendation | null>(null);
  const [recommendationModalOwner, setRecommendationModalOwner] = useState<string | null>(null);

  //

  if (isLoading || !hazopColumns || isLoadingNodes || isLoadingHazopRows || isLoadingConsequenceCategories || isRecommendationLoading || isPatchRecommendationLoading) return <Box sx={{ height: sessionStorage.getItem('tableHeight') + 'px' }}><Loader /></Box>;

  const isEditable = isAuthorized({
    role: profile?.roleName as RoleEnum || RoleEnum.GlobalReader,
    organizationRoles: organizationRoles,
    requiredRoles: [RoleEnum.Facilitator, RoleEnum.Scribe, RoleEnum.OrganizationOwner, RoleEnum.Administrator]
  });

  const canDelateOrCopy = isAuthorized({
    role: profile?.roleName as RoleEnum || RoleEnum.GlobalReader,
    organizationRoles: organizationRoles,
    requiredRoles: [RoleEnum.Facilitator, RoleEnum.Scribe, RoleEnum.OrganizationOwner]
  });

  const hazopMarker = (numberOfClicks: number, sequencer: string, currentData: object = {}): void => {
    if (isEditable && numberOfClicks > 0 && changesInProgressFlag.current === false) {
      if (sequencer === selectedCell) return;
      selectedCellData.current = { sequencer, ...currentData };
      setSelectedCell(sequencer);
    }
  };

  const HazopSequencer = (arrIndex: number, nodeKey: number, deviationSeq: number[], causeSeq?: number[], consequenceSeq?: number[]) => {
    const nodeNumber = `${nodeKey}.`;
    const deviationNumber = `${deviationSeq[arrIndex]}.`;
    const coseNumber = (causeSeq) ? `${causeSeq[arrIndex]}.` : '';
    const consequenceNumber = (consequenceSeq && consequenceSeq[arrIndex]) ? `${consequenceSeq[arrIndex]}.` : '';
    // if (consequenceSeq) return consequenceNumber;
    // if (causeSeq) return coseNumber;
    return nodeNumber + deviationNumber + coseNumber + consequenceNumber;
  }

  const categoryCheck = (categoryName: string, consequenceCategories?: { name: string }[]) => {
    if (!consequenceCategories) return "";
    return consequenceCategories.map(cat => cat.name).indexOf(categoryName) > -1 ? categoryName : ""
  }

  return (<>
    <Table ref={tableRef}>
      <TableHead className='TableHead--is-sticky'>
        <TableRow>
          {filteredColumns.map((column, index) => (
            <Column
              key={`${column.id}-${index}`}
              id={column.id}
              mandatory={column.mandatory}
              selected={column.selected}
              title={titleCorrection(column.name, column.title)}
              dependant={Boolean(column.dependency)}
              isGrouped={Boolean(column.subColumns)}
            />
          ))}
        </TableRow>
        <TableRow>
          {secondRow?.map((x, index) => {
            return (
              <TableCell
                key={`cell--${index}`}
                onClick={(e: any) => hazopMarker(e.detail, e.currentTarget?.dataset?.sequencer || '')}
                data-sequencer={resetSelectedCell}
                sx={{
                  borderRight: index === secondRow.length - 1 ? '' : '1px solid rgba(224, 224, 224, 1)',
                  textAlign: "center"
                }}
              >
                {x.toUpperCase()}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>

      <TableBody ref={tableBody}>
        {activeHazopRows.map((row, indexDeviation) => {
          const causeSeq = HazopSequencer(indexDeviation, currentNodeNumber, hazopRowsParams.rowSeq, hazopRowsParams.causeSeq);
          const consequenceSeq = HazopSequencer(indexDeviation, currentNodeNumber, hazopRowsParams.rowSeq, hazopRowsParams.causeSeq, hazopRowsParams.consequenceSeq);

          const canBeCopiedTo = canDelateOrCopy && sourceCellCauseId !== -1 && sourceCellCauseId !== row.causeId &&
            [null, '', '-'].includes(row.causeName ? row.causeName.trim() : row.causeName);
          const hasNoConsequenceName = [null, '', '-'].includes(row.consequenceName ? row.consequenceName.trim() : row.consequenceName);
          const canBeConsequenceCopiedTo = canDelateOrCopy && sourceCell !== -1 && sourceCell !== row.consequenceId &&
            hasNoConsequenceName;

          return <TableRow key={'row_' + hazopRowsParams.rowIds[indexDeviation]} data-key={'row_' + hazopRowsParams.rowIds[indexDeviation]}>
            {/* deviations */}
            {hazopRowsParams.rowSpans[indexDeviation] > 0 && <TableCell
              onClick={(e: any) => hazopMarker(e.detail, e.currentTarget?.dataset?.sequencer || '')}
              data-sequencer={resetSelectedCell}
              sx={borderRightStyles('')}
              rowSpan={hazopRowsParams.rowSpans[indexDeviation]}>
              {HazopSequencer(indexDeviation, currentNodeNumber, hazopRowsParams.rowSeq)} {row.deviationName.toUpperCase()}
            </TableCell>}

            {/* cause */}
            {hazopRowsParams.causeSpans[indexDeviation] > 0 && <TableCell sx={{ ...borderRightStyles(causeSeq, true), 'position': 'relative' }}
              onClick={(e: any) => {
                if ((e.target as HTMLElement).tagName.toLowerCase() !== 'svg') {
                  hazopMarker(
                    e.detail, e.currentTarget?.dataset?.sequencer || '',
                    { deviationId: row.deviationId, nodeId: row.nodeId, causeId: row.causeId }
                  );
                }
              }}
              data-sequencer={causeSeq} data-cause-id={row.causeId}
              rowSpan={hazopRowsParams.causeSpans[indexDeviation]}
            >
              {sourceCellCauseId === row.causeId && causeSeq !== selectedCell && <SelectAllIcon sx={{ position: 'absolute', right: 0, bottom: 0, color: 'lightgray' }} />}
              {row.causeName !== null && causeSeq !== selectedCell ? <>{causeSeq} {row.causeName}</> : ''}
              {causeSeq !== selectedCell && canBeCopiedTo ? <IconButton tabIndex={-1} aria-label="paste" size="small"
                title="Paste cause from selected"
                sx={{ position: 'absolute', right: 0, top: '8px' }}
                onClick={(event) => {
                  event.preventDefault();
                  if (!row.causeId) {
                    const newCauseData = { deviationId: row.deviationId, nodeId: row.nodeId, name: '', sequence: +causeSeq.split('.')[2] + 1 };
                    addCause(newCauseData).then((cause) => {
                      if (cause.data.id)
                        copyCause({ causeId: cause.data.id, sourceCauseId: sourceCellCauseId }).then(() => onEditClose());
                      else onEditClose()
                    })
                  } else {
                    copyCause({ causeId: row.causeId, sourceCauseId: sourceCellCauseId }).then(() => onEditClose());
                  }

                  setSelectedCell(resetSelectedCell)
                }}>
                <DownloadForOfflineOutlinedIcon />
              </IconButton> : ''}
              {causeSeq === selectedCell && <Box display="flex" alignItems="center">
                <Autocomplete
                  options={suggestionsForHazopRows.causes}
                  defaultValue={row.causeName}
                  sx={{ flex: "1 1 auto" }}
                  renderInput={(params) => <TextField {...params}
                    InputProps={{
                      ...params.InputProps, spellCheck: true,
                    }}
                    size="small"
                    placeholder={'cause name'}
                    multiline minRows={2}
                    onBlur={(event: { target: { value: string; }; }) => {
                      changesInProgressFlag.current = true;
                      row.causeName = event.target.value.trim();
                      if (row.causeId) {
                        patchCause({ name: row.causeName, causeId: row.causeId.toString() }).then(() => onEditClose())
                      } else {
                        const newCauseData = {
                          deviationId: row.deviationId,
                          nodeId: row.nodeId,
                          name: row.causeName,
                          sequence: +causeSeq.split('.')[2] + 1
                        };
                        addCause(newCauseData).then(() => onEditClose())
                      }
                    }} />}
                />
                <Box sx={{ marginRight: '-8px', width: '32px' }}>
                  {canDelateOrCopy && <IconButton tabIndex={-1} aria-label="delete" size="small"
                    title="Delete"
                    disabled={row?.causeId === undefined}
                    onClick={() => {
                      changesInProgressFlag.current = true;

                      if (sourceCellCauseId === row.causeId) setSourceCellCauseId(-1);
                      deleteCause({ nodeId: row.nodeId.toString(), causeId: row.causeId.toString() }).then(() => onEditClose())
                    }}><DeleteOutlinedIcon /></IconButton>}

                  <IconButton tabIndex={-1} aria-label="add" size="small"
                    title="Add new"
                    onClick={() => {
                      const newCauseData = {
                        deviationId: row.deviationId,
                        nodeId: row.nodeId,
                        name: '',
                        sequence: +causeSeq.split('.')[2] + 1
                      }
                      addCause(newCauseData).then(() => onEditClose())
                    }}
                  ><AddCircleOutlineOutlinedIcon /></IconButton>

                  {canBeCopiedTo && sourceCellCauseId ? <IconButton tabIndex={-1} aria-label="paste" size="small"
                    title="Paste cause from selected"
                    onClick={() => {
                      setSelectedCell(resetSelectedCell)
                      if (!row.causeId) {
                        const newCauseData = { deviationId: row.deviationId, nodeId: row.nodeId, name: '', sequence: +causeSeq.split('.')[2] + 1 };
                        addCause(newCauseData).then((cause) => {
                          if (cause.data.id)
                            copyCause({ causeId: cause.data.id, sourceCauseId: sourceCellCauseId }).then(() => onEditClose());
                          else onEditClose()
                        })
                      } else {
                        copyCause({ causeId: row.causeId, sourceCauseId: sourceCellCauseId }).then(() => onEditClose());
                      }
                    }}>
                    <DownloadForOfflineOutlinedIcon />
                  </IconButton> : ''}

                  {row.causeId !== null && sourceCellCauseId === -1 ? <IconButton tabIndex={-1} aria-label="copy" size="small"
                    title={'Select cause for copy'}
                    onClick={() => {
                      setSelectedCell(resetSelectedCell);
                      setSourceCell(-1);
                      //
                      if (row.causeId === sourceCellCauseId) setSourceCellCauseId(-1);
                      else setSourceCellCauseId(row.causeId);
                    }}> <SelectAllIcon /> </IconButton> : ''}

                  {row.causeId === sourceCellCauseId ? <IconButton tabIndex={-1} aria-label="copy" size="small"
                    title={'Deselect cause'}
                    onClick={() => { setSelectedCell(resetSelectedCell); setSourceCell(-1); setSourceCellCauseId(-1); }}>
                    <DeselectIcon />
                  </IconButton> : ''}
                </Box>
              </Box>}
            </TableCell>
            }

            {/* consequence */}
            {!row.causeId ?
              <TableCell sx={borderRightStyles('')}></TableCell> :
              <TableCell sx={{ ...borderRightStyles(consequenceSeq), 'position': 'relative' }}
                onClick={(e: any) => {
                  if ((e.target as HTMLElement).tagName.toLowerCase() !== 'svg')
                    hazopMarker(
                      e.detail, e.currentTarget?.dataset?.sequencer || '',
                      { deviationId: row.deviationId, nodeId: row.nodeId, causeId: row.causeId, consequenceId: row.consequenceId, columnId: undefined }
                    );
                  // confirmCheck(row.consequenceName, 'consequence');
                }}
                data-sequencer={consequenceSeq}
              >
                {sourceCell === row.consequenceId && consequenceSeq !== selectedCell && <SelectAllIcon sx={{ position: 'absolute', right: 0, bottom: 0, color: 'lightgray' }} />}
                {row.consequenceId && consequenceSeq !== selectedCell && `${consequenceSeq} ${row.consequenceName || ''}`}
                {consequenceSeq !== selectedCell && canBeConsequenceCopiedTo ? <IconButton tabIndex={-1} aria-label="paste" size="small"
                  title="Paste from selected"
                  sx={{ position: 'absolute', right: 0, top: '8px' }}
                  onClick={() => {
                    const sourceConsequence = activeHazopRows.find(row => row.consequenceId === sourceCell);
                    addConsequence({
                      consequenceId: row?.consequenceId?.toString(), hazopNodeId: row.nodeId, hazopCauseId: row.causeId,
                      hazopConsequenceCategoryId: sourceConsequence?.consequenceCategoryId,
                      data: JSON.stringify(sourceConsequence?.consequenceCustomColumns || {}),
                      name: sourceConsequence?.consequenceName || ''
                    }).then(
                      () => {
                        setSourceCell(-1);
                        onEditClose();
                      })
                  }}><DownloadForOfflineOutlinedIcon /></IconButton> : ''}

                {consequenceSeq === selectedCell && <Box display="flex" alignItems="center">
                  <Autocomplete
                    options={suggestionsForHazopRows.consequences}
                    defaultValue={row.consequenceName}
                    sx={{ flex: "1 1 auto" }}
                    renderInput={(params) => <TextField {...params}
                      InputProps={{
                        ...params.InputProps, spellCheck: true,
                      }}
                      placeholder={'consequence name'}
                      multiline minRows={2}
                      onBlur={(event: any) => {
                        row.consequenceName = event.target.value.trim();
                        changesInProgressFlag.current = true;
                        addConsequence({
                          consequenceId: row?.consequenceId?.toString(),
                          hazopNodeId: row.nodeId,
                          hazopCauseId: row.causeId, data: JSON.stringify(row.consequenceCustomColumns) || '{}',
                          name: row.consequenceName
                        }).then(() => onEditClose())
                      }}
                    />
                    } />
                  <Box sx={{ width: '32px' }}>
                    {canDelateOrCopy && <IconButton tabIndex={-1} aria-label="delete" size="small"
                      title="Delete"
                      disabled={row?.consequenceId === undefined}
                      onClick={() => {
                        changesInProgressFlag.current = true;
                        if (sourceCell === row?.consequenceId) setSourceCell(-1);
                        deleteConsequence({ nodeId: row.nodeId.toString(), consequenceId: row?.consequenceId?.toString() }).then(() => onEditClose())
                      }}><DeleteOutlinedIcon /></IconButton>}

                    <IconButton tabIndex={-1} aria-label="add" size="small"
                      title="Add new"
                      onClick={() => {
                        const consequenceData = {
                          deviationId: row.deviationId,
                          hazopNodeId: row.nodeId,
                          hazopCauseId: row.causeId,
                          name: '',
                          sequence: +consequenceSeq.split('.')[3] + 1,
                          data: '{}'
                        }
                        addConsequence(consequenceData).then(() => onEditClose())
                      }}><AddCircleOutlineOutlinedIcon /></IconButton>

                    {canBeConsequenceCopiedTo && sourceCell ?
                      <IconButton tabIndex={-1} aria-label="paste" size="small"
                        title="Paste from selected"
                        onClick={() => {
                          const sourceConsequence = activeHazopRows.find(row => row.consequenceId === sourceCell);
                          addConsequence({
                            consequenceId: row?.consequenceId?.toString(), hazopNodeId: row.nodeId, hazopCauseId: row.causeId,
                            hazopConsequenceCategoryId: sourceConsequence?.consequenceCategoryId,
                            data: JSON.stringify(sourceConsequence?.consequenceCustomColumns || {}),
                            name: sourceConsequence?.consequenceName || ''
                          }).then(
                            () => {
                              setSourceCell(-1);
                              onEditClose();
                            })
                        }}><DownloadForOfflineOutlinedIcon /></IconButton> : ''}

                    {row.consequenceId !== sourceCell && !hasNoConsequenceName ? <IconButton tabIndex={-1} aria-label="copy" size="small"
                      title={'Select for copy'}
                      onClick={() => { setSelectedCell(resetSelectedCell); setSourceCellCauseId(-1); setSourceCell(row.consequenceId); }}>
                      <SelectAllIcon />
                    </IconButton> : ''}

                    {row.consequenceId === sourceCell && !hasNoConsequenceName ? <IconButton tabIndex={-1} aria-label="copy" size="small"
                      title={'Deselect'} onClick={() => { setSelectedCell(resetSelectedCell); setSourceCellCauseId(-1); setSourceCell(-1); }}>
                      <DeselectIcon />
                    </IconButton> : ''}

                  </Box>
                </Box>}
              </TableCell>
            }
            {/* consequence category */}
            {!row.causeId ?
              <TableCell sx={borderRightStyles('')}></TableCell> :
              <TableCell sx={borderRightStyles(consequenceSeq + '--category')}
                onClick={(e: any) => row?.consequenceId && hazopMarker(e.detail, e.currentTarget?.dataset?.sequencer || '', { deviationId: row.deviationId, nodeId: row.nodeId, causeId: row.causeId, consequenceId: row.consequenceId })}
                data-sequencer={consequenceSeq + '--category'}
              >
                {consequenceSeq + '--category' !== selectedCell && `${categoryCheck(row.consequenceCategoryName, consequenceCategories) || ''}`}
                {consequenceSeq + '--category' === selectedCell && <Select value={row.consequenceCategoryId}
                  onChange={(event: SelectChangeEvent) => {
                    changesInProgressFlag.current = true;
                    addConsequence({
                      consequenceId: row?.consequenceId.toString(), hazopNodeId: row.nodeId, hazopCauseId: row.causeId,
                      hazopConsequenceCategoryId: event.target.value
                    }).then(() => onEditClose())
                  }} size="small" sx={{ minWidth: 160 }}>
                  {consequenceCategories?.map((category) =>
                    <MenuItem value={category.id} key={`menu__${category.id}`}>{category.name}</MenuItem>
                  )}
                </Select>}
              </TableCell>
            }
            {subColumns.map((column, index) => {

              const columnSequence = consequenceSeq + column.id;
              const columnValue = row.consequenceCustomColumns?.[column.id] || ' - ';

              const isRiskRankingCol = column.type === 2 && column.subType === 'RR' && columnValue !== ' - ';
              let rrBgColor = isRiskRankingCol ? riskRankColorsMap?.[columnValue] || '' : '';
              let rrName = columnValue;

              if (isRiskRankingCol && Object.keys(riskRankColorsMap).length) {
                const _severity = severitiesMap[row.consequenceCustomColumns?.[column.id - 2]];
                const _likelihood = likelihoodsMap[row.consequenceCustomColumns?.[column.id - 1]];
                const _riskRankingClassification = classificationsMap?.[_severity + '+' + _likelihood] || {};

                rrName = _riskRankingClassification?.name || '';
                rrBgColor = _riskRankingClassification?.color || '';
              }

              return <TableCell key={'column_' + hazopRowsParams.rowIds[indexDeviation] + '_' + column.id}
                sx={subColumns.length > index ? borderRightStyles(columnSequence, true, rrBgColor, column.type === 2) : {}}
                onClick={(e: any) => {
                  if (row?.consequenceId) hazopMarker(e.detail, e.currentTarget?.dataset?.sequencer || '', {
                    deviationId: row.deviationId,
                    nodeId: row.nodeId,
                    causeId: row.causeId,
                    consequenceId: row.consequenceId,
                    columnId: column.id,
                    columnData: row.consequenceCustomColumns
                  })
                }}
                data-sequencer={columnSequence}
                data-column-id={column.id}
                data-column-type={column.type}
              >
                {column.type === 4 && <RecommendationList
                  data={row.consequenceRecommendations}
                  isSelected={columnSequence === selectedCell}
                  onDelete={(idForDelete: number) => deleteRecommendation({ recommendationId: idForDelete, consequenceId: row.consequenceId! })}
                  onClick={(e: any) => {
                    if (e.currentTarget.dataset.id === '-1')
                      setRecommendationModalData({ name: '', partyResponsible: '', number: 0, id: -1 })
                    else
                      setRecommendationModalData(row.consequenceRecommendations.find(row => row.id?.toString() === e.currentTarget.dataset.id) ?? null)
                    setRecommendationModalOwner(columnSequence)
                  }} />}

                {column.type === 3 &&
                  <Type3ListElement areChangesInProgress={changesInProgressFlag.current}
                    columnId={column.id}
                    columnValue={Array.isArray(columnValue) ? columnValue : [columnValue]}
                    isSelected={columnSequence === selectedCell}
                    suggestions={suggestionsForHazopRows.customColumns.get(column.id) || []}
                    save={(objetData, resetSelection) => {
                      const newData = Object.assign(row.consequenceCustomColumns,
                        Object.fromEntries([[column.id, objetData]])
                      );
                      changesInProgressFlag.current = true;
                      patchConsequences({ hazopConsequenceData: newData, consequenceId: row.consequenceId, nodeId: row.nodeId }).then(
                        () => {
                          onEditClose(resetSelection)
                          changesInProgressFlag.current = false;
                        })
                    }}
                  />}

                {columnSequence === selectedCell && column.type === 2 && column.subType === 'S' &&
                  (severitiesMin === severitiesMax) && <Box sx={{ textAlign: 'center' }}> - </Box>}
                {columnSequence === selectedCell && column.type === 2 && column.subType === 'S' &&
                  (severitiesMin !== severitiesMax) &&
                  <TextField type="number" size="small" inputProps={{ min: severitiesMin, max: severitiesMax }}
                    sx={{ width: 'auto' }} defaultValue={columnValue} onBlur={(event: any) => {
                      let newData = {};
                      const _lValue = row.consequenceCustomColumns?.[column.id + 1];
                      const _min = parseInt(event.currentTarget.getAttribute('min'));
                      const _max = parseInt(event.currentTarget.getAttribute('max'));
                      const _val = parseInt(event.currentTarget.value);
                      const _sValue = _val < _min || _val > _max ? '' : _val.toString();
                      if (_lValue) {
                        const _sValueInd = severitiesMap[_sValue];
                        const _lValueInd = likelihoodsMap[_lValue];
                        const _rrValue = classificationsMap?.[`${_sValueInd}+${_lValueInd}`]
                        newData = Object.assign(row.consequenceCustomColumns,
                          Object.fromEntries([
                            [column.id, _sValue], [column.id + 2, _rrValue?.name || ' - ']
                          ])
                        );
                      } else {
                        newData = Object.assign(row.consequenceCustomColumns,
                          Object.fromEntries([[column.id, _sValue]])
                        );
                      }
                      changesInProgressFlag.current = true;
                      patchConsequences({ hazopConsequenceData: newData, consequenceId: row.consequenceId, nodeId: row.nodeId }).then(() => onEditClose())
                    }}
                  />
                }
                {columnSequence === selectedCell && column.type === 2 && column.subType === 'L' &&
                  (likelihoodsMin === likelihoodsMax) && <Box sx={{ textAlign: 'center' }}> - </Box>}
                {columnSequence === selectedCell && column.type === 2 && column.subType === 'L' &&
                  (likelihoodsMin !== likelihoodsMax) &&
                  <TextField type="number" size="small" inputProps={{ min: likelihoodsMin, max: likelihoodsMax }}
                    sx={{ width: 'auto' }} defaultValue={columnValue} onBlur={(event: any) => {
                      let newData = {};
                      const _sValue = row.consequenceCustomColumns?.[column.id - 1];
                      const _min = parseInt(event.currentTarget.getAttribute('min'));
                      const _max = parseInt(event.currentTarget.getAttribute('max'));
                      const _val = parseInt(event.currentTarget.value);
                      const _lValue = _val < _min || _val > _max ? '' : _val.toString();

                      if (_sValue) {
                        const _sValueInd = severitiesMap[_sValue];
                        const _lValueInd = likelihoodsMap[_lValue];
                        const _rrValue = classificationsMap?.[`${_sValueInd}+${_lValueInd}`]
                        newData = Object.assign(row.consequenceCustomColumns,
                          Object.fromEntries([
                            [column.id, _lValue], [column.id + 1, _rrValue?.name || ' - ']
                          ])
                        );
                      } else {
                        newData = Object.assign(row.consequenceCustomColumns,
                          Object.fromEntries([
                            [column.id, _lValue]
                          ])
                        );
                      }

                      changesInProgressFlag.current = true;
                      patchConsequences({ hazopConsequenceData: newData, consequenceId: row.consequenceId, nodeId: row.nodeId }).then(() => onEditClose())
                    }}
                  />
                }
                {column.type === 2 && (column.subType === 'RR' || columnSequence !== selectedCell) &&
                  <Box sx={{ textAlign: 'center' }}> {rrName || columnValue} </Box>
                }
                {column.type < 2 &&
                  <>
                    {columnSequence !== selectedCell && <span dangerouslySetInnerHTML={{ __html: columnValue?.toString().replaceAll('\n', '<br>') || '' }} />}
                    {columnSequence === selectedCell &&
                      <TextField size="small"
                        multiline minRows={3}
                        defaultValue={columnValue}
                        inputProps={{ spellCheck: 'true' }}
                        onBlur={(event: { currentTarget: { value: any; }; }) => {
                          const newData = Object.assign(row.consequenceCustomColumns,
                            Object.fromEntries([
                              [column.id, event.currentTarget.value]
                            ])
                          );
                          changesInProgressFlag.current = true;
                          patchConsequences({ hazopConsequenceData: newData, consequenceId: row.consequenceId, nodeId: row.nodeId }).then(() => onEditClose())
                        }}
                      />}
                  </>
                }
              </TableCell>
            }
            )}
          </TableRow>
        })}
      </TableBody>
    </Table>

    <EditRecommendationModal
      isOpen={recommendationModalData !== null}
      handleClose={() => { setRecommendationModalData(null) }}
      handleSave={(data) => {
        const _partyResponsible = data.partyResponsible === "" ? null : data.partyResponsible;
        if (recommendationModalData?.id && recommendationModalData?.id > -1)
          patchRecommendation({ name: data.recommendation, partyResponsible: _partyResponsible, recommendationId: recommendationModalData?.id!, consequenceId: selectedCellData.current.consequenceId! })
        else if (selectedCellData.current.consequenceId)
          addRecommendation({ name: data.recommendation, partyResponsible: _partyResponsible, consequenceId: selectedCellData.current.consequenceId! })
        onEditClose();
      }}
      sequence={recommendationModalOwner ?? ''}
      modalData={recommendationModalData}
    />
    <Dialog fullWidth={false}
      maxWidth={'sm'}
      onClose={() => setShowConfirm(0)} open={!!showConfirm}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Disconnect this field from the associated
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => setShowConfirm(0)}>Disagree</Button>
        <Button onClick={() => setShowConfirm(0)} autoFocus> Agree </Button>
      </DialogActions>
    </Dialog></>
  );
};

export default HazopTable;
