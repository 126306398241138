import axios from "axios";
import { handleError } from "helpers/handleError";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

const returnFileForDownload = (href: string, fileName: string) => {
  const anchorElement = document.createElement("a");
  anchorElement.href = href;
  anchorElement.download = fileName; // Specify the desired file name

  document.body.appendChild(anchorElement);

  anchorElement.click();

  document.body.removeChild(anchorElement);

  window.URL.revokeObjectURL(href);
};

export const postRequestForSummary = async (
  projectId: string,
  type: string,
  csvText: string
) => {
  await axios
    .post(
      `/api/summary/${projectId}/${type}`,
      {
        text: csvText
      },
      { responseType: "blob" }
    )
    .then((response) => {
      const contentDispositionArr =
        response.headers["content-disposition"].split("; ");
      const apiFileName =
        contentDispositionArr?.[1].split("=")?.[1] || `${type}_${projectId}`;
      const href = window.URL.createObjectURL(response.data);
      returnFileForDownload(href, apiFileName);
    })
    .catch(() => {
      toast.error(
        `Error: "Something went wrong. Check the data you entered."`,
        { autoClose: false, hideProgressBar: true }
      );
    });
};

export const useGetSummaryRecommendations = (projectId: string) => {
  return useQuery(
    ["summary", projectId],
    async () => {
      const { data } = await axios.get<
        {
          id: number;
          number: number;
          name: string;
          partyResponsible: string;
          nodes: {
            key: number;
            classificationRisk: string[];
            identifier: string;
          }[];
        }[]
      >(`/api/summary/${projectId}/recommendations`);

      return data;
    },
    {
      cacheTime: 1,
      onError: handleError<unknown>
    }
  );
};
