import axios from "axios";
import { handleError } from "helpers/handleError";
import { useMutation, useQuery } from "react-query";

export type NewNodeMarkupsItem = {};
export enum NodeMarkupsTypes {
  "circle" = 1,
  "rectangle"
}
export type NodeMarkupsItem = {
  id: number;
  type: NodeMarkupsTypes;
  x: number;
  y: number;
  width?: number;
  height?: number;
};

export const useGetNodeMarkups = (nodeId: string) => {
  return useQuery(
    ["node-markups", nodeId],
    async () => {
      const { data } = await axios.get<NodeMarkupsItem[]>(
        `/api/nodeMarkups/${nodeId}/elements`
      );

      return {
        circles: data
          .filter((item) => item.type === NodeMarkupsTypes.circle)
          .map((item) => {
            return { id: item.id, x: item.x, y: item.y };
          }),
        rectangle: data
          .filter((item) => item.type === NodeMarkupsTypes.rectangle)
          .map((item) => {
            return {
              id: item.id,
              x: item.x,
              y: item.y,
              width: item?.width || 100,
              height: item?.height || 100
            };
          })
      };
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const useGetAllNodeMarkups = (nodeIds: number[]) => {
  return useQuery(
    ["node-markups", nodeIds],
    async () => {
      const results = await axios
        .all(
          nodeIds.map((nodeId) =>
            axios.get<NodeMarkupsItem[]>(`/api/nodeMarkups/${nodeId}/elements`)
          )
        )
        .then(async (resultArr) => resultArr.map((result) => result.data));

      return results.map((data) => {
        return {
          circles: data
            .filter((item) => item.type === NodeMarkupsTypes.circle)
            .map((item) => {
              return { id: item.id, x: item.x, y: item.y };
            }),
          rectangle: data
            .filter((item) => item.type === NodeMarkupsTypes.rectangle)
            .map((item) => {
              return {
                id: item.id,
                x: item.x,
                y: item.y,
                width: item?.width || 100,
                height: item?.height || 100
              };
            })
        };
      });
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const useAddSymbolCoords = (nodeId: string) => {
  return useMutation(
    async (data: {
      nodeId: number;
      type: NodeMarkupsTypes;
      x: number;
      y: number;
      width?: number;
      height?: number;
    }) => {
      const { data: symbolCoordsFromApiResponse } = await axios.post(
        `/api/nodeMarkups/${nodeId}/elements`,
        data
      );
      return symbolCoordsFromApiResponse;
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const useChangeSymbolCoords = (nodeId: string) => {
  return useMutation(
    async (params: { elementId: number; data: any }) => {
      let oldValue: { id?: number; x?: number; y?: number } = {};
      if (params.elementId > -1) {
        const { data: symbolCoordsFromApiOld } = await axios.get(
          `/api/nodeMarkups/${nodeId}/elements/${params.elementId}`
        );
        oldValue = symbolCoordsFromApiOld;
      } else {
        const { data: symbolsCoordsFromApi } = await axios.get(
          `/api/nodeMarkups/${nodeId}/elements`
        );
        oldValue = symbolsCoordsFromApi.pop();
      }

      if (oldValue?.id) {
        const { data: symbolCoordsFromApiResponse } = await axios.put(
          `/api/nodeMarkups/${nodeId}/elements/${oldValue.id}`,
          { ...oldValue, ...params.data }
        );
        return symbolCoordsFromApiResponse;
      }
      return null;
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const useRemoveSymbolCoords = (nodeId: string) => {
  return useMutation(
    async (elementId: number) => {
      const { data: symbolCoordsFromApiResponse } = await axios.delete(
        `/api/nodeMarkups/${nodeId}/elements/${elementId}`
      );
      return symbolCoordsFromApiResponse;
    },
    {
      onError: handleError<unknown>
    }
  );
};
