import axios from "axios";
import { handleError } from "helpers/handleError";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { AddSeverityDto, Severity } from "types";

export interface CustomColumn {
  id: number;
  index: number;
  value: string;
}
export interface SeverityDto {
  id: number;
  riskMatrixId: number;
  index: number;
  name: string;
  customColumns: CustomColumn[];
}

export const useGetSelectedSeverity = (projectId: string) => {
  return useQuery(
    ["custom-severities", projectId],
    async () => {
      const { data } = await axios.get<SeverityDto[]>(
        `/api/projects/${projectId}/matrixes/selected/severities`
      );
      return data.sort((s1, s2) => s1.index - s2.index);
    },
    {
      cacheTime: 1,
      onError: handleError<unknown>
    }
  );
};

export const useAddCustomSeverity = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (newCustomSeverity: AddSeverityDto) => {
      const { data: customLikelihood } = await axios.post<Severity>(
        `/api/projects/${projectId}/matrixes/selected/severities`,
        newCustomSeverity
      );
      return customLikelihood;
    },
    {
      onError: (error: { response: { data?: { Index: string } } }) => {
        toast.error(
          `Error: ${
            error.response?.data?.Index ||
            "Something went wrong. Check the data you entered."
          }`,
          { autoClose: false, hideProgressBar: true }
        );
      },
      onSuccess: () => {
        toast.success("Successfully added new severity.");
        queryClient.invalidateQueries(["custom-severities", projectId]);
        queryClient.invalidateQueries(["classifications", projectId]);
      }
    }
  );
};

export type EditSeverityDto = {
  index: number;
  name: string;
  consequenceCategoriesValues: {
    [key: string]: string;
  };
};

export const useEditSeverity = (projectId: string, severityId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (editSeverityDto: EditSeverityDto) => {
      const { data: updatedSeverity } = await axios.put<SeverityDto>(
        `/api/projects/${projectId}/matrixes/selected/severities/${severityId}`,
        editSeverityDto
      );
      return updatedSeverity;
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully edited severity.");
        queryClient.invalidateQueries(["custom-severities", projectId]);
      }
    }
  );
};

export const useDeleteSeverity = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (severityId: number) =>
      axios.delete(
        `/api/projects/${projectId}/matrixes/selected/severities/${severityId}`
      ),
    {
      onError: () => {
        toast.error("Something went wrong.");
      },
      onSuccess: () => {
        toast.success("Successfully deleted severity");
        queryClient.invalidateQueries(["custom-severities", projectId]);
        queryClient.invalidateQueries(["classifications", projectId]);
      }
    }
  );
};
