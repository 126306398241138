import { Box, Tab, Table, TableBody, TableCell, TableRow, Tabs, Typography } from "@mui/material";
import { useGetNodes } from "api/node-analysis";
import Title from "components/Title";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { RouteProps } from "./fabric/AnalyzePid";
import { linesColorsArr } from "./fabric/Canvas";

type Props = {};

const ManageNodesDescription = () => (
  <Typography sx={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
    View and edit nodes, symbols and equipments
  </Typography>
);

const ColorBox = ({ colorInd }: { colorInd?: number }) => <span style={{
  display: "inline-block",
  width: '16px',
  height: '16px',
  margin: '-3px 3px -3px -3px ',
  background: colorInd ? linesColorsArr[(colorInd - 1) % linesColorsArr.length] : linesColorsArr[0]
}} />


const MarkupNodeDescription = () => {
  const { projectId, nodeId, fileId } = useParams<RouteProps>() as RouteProps;
  const { search } = useLocation()
  const { data: nodes } = useGetNodes(projectId);

  const nodesMap = new Map(nodes?.map((n) => [n.id, n]) || []);
  const currentNode = nodesMap.get(+nodeId);
  const document = currentNode?.files.find(f => f.id === +fileId);

  const allNodes = nodes?.filter((n) => n.files.find((nf) => nf.id === +fileId))
  const showAllNodes = search.includes('showAllNodes');

  return (
    <Box sx={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableBody>
          <TableRow>
            <TableCell sx={{ border: 'none' }} align="right" width={'50%'}><strong>Document Name / Number</strong> : </TableCell>
            <TableCell sx={{ border: 'none' }}>{document?.fileName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: 'none' }} align="right"><strong>Node ID - Name</strong> : </TableCell>
            <TableCell sx={{ border: 'none' }}><ul style={{ margin: 0, padding: 0, listStyle: 'none', textAlign: 'left' }}>
              {showAllNodes ?
                allNodes?.map((an, key) => <li key={`node_${key + 1}`}><ColorBox colorInd={parseInt(an?.nodeIdentifier?.split('-')?.[1] || '1')} /> {an?.nodeIdentifier} - {an?.name}</li>)
                : <li key={`node_0`}><ColorBox colorInd={parseInt(currentNode?.nodeIdentifier?.split('-')?.[1] || '1')} /> {currentNode?.nodeIdentifier} - {currentNode?.name}</li>}
            </ul>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}

const NodeAnalysisTabs = () => {
  const [tab, setTab] = useState(0);

  const { pathname, search } = useLocation();
  const { nodeId, fileId } = useParams<RouteProps>() as RouteProps;

  // When user refreshes make sure the url sets active tab properly
  useEffect(() => {
    if (search.includes("showAll")) setTab(2)
    else if (pathname.includes("pids")) setTab(1);
    else if (pathname.includes("nodes")) setTab(0);
    else setTab(0);
  }, [pathname, search]);

  return (
    <Box sx={{ textAlign: "center" }}>
      <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)} centered>
        <Tab
          label="Manage nodes"
          to="nodes"
          component={Link}
          sx={{ paddingBottom: 0 }}
        />
        {tab > 0 &&
          <Tab
            label="Node Markup"
            to={`nodes/${nodeId}/pids/${fileId}`}
            component={Link}
            sx={{ paddingBottom: 0 }}
          />}
        {tab > 0 &&
          <Tab
            label="All Node Markup"
            to={`nodes/${nodeId}/pids/${fileId}?showAllNodes=true`}
            component={Link}
            sx={{ paddingBottom: 0 }}
          />}
      </Tabs>
      {tab === 0 && <ManageNodesDescription />}
      {(tab === 1 || tab === 2) && <MarkupNodeDescription />}
    </Box>
  );
};

const NodeAnalysis = (props: Props) => {
  return (
    <Box sx={{ minHeight: "100vh" }}>
      <Title>
        <>
          <Box>
            <Typography variant={"h3"}>Node analysis</Typography>
          </Box>
          <Typography color={"GrayText"}>
            Analyze P&IDs, add nodes and manage worksheet pre-population
          </Typography>
        </>
      </Title>

      <NodeAnalysisTabs />

      <Box sx={{ width: "100%" }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default NodeAnalysis;
