import { DndContext, DragEndEvent, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Checkbox,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {
  HazopColumnDto,
  useDeleteHazopColumn,
  usePatchHazopColumn
} from "api/hazop";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { MouseSensor } from "../sensors";
import EditHazopColumnModal from "./EditHazopColumnModal";

const getBackgroundColor = (dependant: boolean, mandatory: boolean) => {
  if (dependant) return "#FCF8E6";
  if (mandatory) return "#F3F2F2";
  return "#FFF";
};

type ColumnProps = {
  column: HazopColumnDto;
  isEditable: boolean;
}

export const titleCorrection = (name: string, title: string) => {
  if (name === 'safeguards') return 'Safeguards';
  if (name === 'recommendations') return 'Recommendations';
  return title;
}

// Custom column component
const Column = ({ column, isEditable }: ColumnProps) => {
  const {
    id,
    mandatory,
    selected,
    title,
    dependency,
    subColumns,
    isCreatedByUser,
    sequence
  } = column;

  const { projectId } = useParams<RouteProps>() as RouteProps;

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(selected);

  const { mutateAsync: deleteHazopColumn } = useDeleteHazopColumn(projectId);
  const { mutateAsync: patchHazopColumn } = usePatchHazopColumn(projectId);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = { transform: transform ? `translate3d(${transform.x}px, 0, 0)` : undefined, transition };

  const isMoveable = id !== 0 && isEditable;

  return (
    <>
      <TableCell
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        sx={{
          whiteSpace: "nowrap",
          backgroundColor: getBackgroundColor(Boolean(dependency), mandatory),
          cursor: isMoveable ? "pointer" : "auto"
        }}
        data-no-dnd={!isMoveable ? "true" : ""}
        data-is-editable={isEditable ? "true" : "false"}
        colSpan={Boolean(subColumns) ? 3 : 1}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!mandatory && <DragIndicatorIcon />}
          {!mandatory && (<Box data-no-dnd="true">
            <Checkbox
              data-no-dnd="true"
              checked={isSelected}
              disabled={!isEditable}
              onClick={() => {
                patchHazopColumn({ selected: !!!isSelected, columnId: id })
                  .then(() => setIsSelected(!isSelected));
              }}
            />
          </Box>)}
          <Typography sx={{ fontSize: "1.2rem" }}>{title}</Typography>
          {!mandatory && (
            <Box data-no-dnd="true">
              {isEditable && <IconButton
                aria-label="more"
                id="long-button"
                onClick={() => {
                  setIsEditOpen(true);
                }}
              >
                <EditIcon />
              </IconButton>}
              {isCreatedByUser && isEditable && (<IconButton
                aria-label="more"
                id="long-button"
                onClick={() => {
                  setIsDeleteConfirmationOpen(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
              )}
            </Box>
          )}
        </Box>
      </TableCell>

      {isEditOpen && (
        <EditHazopColumnModal
          isOpen={isEditOpen}
          handleClose={() => setIsEditOpen(false)}
          column={column}
        />
      )}

      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        handleClose={() => setIsDeleteConfirmationOpen(false)}
        title={"Delete PHA column"}
        text={"Are you sure you want to delete the PHA column?"}
        onAgree={() => {
          deleteHazopColumn(id);
          setIsDeleteConfirmationOpen(false);
        }}
      />
    </>
  );
};

type RouteProps = {
  projectId: string;
};

type HazopSetupTableProps = {
  isEditable: boolean;
  hazopColumns: HazopColumnDto[];
};

// Table component
const HazopSetupTable = ({ isEditable, hazopColumns }: HazopSetupTableProps) => {
  const { projectId } = useParams<RouteProps>() as RouteProps;
  const { mutateAsync: patchHazopColumn } = usePatchHazopColumn(projectId);
  const sensors = useSensors(useSensor(MouseSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      // const activeColumn = hazopColumns.find((c) => c.id === active.id);
      const overColumn = hazopColumns.find((c) => c.id === over?.id);
      if (overColumn?.sequence && overColumn?.id)
        patchHazopColumn({
          columnId: active!.id as number,
          sequence: overColumn?.sequence,
          targetColumnId: overColumn?.id,
          event
        });
    }
  };

  //   
  // Display subcolumns "s" "l" and "r"
  //
  const secondRow = hazopColumns
    ?.map((x) => {
      if (x.subColumns) {
        const values = Object.values(x.subColumns);
        return values;
      }
      return "";
    })
    .flat();

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SortableContext items={hazopColumns}>
        <Table>
          <TableHead>
            <TableRow>
              {hazopColumns.map((column, index) => {
                const _column = { ...column, title: titleCorrection(column.name, column.title) };
                return <Column key={`${column.id}-${index}`} column={_column} isEditable={isEditable} />
              })}
            </TableRow>
            <TableRow>
              {secondRow?.map((x, index) => {
                return (
                  <TableCell key={`sr-${index}`} sx={{ textAlign: "center" }}>
                    {x.toUpperCase()}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        </Table>
      </SortableContext>
    </DndContext>
  );
};

export default HazopSetupTable;
