export const jsonToCsv = (
  items: any[],
  headerForCsv: string = "",
  csvValueJoiner: string = ";",
  csvRowsJoiner: string = "\n"
) => {
  const header = Object.keys(items?.[0] || {});
  const headerString = header.join(csvValueJoiner);

  // handle null or undefined values here
  const replacer = (_: any, value: string | string[] | undefined) => {
    if (Array.isArray(value)) return value.join(", ");
    return value ?? "";
  };

  const rowItems = items.map((row) =>
    header
      .map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .map((item) => item.replaceAll('"', ""))
      .join(csvValueJoiner)
  );

  // join header and body, and break into separate lines
  const csv = [headerForCsv || headerString, ...rowItems].join(csvRowsJoiner);

  return csv;
};
