import { OrganizationRole } from "types";

export enum RoleEnum {
  Requester = "Requester",
  Facilitator = "Facilitator",
  Scribe = "Scribe",
  Participant = "Participant",
  Administrator = "Administrator",
  Curator = "Curator",
  OrganizationOwner = "Organization Owner",
  OrganizationReader = "Organization Reader",
  GlobalReader = "Global Reader"
}

export type IsAuthorized = {
  requiredRoles: RoleEnum[];
  role: RoleEnum;
  organizationRoles?: RoleEnum[];
};

export const isReadOnlyRole = (role: RoleEnum) =>
  role === RoleEnum.OrganizationReader || role === RoleEnum.GlobalReader;

export const isAuthorized = ({
  role,
  requiredRoles,
  organizationRoles
}: IsAuthorized): boolean => {
  return (
    requiredRoles.includes(role) ||
    (organizationRoles !== undefined &&
      organizationRoles.some((r) => requiredRoles.includes(r)))
  );
};

export const rolePrioritization = (
  projectRoles?: string[],
  organizationRoles?: OrganizationRole[],
  organizationId?: number
): RoleEnum[] => {
  if (!organizationId || !organizationRoles) return [];

  const orgRoles =
    organizationRoles
      ?.filter((orgRoles) => orgRoles.organizationId === organizationId)
      ?.map((orgRoles) => orgRoles.roleName as RoleEnum) || [];

  const isOrgOwner = orgRoles.includes(RoleEnum.OrganizationOwner);

  if (isOrgOwner === false && projectRoles && projectRoles.length > 0)
    return projectRoles as RoleEnum[];

  return orgRoles;
};

export const main4Roles = [
  RoleEnum.Administrator,
  RoleEnum.OrganizationOwner,
  RoleEnum.OrganizationReader,
  RoleEnum.GlobalReader
];
