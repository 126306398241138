import { zodResolver } from "@hookform/resolvers/zod";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { consequenceRecommendation, useDeleteHazopRecommendation, useGetHazopRecommendationSuggestions } from "api/hazop";
import ModalForm from "components/ModalForm";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

type Props = {
    isOpen: boolean;
    sequence: string;
    handleClose: () => void;
    handleSave: (data: { recommendation: string, partyResponsible: string }) => void;
    modalData: consequenceRecommendation | null;
    isLoading?: boolean
};
const RecommendationSchema = z.object({
    recommendation: z
        .string()
        .min(1, "Recommendation is required")
        .max(256),
    partyResponsible: z.string()
});

type NewRecommendation = z.infer<typeof RecommendationSchema>;
type RouteProps = {
    projectId: string;
};
const EditRecommendationModal = ({ isOpen, handleClose, handleSave, modalData, sequence, isLoading = false }: Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset, setValue
    } = useForm<NewRecommendation>({
        resolver: zodResolver(RecommendationSchema),
        defaultValues: { recommendation: modalData?.name ?? '', partyResponsible: modalData?.partyResponsible ?? '' }
    });

    const { projectId } = useParams<RouteProps>() as RouteProps;
    const { data: recommendationSuggestions } = useGetHazopRecommendationSuggestions(projectId);

    const consequenceSequence = sequence.split('.').slice(0, -1).join('.');

    useEffect(() => {
        setValue('recommendation', modalData?.name ?? '')
        setValue('partyResponsible', modalData?.partyResponsible ?? '')
    }, [modalData])

    return (
        <ModalForm
            title={`Recommendation for ${consequenceSequence}.`}
            isOpen={isOpen}
            handleClose={() => { reset(); handleClose(); }}
            onSubmit={handleSubmit((recommendation) => {
                handleSave(recommendation)
                reset();
                handleClose();
            })}
            isLoading={isLoading}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controller
                        name="recommendation"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                value={field.value}
                                onChange={(_, newValue) => field.onChange(newValue)}
                                options={recommendationSuggestions?.recommendations ?? []}
                                style={{ width: '100%' }}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        {...register("recommendation")}
                                        InputProps={{ ...params.InputProps, spellCheck: true, }}
                                        size="small" multiline rows={4}
                                        label={'Recommendation'}
                                        error={!!(errors?.recommendation)}
                                    />} />
                        )} />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="partyResponsible"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                value={field.value}
                                onChange={(_, newValue) => field.onChange(newValue)}
                                options={recommendationSuggestions?.partiesResponsible ?? []}
                                style={{ width: '100%' }}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        {...register("partyResponsible")}
                                        InputProps={{ ...params.InputProps, spellCheck: true }}
                                        size="small" multiline
                                        label={'Party responsible'}
                                    />}
                            />
                        )} />
                </Grid>
            </Grid>
        </ModalForm>)
}

export default EditRecommendationModal;
