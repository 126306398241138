import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { handleError } from "../helpers/handleError";
import { TimeframeDto, TimeframeRequestDto } from "../types";

export const useGetTimeFrames = (projectId: string) => {
  return useQuery(
    ["timeframes", projectId],
    async () => {
      const { data } = await axios.get<TimeframeDto[]>(
        `/api/projects/${projectId}/timeframes`
      );
      return data;
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const addTimeframeToWorkshop = async (
  projectId: number,
  newTimeframe: TimeframeRequestDto
) => {
  const { data } = await axios.post(
    `/api/projects/${projectId}/timeframes`,
    newTimeframe
  );
  return data;
};

export const useAddTimeframeToWorkshop = (projectId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (newTimeframe: TimeframeRequestDto) =>
      addTimeframeToWorkshop(projectId, newTimeframe),
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully added new timeframe.");
        queryClient.invalidateQueries(["timeframes", projectId.toString()]);
      }
    }
  );
};

export const deleteTimefrmae = async (
  projectId: string,
  timeframeId: number
) => {
  const { data: isSuccess } = await axios.delete<boolean>(
    `/api/projects/${projectId}/timeframes/${timeframeId}`
  );
  return isSuccess;
};

export const useDeleteTimeframe = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (timeframeId: number) => deleteTimefrmae(projectId, timeframeId),
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully deleted timefrmae.");
        queryClient.invalidateQueries(["timeframes", projectId]);
      }
    }
  );
};

export const editTimeframe = async (
  projectId: string,
  updatedTimeframe: TimeframeRequestDto,
  timeframeId: number
) => {
  const { data: timeframe } = await axios.put<TimeframeDto>(
    `/api/projects/${projectId}/timeframes/${timeframeId}`,
    updatedTimeframe
  );
  return timeframe;
};

export type UseEditTimeframeParams = {
  updatedTimeframe: TimeframeRequestDto;
  timeframeId: number;
};
export const useEditTimeframe = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (useEditTimeframeParams: UseEditTimeframeParams) =>
      editTimeframe(
        projectId,
        useEditTimeframeParams.updatedTimeframe,
        useEditTimeframeParams.timeframeId
      ),
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully edited timeframe.");
        queryClient.invalidateQueries(["timeframes", projectId]);
      }
    }
  );
};
