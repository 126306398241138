import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, TextField } from "@mui/material";
import { useGetSelectedMatrixConsequenceCategories } from "api/matrixes";
import { SeverityDto, useEditSeverity } from "api/severity";
import { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import ModalForm from "../../../../../components/ModalForm";
import { SeverityIndex } from "./AddSeverityModal";

export type SeverityIndexType = z.infer<typeof SeverityIndex>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  severity: SeverityDto;
};

type RouteParams = {
  projectId: string;
  assetId: string;
};

const EditSeverityModal = ({ isOpen, handleClose, severity }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm<z.infer<typeof SeverityIndex>>({
    resolver: zodResolver(SeverityIndex),
    defaultValues: severity
  });

  const { projectId } = useParams<RouteParams>() as RouteParams;

  const { mutateAsync: editCustomSeverity, isLoading } = useEditSeverity(
    projectId,
    severity.id
  );
  const { data: consequenceCategories } =
    useGetSelectedMatrixConsequenceCategories(projectId);

  const { fields, append } = useFieldArray({
    name: "columns",
    control
  });

  const onChange = (index: number, description: string) => {
    setValue(`columns.${index}.value.description`, description);
  };

  useEffect(() => {
    consequenceCategories?.forEach((consequenceCategory) => {
      append({
        value: {
          id: consequenceCategory.id,
          name: consequenceCategory.name,
          description: `-`
        }
      });
    });

    return () => {
      reset();
    };
  }, [consequenceCategories, append, reset]);

  return (
    <ModalForm
      title={"Edit severity index"}
      isOpen={isOpen}
      handleClose={() => { reset(); handleClose(); }}
      onSubmit={handleSubmit(async (newCustomSeverityIndex) => {
        const { index, name, columns } = newCustomSeverityIndex;

        const newValues = columns.reduce(
          (acc, next) => {
            if (!next) return acc;
            const oldValue = severity?.customColumns?.find(elem => elem.id === next.value.id);

            return {
              ...acc,
              [next.value.id]: next.value.description === '-' ? oldValue?.value : next.value.description.trim()
            };

          },
          {}
        );

        const body = {
          index,
          name,
          consequenceCategoriesValues: newValues
        };

        await editCustomSeverity(body);
        reset();
        handleClose();
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("index", {
              valueAsNumber: true
            })}
            inputMode="numeric"
            error={!!errors.index?.message}
            helperText={errors.index?.message}
            label={`Index *`}
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            label={`Name *`}
            fullWidth
          />
        </Grid>
        {fields?.map((field, index) => {
          return (
            <Grid item xs={12} key={field.id}>
              <Controller
                render={() => {
                  return (
                    <TextField
                      label={`${field.value.name}`}
                      defaultValue={severity?.customColumns[index].value}
                      onChange={(event) => onChange(index, event.target.value)}
                      fullWidth
                    />
                  );
                }}
                name={`columns.${index}.value.name` as const}
                control={control}
              />
            </Grid>
          );
        })}
      </Grid>
    </ModalForm >
  );
};

export default EditSeverityModal;
