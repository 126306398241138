import { Box, Grid, IconButton, List, ListItem, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { PieChart, BarChart } from "@mui/x-charts";
import { useParams } from "react-router-dom";
import { useGetProject } from "api/projects";
import { useGetNodes } from "api/node-analysis";

import { RiskClassificationSquare } from "modules/study-setup/risk-matrix/custom/classifications/CustomRiskClassification";
import TitleWithSubtext from "components/TitleWithSubtext";
import { HazopColumnDto, HazopRowDto, useGetHazopColumns, useGetHazopRows } from "api/hazop";
import { NodeDto } from "types";
import { useClassificationsObjGen } from "./HazopTable";
import { useGetSelectedMatrixLikelihoods } from "api/likelihood";
import { useGetSelectedSeverity } from "api/severity";
import Loader from "components/Loader";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";

import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { postRequestForSummary, useGetSummaryRecommendations } from "api/summary";
import { jsonToCsv } from "helpers/json2csv";
import TitleWithAddButton from "../../components/TitleWithAddButton";

type RouteParams = {
  assetId: string;
  projectId: string;
};

const PieChartWrapper = ({ nodesCompleted }: { nodesCompleted: boolean[] }) => {
  const inProgress = nodesCompleted.filter((elem) => !elem)?.length;
  const completed = nodesCompleted.filter((elem) => !!elem)?.length;

  return <PieChart
    series={[
      {
        highlightScope: { faded: 'global', highlighted: 'item' },
        faded: { innerRadius: 32, additionalRadius: -16, color: '#ccc' },
        data: [
          { id: 0, value: inProgress, label: 'In progress', color: '#9bddff' },
          { id: 1, value: completed, label: 'Completed', color: '#003591' },
        ],
        innerRadius: 75,
        paddingAngle: 3
      },
    ]}
    height={240}
  />
}

const rrBarDataCreator = (nodeId: number, hazopRows: HazopRowDto[], hazopColumns: HazopColumnDto[], rrColorArr: any[][]) => {
  const colType2 = hazopColumns?.filter(col => col.type === 2 && col.dependency === 'safeguards').map(col => Object.keys(col.subColumns).flat());

  const hrInNode = hazopRows.filter((row) => row.nodeId === nodeId);

  const ccWithRR = hrInNode.map(row => row.consequenceCustomColumns).map((cc) =>
    colType2.map(arr => arr.map(subColId => cc[subColId] || '')).filter(arr => arr[2] !== '')
  ).flat().map(cc => cc[2]);

  return rrColorArr.map(rrColorArr => ccWithRR.filter(rr => rr === rrColorArr[0])?.length);
}

const BarChartWrapper = ({ hazopColumns, hazopRows, nodes, riskRankColorsMap }: { hazopColumns?: HazopColumnDto[], hazopRows?: HazopRowDto[], nodes?: NodeDto[], riskRankColorsMap: any }) => {
  const nodesNames = nodes?.map(node => node.nodeIdentifier) || [];

  if (!nodes || !hazopColumns || !riskRankColorsMap) return <></>;

  const rrArr = Object.entries(riskRankColorsMap);
  const rrBarData = nodes?.map(node => rrBarDataCreator(node?.id, hazopRows || [], hazopColumns || [], rrArr)) || [];
  const barChartSeries = [
    rrArr.length >= 1 ? { data: rrBarData?.map(rrBarLow => rrBarLow?.[0] || 0), stack: 'RR', label: rrArr?.[0]?.[0] || '', color: (rrArr?.[0]?.[1] || '#0f0').toString() } : {},
    rrArr.length >= 2 ? { data: rrBarData?.map(rrBarLow => rrBarLow?.[1] || 0), stack: 'RR', label: rrArr?.[1]?.[0] || '', color: (rrArr?.[1]?.[1] || '#00f').toString() } : {},
    rrArr.length >= 3 ? { data: rrBarData?.map(rrBarLow => rrBarLow?.[2] || 0), stack: 'RR', label: rrArr?.[2]?.[0] || '', color: (rrArr?.[2]?.[1] || '#f00').toString() } : {},
    rrArr.length >= 4 ? { data: rrBarData?.map(rrBarLow => rrBarLow?.[3] || 0), stack: 'RR', label: rrArr?.[3]?.[0] || '', color: (rrArr?.[3]?.[1] || '#f00').toString() } : {}
  ];

  return <BarChart
    slotProps={{ legend: { hidden: true } }}
    xAxis={[{ scaleType: 'band', data: nodesNames }]}
    series={barChartSeries.filter((obj) => Object.keys(obj).length > 0)}
    height={350}
  />
}

const CompletedNodesData = (hazopColumns: HazopColumnDto[], hazopRows: HazopRowDto[], nodes: NodeDto[], riskRankColorsMap: any) => {
  const rrArr = Object.entries(riskRankColorsMap);
  const rrData = nodes?.map((node: { id: number; nodeIdentifier: any; name: any; }) => {
    const barData = rrBarDataCreator(node?.id, hazopRows, hazopColumns, rrArr);
    return [['id', node.nodeIdentifier], ['name', node.name], ...rrArr.reverse().map((rr, key) => [rr[0].toLowerCase() + 'LevelNum', barData[key]])];
  }) || [];

  return rrData.map((dataArr) => Object.fromEntries(dataArr));
}

const CompletedNodesWrapper = ({ hazopColumns, hazopRows, nodes, riskRankColorsMap }: { hazopColumns?: HazopColumnDto[], hazopRows?: HazopRowDto[], nodes?: NodeDto[], riskRankColorsMap: any }) => {
  if (!nodes || !hazopColumns || !riskRankColorsMap) return <></>;

  const rows: GridRowsProp = CompletedNodesData(hazopColumns, hazopRows || [], nodes, riskRankColorsMap);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Node ID\'s', headerAlign: 'center', minWidth: 100, flex: 1, align: 'center' },
    { field: 'name', headerName: 'Name of the node', headerAlign: 'center', minWidth: 100, flex: 1.2, align: 'center' },
    ...Object.keys(riskRankColorsMap).reverse().map(type => {
      return { field: type.toLowerCase() + 'LevelNum', headerName: type + ' level recommendations', headerAlign: 'center', minWidth: 64, flex: 1, align: 'center' } as GridColDef;
    })
  ];

  return <DataGrid rows={rows} columns={columns} autoHeight hideFooter />;
};

const RiskClassificationWrapper = ({ projectId, hazopRows, hazopColumns }: { projectId: string, hazopRows: HazopRowDto[], hazopColumns: HazopColumnDto[] }) => {
  const { data: likelihoods } = useGetSelectedMatrixLikelihoods(projectId);
  const { data: severities } = useGetSelectedSeverity(projectId);

  const subColumns = hazopColumns.filter(col => col.type === 2 && col.dependency === 'safeguards').map(col => (Object.entries(col.subColumns).map(([k, v]) => [v, k]))).flat();
  const severityColumnIds = subColumns.filter(subCol => subCol[0] === 'S').map(subCol => subCol[1]);
  const likelihoodColumnIds = subColumns.filter(subCol => subCol[0] === 'L').map(subCol => subCol[1]);

  const severityHazopValues = hazopRows.map(row => row.consequenceCustomColumns).map((col) =>
    Object.entries(col).filter((arr) => severityColumnIds.includes(arr[0])).map((subCol) => subCol[1])
  ).flat();

  const likelihoodHazopValues = hazopRows.map(row => row.consequenceCustomColumns).map((col) =>
    Object.entries(col).filter((arr) => likelihoodColumnIds.includes(arr[0])).map((subCol) => subCol[1]).flat()
  ).flat();

  if (!likelihoods || !severities) return <Loader />;
  if (likelihoods?.length === 0 || severities?.length === 0) return <strong>No risk matrix selected</strong>;

  const likelihoodMax = likelihoods?.reduce((max, l) => l.index > max ? l.index : max, 0) || 1;
  const severityMax = severities?.reduce((max, s) => s.index > max ? s.index : max, 0) || 1;
  let matrix = [...new Array(likelihoodMax + 1)].map(() => new Array(severityMax + 1).fill(0));

  severityHazopValues.forEach((_, key) => {
    const severity = parseInt(severityHazopValues[key]) || 0;
    const likelihood = parseInt(likelihoodHazopValues[key]) || 0;
    matrix[likelihood][severity] = matrix?.[likelihood]?.[severity] || 0 + 1;
  })

  return <RiskClassificationSquare
    severities={severities}
    likelihoods={likelihoods}
    settings={{ isSmaller: true, dataMatrix: matrix, isReadOnly: true, isDnv: false }}
  />
}

const RecommendationData = (recommendations?: {
  id: number;
  number: number;
  name: string;
  partyResponsible: string;
  nodes: { key: number; identifier: string, classificationRisk: string[]; }[];
}[]
) => {
  return recommendations?.map(r => [`R${r.number}`, r.name, r.partyResponsible, r.nodes.map((node: { classificationRisk: string[] }) => node.classificationRisk.join(' / ')), r.nodes.map((node: { identifier: string }) => node.identifier)]) as (string | string[])[][];
}

const RecommendationWrapper = ({ recommendations }: {
  recommendations?: {
    id: number;
    number: number;
    name: string;
    partyResponsible: string;
    nodes: { key: number; classificationRisk: string[]; identifier: string }[];
  }[]
}) => {
  if (!recommendations) return <></>;

  const renderMultiRowCell = (value?: string[] | string) => {
    if (Array.isArray(value) && value.length > 1)
      return <List sx={{ margin: '-8px -4px', padding: 0 }}>{
        value.map((val: string, key: number) =>
          <ListItem key={`list_key_${key}`}
            sx={{ padding: '4px 0', justifyContent: 'center' }}
            divider={value.length - 1 !== key}>
            {val}
          </ListItem>)
      }</List>;
    return <>{value || '-'}</>;
  }

  const columns: GridColDef[] = [
    {
      field: 'number', headerName: 'ID\'s', headerAlign: 'center', minWidth: 30, flex: 0, align: 'center'
    },
    {
      field: 'name', headerName: 'Recommendation description', headerAlign: 'center', minWidth: 100, flex: 1.2, align: 'center'
    },
    {
      field: 'partyResponsible', headerName: 'Party responsible', headerAlign: 'center', minWidth: 100, flex: 1, align: 'center'
    },
    {
      field: 'classificationRisk', headerName: 'After risk reduction', headerAlign: 'center', minWidth: 100, flex: 1, align: 'center',
      valueGetter: (value: string[]) => value.join(', '),
      renderCell: ({ value }) => renderMultiRowCell(value.split(', '))
    },
    {
      field: 'nodesIds', headerName: 'Node ID\'s', headerAlign: 'center', maxWidth: 160, flex: 1, align: 'center',
      valueGetter: (value: string[]) => value.join(', '),
      renderCell: ({ value }) => renderMultiRowCell(value.split(', '))
    }
  ];

  return <DataGrid rows={recommendations.map((row) => {
    return Object.fromEntries([
      ['id', row.id], ['number', 'R' + row.number], ['name', row.name], ['partyResponsible', row.partyResponsible],
      ['classificationRisk', row.nodes.map((node: { classificationRisk: string[] }) => node.classificationRisk[0] === '' ? ' - ' : node.classificationRisk.join(' / '))],
      ['nodesIds', row.nodes.map((node: { identifier: string }) => node.identifier)]
    ])
  })} columns={columns}
    autoHeight hideFooter
    getRowHeight={() => 'auto'}
    sx={{ '&.MuiDataGrid-root .MuiDataGrid-cell': { py: 2 } }}
  />;
}

const HazopStatus = () => {
  const { assetId, projectId } = useParams<RouteParams>() as RouteParams;
  const { data: project } = useGetProject(assetId, projectId);
  const { data: hazopRowsForProject } = useGetHazopRows(projectId);
  const { data: hazopColumns } = useGetHazopColumns(projectId);
  const { data: nodes } = useGetNodes(projectId);
  const { data: summaryRecommendations } = useGetSummaryRecommendations(projectId)

  const { riskRankColorsMap } = useClassificationsObjGen(projectId);

  const boxStyles = {
    boxShadow: "2px 2px 6px 2px #0F204B33",
    height: "100%",
    display: "flex",
    border: "1px solid transparent",
    "&:hover": {
      border: `1px solid ${blue[500]}`
    },
    flexFlow: 'column nowrap'
  }

  const hazopRowsFiltered = hazopRowsForProject?.filter(
    (row) => !!row.causeId && !!row.consequenceId &&
      Object.keys(row.consequenceCustomColumns).length
  ) || [];

  const selectedHazopColumns = hazopColumns?.filter(({ selected }) => selected !== false) || [];

  if (nodes === undefined || nodes.length === 0) {
    return (<>
      <TitleWithSubtext
        title={"Summary / Status Report"}
        textUnderTitle={
          `Summary for ${project?.name} - unviewable without nodes`
        }
      />
      {(nodes === undefined || nodes.length === 0) && <strong>No nodes </strong>}
    </>
    )
  }

  return (<>
    <TitleWithAddButton
      title={"Summary / Status Report"}
      textUnderTitle={
        `Summary for ${project?.name}`
      }
      isButtonVisible={true}

      secondaryButtonText="Export report"
      secondaryButtonUrl={`/api/hazop/report/${projectId}`}
    />

    <Grid container spacing={2} data-testid="PHAStatus">
      <Grid item xs={12} sm={6} lg={4}>
        <Box sx={boxStyles} p={3}>
          <Typography sx={{ width: "100%", color: '#00008b', marginBottom: '1rem' }} variant="h6" textAlign={'center'}>
            {'Nodes status '}
          </Typography>
          <PieChartWrapper nodesCompleted={nodes.map(node => !!node?.isCompleted)} />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} lg={8}>
        <Box sx={boxStyles} p={3}>
          <Typography sx={{ width: "100%", marginBottom: '2rem', color: '#00008b' }} variant="h6" textAlign={'center'}>
            {'Total scenarios Risk Classifications'}
          </Typography>
          <Grid container spacing={1}>
            <Grid item lg={4}>
              <BarChartWrapper
                hazopColumns={selectedHazopColumns}
                hazopRows={hazopRowsFiltered} nodes={nodes}
                riskRankColorsMap={riskRankColorsMap}
              />
            </Grid>
            <Grid item lg={8}>
              <RiskClassificationWrapper projectId={projectId} hazopRows={hazopRowsFiltered} hazopColumns={selectedHazopColumns} />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12} minHeight={320}>
        <Box sx={boxStyles} p={3} >
          <Typography sx={{ width: "100%", color: '#00008b', marginBottom: '1rem', position: 'relative' }} variant="h6" textAlign={'left'}>
            {'Completed nodes '}
            <IconButton size={'small'} sx={{ position: 'absolute', right: '8px', top: '0' }}
              onClick={() => {
                const jsonAsCsv = jsonToCsv(
                  CompletedNodesData(selectedHazopColumns, hazopRowsFiltered || [], nodes.filter(node => !!node?.isCompleted), riskRankColorsMap),
                  'Node ID\'s; Name of the node; ' + Object.keys(riskRankColorsMap)?.reverse().map((rr) => 'Recommendations level ' + rr).join('; ')
                )
                postRequestForSummary(projectId, 'completedNodes', jsonAsCsv)
              }} title='Completed nodes export'
            >
              <SimCardDownloadIcon />
            </IconButton>
          </Typography>
          <CompletedNodesWrapper hazopColumns={selectedHazopColumns}
            hazopRows={hazopRowsFiltered} nodes={nodes.filter(node => !!node?.isCompleted)}
            riskRankColorsMap={riskRankColorsMap} />
        </Box>
      </Grid>

      <Grid item xs={12} minHeight={320}>
        <Box sx={boxStyles} p={3} >
          <Typography sx={{ width: "100%", color: '#00008b', marginBottom: '1rem', position: 'relative' }} variant="h6" textAlign={'left'}>
            {'Recommendations'}
            <IconButton size={'small'} sx={{ position: 'absolute', right: '8px', top: '0' }}
              onClick={() => {
                const jsonAsCsv = jsonToCsv(RecommendationData(summaryRecommendations),
                  'Recommendation ID; Recommendation description; Party responsible; After risk reduction; Node ID\'s'
                )
                postRequestForSummary(projectId, 'recommendations', jsonAsCsv);
              }} title='Recommendations export'
            >
              <SimCardDownloadIcon />
            </IconButton>
          </Typography>
          <RecommendationWrapper recommendations={summaryRecommendations} />
        </Box>
      </Grid>
    </Grid >
  </>);
}

export default HazopStatus;