import TitleWithSubtext from "components/TitleWithSubtext";
import HazopTable from "./HazopTable";
import NodeSelect from "./NodeSelect";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

type Props = {};

type RouteParams = {
  assetId: string;
  projectId: string;
  nodeId: string;
};

const Hazop = (props: Props) => {
  const { nodeId } = useParams<RouteParams>() as RouteParams;
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const onBeforeUnload = () => {
      if (
        window.opener?.location?.pathname === window.location.pathname &&
        searchParams.get('tableOnly') === 'true'
      )
        window.opener.location.reload(true);
    };

    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [searchParams]);

  if (nodeId && searchParams.get('tableOnly') === 'true') {
    return <HazopTable />
  }

  return (
    <>
      <TitleWithSubtext
        title={"PHA Worksheet"}
        textUnderTitle={
          "Manage PHA worksheet; document risks and add recommendation"
        }
      />
      <NodeSelect />
      {nodeId && <HazopTable />}
    </>
  );
};

export default Hazop;
