import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowsProp
} from "@mui/x-data-grid";

import ConfirmationDialog from "components/ConfirmationDialog";
import { RoleEnum, isAuthorized } from "helpers/isAuthorized";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ListedParticipantDto, ViewedUser } from "types";
import {
  useDeleteParticipant,
  useGetParticipants
} from "../../api/participants";
import EditParticipantModal from "./add-edit-participant/EditParticipantModal";

type RouteParams = {
  assetId: string;
  projectId: string;
};

type Props = {
  organizationRoles: RoleEnum[];
  profile: ViewedUser;
};

const ParticipantsDatagrid = (props: Props) => {
  const { projectId } = useParams<RouteParams>() as RouteParams;

  const [selectedParticipant, setSelectedParticipant] =
    useState<ListedParticipantDto | null>(null);
  const [isEditParticipantOpen, setIsEditParticipantOpen] = useState(false);
  const [isDeleteParticipantOpen, setIsDeleteParticipantOpen] = useState(false);

  const { data: participants, isLoading: isLoadingParticipants } =
    useGetParticipants(projectId);

  const { mutateAsync: deleteParticipant } = useDeleteParticipant(projectId);

  const rows: GridRowsProp =
    participants?.map((participant) => ({
      id: participant.userId,
      userId: participant.userId,
      email: participant.email,
      name: participant.name,
      company: participant.company,
      roleName: participant.roleName,
      roleId: participant.roleId,
      function: participant.function,
      position: participant.position
    })) ?? [];

  const columns: GridColDef[] = [
    {
      flex: 1,
      field: "email",
      headerName: "Email"
    },
    {
      flex: 1,
      field: "name",
      headerName: "Name"
    },
    {
      flex: 1,
      field: "company",
      headerName: "Company"
    },
    {
      flex: 1,
      field: "position",
      headerName: "Position"
    },
    {
      flex: 1,
      field: "roleName",
      headerName: "Role"
    },
    {
      flex: 1,
      field: "function",
      headerName: "Function"
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) =>
        [
          // @ts-ignore
          <GridActionsCellItem
            icon={<DeleteIcon />}
            onClick={() => {
              setSelectedParticipant(params.row);
              setIsDeleteParticipantOpen(true);
            }}
            label="Delete"
          />,
          // @ts-ignore
          <GridActionsCellItem
            icon={<EditIcon />}
            onClick={() => {
              setSelectedParticipant(params.row);
              setIsEditParticipantOpen(true);
            }}
            label="Edit"
          />
        ].filter((x) =>
          isAuthorized({
            role: props.profile.roleName as RoleEnum,
            organizationRoles: props.organizationRoles as RoleEnum[],
            requiredRoles: [RoleEnum.Administrator, RoleEnum.OrganizationOwner]
          })
        )
    }
  ];

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isLoadingParticipants}
        autoHeight
        disableRowSelectionOnClick
      />

      {isEditParticipantOpen && selectedParticipant && (
        <EditParticipantModal
          isOpen={isEditParticipantOpen}
          handleClose={() => setIsEditParticipantOpen(false)}
          participant={selectedParticipant}
        />
      )}

      <ConfirmationDialog
        isOpen={isDeleteParticipantOpen}
        handleClose={() => setIsDeleteParticipantOpen(false)}
        title={"Delete participant"}
        text={"Are you sure you want to delete participant?"}
        onAgree={() => {
          deleteParticipant(selectedParticipant!.userId);
          setIsDeleteParticipantOpen(false);
        }}
      />
    </div>
  );
};

export default ParticipantsDatagrid;
