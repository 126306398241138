import { RoleEnum, isAuthorized, rolePrioritization } from "helpers/isAuthorized";
import { useMemo } from "react";
import AnalyzePid from "./fabric/AnalyzePid";
import { useGetAsset } from "api/assets";
import { useGetProfile, useGetProjectProfile } from "api/users";
import { useParams } from "react-router-dom";
import { useGetNodes } from "api/node-analysis";

type Props = {
  edit?: boolean
};

export type RouteProps = {
  projectId: string;
  assetId: string;
  fileId: string;
};
const Pids = ({ edit = true }: Props) => {
  const { projectId, assetId, fileId } = useParams<RouteProps>() as RouteProps;

  const { data: asset } = useGetAsset(assetId as string);
  const { data: profile } = useGetProfile();
  const { data: projectProfile } = useGetProjectProfile(parseInt(projectId || '0'))
  const { data: nodes } = useGetNodes(projectId);
  const allNodes = nodes?.filter((n) => n.files.find((nf) => nf.id === +fileId))

  const organizationRoles: RoleEnum[] = useMemo(() =>
    rolePrioritization(projectProfile?.projectRoles, profile?.organizationRoles, asset?.organizationId)
    , [asset?.organizationId, profile?.organizationRoles, projectProfile?.projectRoles])

  const isEditable = isAuthorized({
    role: profile?.roleName as RoleEnum || RoleEnum.GlobalReader,
    organizationRoles: organizationRoles,
    requiredRoles: [RoleEnum.Facilitator, RoleEnum.Scribe, RoleEnum.OrganizationOwner, RoleEnum.Administrator]
  });

  if (asset?.organizationId && profile?.organizationRoles && projectProfile?.projectRoles) {
    return <AnalyzePid showResult={isEditable !== true || edit === false} allNodesIds={allNodes?.map(n => n.id)} />;
  }

  return <></>
};

export default Pids;
