import { zodResolver } from "@hookform/resolvers/zod";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { useAddUser, usePatchUser } from "api/users";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { RoleTypes, ViewedUser } from "types";
import { z } from "zod";
import ModalForm from "../../components/ModalForm";
import { useGetAppRoles } from "api/roles";
import { useMemo } from "react";

const UserSchema = z.object({
  email: z.string().max(256),
  roleId: z.number(),
  name: z.string()
});

type NewUserType = z.infer<typeof UserSchema>;

type RouteParams = { organizationId: string; };

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  user: ViewedUser;
  isUserPanel?: boolean;
};

const EditUserModal = ({ isOpen, handleClose, user, isUserPanel = false }: Props) => {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors }
  } = useForm<NewUserType>({
    resolver: zodResolver(UserSchema),
    defaultValues: {
      ...user
    }
  });

  const { organizationId } = useParams<{ organizationId: string }>() as RouteParams;

  const { data: roles } = useGetAppRoles();

  const { mutateAsync: patchUser } = usePatchUser(user.id);
  const { mutateAsync: addUser } = useAddUser(organizationId);

  const specificRoles = useMemo(() => {
    if (isUserPanel)
      return roles?.filter((role) => role.roleType === RoleTypes.Custom) ?? []

    return roles?.filter((role) => role.roleType === RoleTypes.BuiltIn) ?? [];
  }, [roles, isUserPanel])

  const disabledRolesId = useMemo(() => {
    if (user.email.includes('@dnv') === false)
      return roles?.find((role) => role?.name === 'Administrator')?.id || 0;
    return -1;
  }, [roles, user.email])
  //
  return (
    <ModalForm
      title={"Edit user"}
      isOpen={isOpen}
      handleClose={() => { handleClose(); }}
      onSubmit={handleSubmit(async (newUser) => {
        if (organizationId) {
          await addUser({
            email: newUser.email,
            roleId: newUser.roleId
          });
        }
        else {
          await patchUser({
            id: newUser.roleId
          });
        }
        handleClose();
      })}
      isLoading={false}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("email")}
            error={!!errors.email?.message}
            helperText={errors.email?.message}
            label={`Email`}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            label={`Name`}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="roleId"
            // @ts-ignore need to set value to null to have a controlled input
            defaultValue={user.roleId}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  onChange={(event, newAssetTypeId) => {
                    onChange(newAssetTypeId);
                  }}
                  // loading={isFetchingRoles}
                  value={value}
                  options={
                    specificRoles.map((role) => role.id)
                  }
                  getOptionDisabled={(option) => option === disabledRolesId}
                  getOptionLabel={(option) =>
                    specificRoles?.find((role) => role.id === option)?.name ?? ""
                  }
                  isOptionEqualToValue={(option, value) => {
                    return option === value;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Role *"
                      error={!!errors.roleId}
                      helperText={errors.roleId?.message}
                    />
                  )}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </ModalForm >
  );
};

export default EditUserModal;
