import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { handleError } from "../helpers/handleError";
import {
  Cell,
  ConsequenceCategory,
  ConsequenceCategoryAddDto,
  ConsequenceCategoryEditDto,
  Matrix,
  MatrixTypes,
  RiskRank
} from "../types";

export const useGetSelectedMatrixType = (projectId: string) => {
  return useQuery(
    ["matrixes-type", projectId],
    async () => {
      const { data } = await axios.get<Matrix>(
        `/api/projects/${projectId}/matrixes/selected`
      );
      return data.matrixType;
    },
    {
      onError: handleError<unknown>
    }
  );
};

// I know this is bad :(
export const useGetSelectedMatrix = (projectId: string) => {
  return useQuery(
    ["matrixes", projectId],
    async () => {
      const { data } = await axios.get<Matrix>(
        `/api/projects/${projectId}/matrixes/selected`
      );
      return data;
    },
    {
      cacheTime: 1,
      onError: handleError<unknown>
    }
  );
};

export const patchMatrix = async (
  projectId: string,
  matrixType: MatrixTypes
) => {
  const { data: matrix } = await axios.patch<Matrix>(
    `/api/projects/${projectId}/matrixes/${matrixType}`
  );
  return matrix;
};

export const usePatchMatrix = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (matrixType: MatrixTypes) => patchMatrix(projectId, matrixType),
    // When mutate is called:
    onMutate: async (newMatrixType) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ["matrixes-type", projectId]
      });

      // Snapshot the previous value
      const previousSelectedMatrix = queryClient.getQueryData([
        "matrixes-type",
        projectId
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(["matrixes-type", projectId], newMatrixType);

      // Return a context with the previous and new todo
      return {
        previousSelectedMatrix: previousSelectedMatrix,
        newMatrixType
      };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["matrixes", projectId]);
      //
      queryClient.invalidateQueries(["classifications", projectId]);
      queryClient.invalidateQueries(["custom-severities", projectId]);
      queryClient.invalidateQueries(["matrix-likelihoods", projectId]);
      queryClient.invalidateQueries(["matrix-risk-ranks", projectId]);
    },
    // If the mutation fails, use the context we returned above
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        ["matrixes-type", projectId],
        context?.previousSelectedMatrix
      );
    }
  });
};

export const useGetSelectedMatrixCells = (projectId: string) => {
  return useQuery(
    ["matrix-cells", projectId],
    async () => {
      const { data } = await axios.get<Cell[]>(
        `/api/projects/${projectId}/matrixes/selected/cells`
      );
      return data;
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const useGetSelectedMatrixConsequenceCategories = (
  projectId: string
) => {
  return useQuery(
    ["matrix-consequence-categories", projectId],
    async () => {
      const { data } = await axios.get<ConsequenceCategory[]>(
        `/api/projects/${projectId}/matrixes/selected/consequencecategories`
      );
      return data;
    },
    {
      cacheTime: 1,
      onError: handleError<unknown>
    }
  );
};

export const useGetSelectedMatrixRiskRanks = (projectId: string) => {
  return useQuery(
    ["matrix-risk-ranks", projectId],
    async () => {
      const { data } = await axios.get<RiskRank[]>(
        `/api/projects/${projectId}/matrixes/selected/riskranks`
      );

      return data || [];
    },
    {
      cacheTime: 1,
      onError: handleError<unknown>
    }
  );
};

export const useAddConsequenceCategory = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (newConsequenceCategory: ConsequenceCategoryAddDto) => {
      const { data: consequenceCategory } =
        await axios.post<ConsequenceCategory>(
          `/api/projects/${projectId}/matrixes/selected/consequencecategories`,
          newConsequenceCategory
        );
      return consequenceCategory;
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully added new consequence category.");
        queryClient.invalidateQueries([
          "matrix-consequence-categories",
          projectId
        ]);
        queryClient.invalidateQueries(["custom-severities", projectId]);
      }
    }
  );
};

export const useEditConsequenceCategory = (
  projectId: string,
  consequenceCategoryId: number
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (updatedConsequenceCategory: ConsequenceCategoryEditDto) => {
      const { data: consequenceCategory } =
        await axios.put<ConsequenceCategory>(
          `/api/projects/${projectId}/matrixes/selected/consequencecategories/${consequenceCategoryId}`,
          updatedConsequenceCategory
        );
      return consequenceCategory;
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully edited consequence category.");
        queryClient.invalidateQueries([
          "matrix-consequence-categories",
          projectId
        ]);
        queryClient.invalidateQueries(["custom-severities", projectId]);
      }
    }
  );
};

export const useDeleteConsequenceCategory = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (consequenceCategoryId: number) => {
      const { data: isSuccess } = await axios.delete<boolean>(
        `/api/projects/${projectId}/matrixes/selected/consequencecategories/${consequenceCategoryId}`
      );
      return isSuccess;
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully deleted consequence category.");
        queryClient.invalidateQueries([
          "matrix-consequence-categories",
          projectId
        ]);
        queryClient.invalidateQueries(["custom-severities", projectId]);
      }
    }
  );
};
