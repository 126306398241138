import { arrayMove } from "@dnd-kit/sortable";
import axios from "axios";
import { handleError } from "helpers/handleError";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { HAZOPWorksheetSettings, HazopPatchDto } from "../types";

export type HAZOPWorksheetSettingsExtended = HAZOPWorksheetSettings & {
  isGrouped: boolean;
};

export type HazopColumnDto = {
  id: number;
  sequence: number;
  name: string;
  title: string;
  dependency: string;
  type: 0 | 1 | 2 | 3 | 4 | 5;
  selected: boolean;
  mandatory: boolean;
  subColumns: {
    [key: string]: string;
  };
  isCreatedByUser: boolean;
};

export type consequenceRecommendation = {
  id?: number;
  number: number;
  name: string;
  partyResponsible: string;
};

export type HazopRowDto = {
  rowId: number;
  nodeId: number;

  deviationId: number;
  deviationName: string;

  causeId: number;
  causeName: string;
  causeSequence: string;

  consequenceId: number;
  consequenceName: string;

  consequenceCategoryId: string;
  consequenceCategoryName: string;
  consequenceCustomColumns: {
    [key: string]: string;
  };

  consequenceRecommendations: consequenceRecommendation[];
};

type NewHazopRow = {
  deviationId: number;
  id?: number;
  name: string;
  nodeId: number;
  sequence: number;
};

export const useGetHazopRows = (projectId: string, nodeId?: string) => {
  return useQuery(
    ["hazopRow", projectId, nodeId],
    async () => {
      if (nodeId) {
        const { data: hazopRow } = await axios.get<HazopRowDto[]>(
          `/api/hazop/${projectId}/rows/${nodeId}`
        );
        return hazopRow;
      }

      const { data: hazopRow } = await axios.get<HazopRowDto[]>(
        `/api/hazop/${projectId}/rows`
      );
      return hazopRow;
    },
    {
      onError: handleError<unknown>
    }
  );
};

//
// Hazop Consequence
//

type Consequence = {
  consequenceId?: string;
  hazopNodeId: number;
  hazopCauseId: number;
  hazopConsequenceCategoryId?: number | string;
  name?: string;
  data?: string;
  sequence?: number;
};

export const useAddConsequences = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (hazopConsequence: Consequence) => {
      if (hazopConsequence.consequenceId)
        await axios.patch<Consequence>(
          `/api/hazop/${projectId}/consequences/${hazopConsequence.consequenceId}`,
          hazopConsequence
        );
      else
        await axios.post<Consequence>(
          `/api/hazop/${projectId}/consequences`,
          hazopConsequence
        );
      const { data: hazopRow } = await axios.get<HazopRowDto[]>(
        `/api/hazop/${projectId}/rows/${hazopConsequence.hazopNodeId}`
      );
      return hazopRow;
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        // toast.success("Successfully added consequences.");
        queryClient.invalidateQueries(["hazop", projectId]);
        queryClient.refetchQueries();
      }
    }
  );
};

export const usePatchConsequences = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      hazopConsequenceData,
      consequenceId,
      nodeId
    }: {
      hazopConsequenceData: {
        [key: string]: string | string[];
      };
      consequenceId: number;
      nodeId: number;
    }) => {
      const hazopColumns = queryClient.getQueryData<HazopColumnDto[]>([
        "hazopCol",
        projectId
      ]);
      const hazopColumnIds =
        hazopColumns
          ?.map((col) =>
            col.subColumns
              ? Object.keys(col.subColumns).map((sub) => parseInt(sub))
              : col.id
          )
          .flat() ?? [];

      await axios.patch<Consequence>(
        `/api/hazop/${projectId}/consequences/${consequenceId}`,
        {
          data: JSON.stringify(
            Object.fromEntries(
              Object.entries(hazopConsequenceData).filter(([key, _]) =>
                hazopColumnIds.includes(+key)
              )
            )
          )
        }
      );
      const { data: hazopRow } = await axios.get<HazopRowDto[]>(
        `/api/hazop/${projectId}/rows/${nodeId}`
      );
      return hazopRow;
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        // toast.success("Successfully edited consequences.");
        queryClient.invalidateQueries(["hazop", projectId]);
        queryClient.refetchQueries();
      }
    }
  );
};

export const useDeleteConsequences = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      nodeId,
      consequenceId
    }: {
      nodeId: string;
      consequenceId: string | number;
    }) => {
      await axios.delete(
        `/api/hazop/${projectId}/consequences/${consequenceId}`
      );

      const { data: hazopRow } = await axios.get<HazopRowDto[]>(
        `/api/hazop/${projectId}/rows/${nodeId}`
      );

      return hazopRow;
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully deleted consequences.");
        queryClient.invalidateQueries(["hazop", projectId]);
        queryClient.refetchQueries();
      }
    }
  );
};

//
// Hazop Causes
//

export const useAddCauses = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (newHazopCauses: NewHazopRow) => {
      return await axios.post<NewHazopRow>(
        `/api/hazop/${projectId}/causes`,
        newHazopCauses
      );
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully added cause.");
        queryClient.invalidateQueries(["hazop", projectId]);
        queryClient.refetchQueries();
      }
    }
  );
};

export const usePatchCause = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ name, causeId }: { name: string; causeId: string }) => {
      return await axios.patch<{
        name: string;
      }>(`/api/hazop/${projectId}/causes/${causeId}`, { name: name });
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully edited cause.");
        queryClient.invalidateQueries(["hazop", projectId]);
        queryClient.refetchQueries();
      }
    }
  );
};

export const useDeleteCauses = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      nodeId,
      causeId
    }: {
      nodeId: string;
      causeId: string | number;
    }) => {
      await axios.delete(`/api/hazop/${projectId}/causes/${causeId}`);

      const { data: hazopRow } = await axios.get<HazopRowDto[]>(
        `/api/hazop/${projectId}/rows/${nodeId}`
      );

      return hazopRow;
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully deleted cause.");
        queryClient.invalidateQueries(["hazop", projectId]);
        queryClient.refetchQueries();
      }
    }
  );
};

export const useCopyCause = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      causeId,
      sourceCauseId
    }: {
      causeId: number;
      sourceCauseId: number;
    }) => {
      await axios.put(`/api/hazop/${projectId}/causes/${causeId}/copy`, {
        sourceCauseId
      });

      return true;
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully copied cause.");
        queryClient.invalidateQueries(["hazop", projectId]);
        queryClient.refetchQueries();
      }
    }
  );
};

//
// Hazop Columns
//

export const useGetHazopColumns = (projectId: string) => {
  return useQuery(
    ["hazopCol", projectId],
    async () => {
      const { data: hazop } = await axios.get<HazopColumnDto[]>(
        `/api/hazop/columns/${projectId}`
      );

      return (
        hazop
          .map((x, i) => ({ ...x, sequence: i + 1 }))
          // .filter((x) => x.id !== 0)
          //TODO: remove below line later
          .filter((x) => x.title !== "Id")
          .sort((a, b) => a.sequence - b.sequence)
      );
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const usePatchHazopColumn = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ columnId, selected, sequence, targetColumnId }: HazopPatchDto) => {
      if (sequence) {
        const { data } = await axios.patch<HAZOPWorksheetSettings[]>(
          `/api/hazop/columns/${projectId}/${columnId}`,
          { sequence, targetColumnId }
        );
        return data;
      } else {
        const { data } = await axios.patch<HAZOPWorksheetSettings[]>(
          `/api/hazop/columns/${projectId}/${columnId}`,
          { selected }
        );
        return data;
      }
    },
    {
      onMutate: async ({ sequence, event }: HazopPatchDto) => {
        await queryClient.cancelQueries({
          queryKey: ["hazopCol", projectId]
        });

        const previousHazopColumns = queryClient.getQueryData<HazopColumnDto[]>(
          ["hazopCol", projectId]
        );

        if (sequence && previousHazopColumns && event) {
          const { active, over } = event;
          const oldIndex = previousHazopColumns.findIndex(
            (sd) => sd!.id === active!.id
          );
          const newIndex = previousHazopColumns.findIndex(
            (sd) => sd!.id === over!.id
          );
          const optimistic = arrayMove(
            previousHazopColumns,
            oldIndex,
            newIndex
          ).map((x, i) => ({ ...x, sequence: i + 2 }));
          queryClient.setQueryData(["hazopCol", projectId], optimistic);
        }
      },
      onError: handleError<unknown>,
      onSuccess: () => {
        queryClient.invalidateQueries(["hazopCol", projectId]);
      }
    }
  );
};

// TODO: Once Mariusz finishes backend fix these 2 types
export type AddHazopColumnDto = {
  title: string;
};

export type HazopColumn = {};

export const useAddHazopColumn = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (newHazopColumn: AddHazopColumnDto) => {
      const { data } = await axios.post<HazopColumn>(
        `/api/hazop/columns/${projectId}`,
        newHazopColumn
      );
      return data;
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully added new column.");
        queryClient.invalidateQueries(["hazop", projectId]);
        queryClient.refetchQueries();
      }
    }
  );
};

export const deleteHazopColumn = async (
  projectId: string,
  columnId: number
) => {
  const { data: isSuccess } = await axios.delete<boolean>(
    `/api/hazop/columns/${projectId}/${columnId}`
  );
  return isSuccess;
};

export const useDeleteHazopColumn = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (columnId: number) => deleteHazopColumn(projectId, columnId),
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully deleted column.");
        queryClient.invalidateQueries(["hazop", projectId]);
        queryClient.refetchQueries();
      }
    }
  );
};

export const useEditHazopColumn = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (newHazopColumn: HazopColumnDto) => {
      const { data } = await axios.put<HazopColumn>(
        `/api/hazop/columns/${projectId}/${newHazopColumn.id}`,
        newHazopColumn
      );
      return data;
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully edited new column.");
        queryClient.invalidateQueries(["hazop", projectId]);
        queryClient.refetchQueries();
      }
    }
  );
};

export const useAddHazopRecommendation = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (newHazopRecommendation: {
      consequenceId: number;
      name: string;
      partyResponsible: string | null;
    }) => {
      const { data } = await axios.post<{
        consequenceId: number;
        name: string;
        partyResponsible: string | null;
      }>(`/api/hazop/${projectId}/recommendations`, newHazopRecommendation);
      return data;
    },
    {
      onError: (error: { response: { data?: string } }) => {
        const errorValue = Object.values(error.response?.data ?? {});
        toast.error(
          `Error: ${errorValue || "Something went wrong. Please try again later."}`,
          { autoClose: false, hideProgressBar: true }
        );
      },
      onSuccess: () => {
        toast.success("Successfully added new recommendation.");
        queryClient.invalidateQueries(["hazop", projectId]);
        queryClient.refetchQueries();
      }
    }
  );
};

export const useEditHazopRecommendation = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (hazopRecommendation: {
      consequenceId: number;
      recommendationId: number;
      name: string;
      partyResponsible: string | null;
    }) => {
      const { data } = await axios.patch<{
        consequenceId: number;
        recommendationId: number;
        name: string;
        partyResponsible: string | null;
      }>(`/api/hazop/${projectId}/recommendations`, hazopRecommendation);
      return data;
    },
    {
      onError: (error: { response: { data?: string } }) => {
        const errorValue = Object.values(error.response?.data ?? {});
        toast.error(
          `Error: ${errorValue || "Something went wrong. Please try again later."}`,
          { autoClose: false, hideProgressBar: true }
        );
      },
      onSuccess: () => {
        toast.success("Successfully edited recommendation.");
        queryClient.invalidateQueries(["hazop", projectId]);
        queryClient.refetchQueries();
      }
    }
  );
};

export const useDeleteHazopRecommendation = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (hazopRecommendation: {
      recommendationId: number;
      consequenceId: number;
    }) => {
      await axios.delete<boolean>(
        `/api/hazop/${projectId}/recommendations/${hazopRecommendation.recommendationId}/${hazopRecommendation.consequenceId}`
      );
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully deleted recommendation.");
        queryClient.invalidateQueries(["hazop", projectId]);
        queryClient.refetchQueries();
      }
    }
  );
};

export const useGetHazopRecommendationSuggestions = (projectId: string) => {
  return useQuery(
    ["hazopRecommendationsDick", projectId],
    async () => {
      const { data } = await axios.get<{
        recommendations: string[];
        partiesResponsible: string[];
      }>(
        `/api/hazop/${projectId}/recommendations/GetRecommendationsAndPartiesResponsibleForProject`
      );

      return data;
    },
    {
      onError: handleError<unknown>
    }
  );
};
