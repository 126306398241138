import { Typography } from "@mui/material";
import { useGetProfile, useGetProjectProfile } from "api/users";
import Loader from "components/Loader";
import { isAuthorized, RoleEnum, rolePrioritization } from "helpers/isAuthorized";
import { useParams } from "react-router-dom";
import {
  useGetDocumentCategories,
  useGetUploadedFiles,
  useUploadFiles
} from "../../../api/document-categories";
import FileUpload from "../../../components/FileUpload";
import UploadedFilesList from "./UploadedFilesList";
import { useGetAsset } from "api/assets";
import { useMemo } from "react";

const DocumentsUpload = () => {
  const { assetId, projectId, documentCategoryId } = useParams<{ assetId: string, projectId: string; documentCategoryId: string; }>();
  const { data: asset } = useGetAsset(assetId as string);
  const { data: profile } = useGetProfile();
  const { data: projectProfile } = useGetProjectProfile(parseInt(projectId || '0'))

  const { data: documentCategories } = useGetDocumentCategories(projectId as string);
  const { mutateAsync, status: uploadStatus } = useUploadFiles(projectId as string, documentCategoryId as string);
  const { data: uploadedFiles, isLoading } = useGetUploadedFiles(projectId as string, documentCategoryId as string);

  const organizationRoles: RoleEnum[] = useMemo(() =>
    rolePrioritization(projectProfile?.projectRoles, profile?.organizationRoles, asset?.organizationId)
    , [asset?.organizationId, profile?.organizationRoles, projectProfile?.projectRoles])

  const isEditable = isAuthorized({
    role: profile?.roleName as RoleEnum || RoleEnum.GlobalReader,
    organizationRoles: organizationRoles,
    requiredRoles: [RoleEnum.Facilitator, RoleEnum.Scribe, RoleEnum.OrganizationOwner, RoleEnum.Administrator]
  });

  const title =
    documentCategories?.find(
      (documentCategory) => `${documentCategory.id}` === documentCategoryId
    )?.categoryName ?? "No title";

  if (isLoading) return <Loader />;

  return (
    <>
      <Typography variant="h3" sx={{ marginBottom: "2rem", marginTop: "2rem" }}> {title} </Typography>
      {isEditable && (
        <FileUpload
          uploadStatus={uploadStatus}
          onUpload={async (files: File[]) => {
            mutateAsync(files);
          }}
        />
      )}

      <Typography variant="h6" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        Uploaded files
      </Typography>
      {uploadedFiles && uploadedFiles.length > 0 ? (
        <UploadedFilesList uploadedFiles={uploadedFiles} />
      ) : (
        <Typography>No files were uploaded yet.</Typography>
      )}
    </>
  );
};

export default DocumentsUpload;
