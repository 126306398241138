import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, InputAdornment, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useEditTimeframe } from "../../api/timeframes";
import { defaultDateFormatDisplay } from "../../common/date";
import ModalForm from "../../components/ModalForm";
import { TimeframeDto } from "../../types";
import { TimeframeSchema, TimeframeType } from "./AddTimeframeModal";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  timeframe: TimeframeDto;
};

type RouteProps = {
  projectId: string;
};

const EditTimeframeModal = ({ isOpen, handleClose, timeframe }: Props) => {
  const {
    register, handleSubmit, getValues, setValue,
    control, reset, watch, formState: { errors }
  } = useForm<TimeframeType>({
    resolver: zodResolver(TimeframeSchema),
    defaultValues: {
      address: timeframe.address,
      description: timeframe.description,
      duration: timeframe.endDate && timeframe.startDate ? moment(timeframe.endDate, 'DD/MM/YYYY').endOf('day').diff(moment(timeframe.startDate, 'DD/MM/YYYY').startOf('day'), 'days') + 1 : undefined,
      // @ts-ignore
      endDate: moment(timeframe.endDate, 'DD/MM/YYYY').endOf('day'),
      // @ts-ignore
      startDate: moment(timeframe.startDate, 'DD/MM/YYYY').startOf('day'),
    }
  });
  const { projectId } = useParams<keyof RouteProps>() as RouteProps;
  const { mutateAsync } = useEditTimeframe(projectId);

  return (
    <ModalForm
      title={"Edit time frame "}
      isOpen={isOpen}
      handleClose={() => { reset(); handleClose(); }}
      onSubmit={handleSubmit(async (newTimeframe) => {
        const startDate = moment(newTimeframe.startDate).utc(true).startOf('day').toISOString();
        // for some cases end date is move by 1 day  
        const endDate = moment(startDate).subtract(-(newTimeframe.duration - 1), 'day').utc(true).endOf('day').toISOString();
        await mutateAsync({
          timeframeId: timeframe.id,
          updatedTimeframe: {
            ...newTimeframe,
            startDate,
            endDate
          }
        });
        reset();
        handleClose();
      })}
      isLoading={false}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Controller
            name="startDate"
            control={control}
            // @ts-ignore
            defaultValue={null}
            render={({ field }) => (
              <DatePicker
                sx={{ width: "100%" }}
                onChange={(newValue) => {
                  field.onChange(moment(newValue).startOf('day').utc(true));
                  const endDateValue = getValues("endDate")
                  if (endDateValue) setValue("duration", moment(endDateValue).endOf('day').utc(true).diff(moment(newValue).startOf('day').utc(true), 'days') + 1);
                }}
                value={moment(field.value, 'DD/MM/YYYY')}
                label={`Timeframe start date *`}
                format={defaultDateFormatDisplay}
                // Don't allow the user to pick dates later than End Date
                shouldDisableDate={(date) => {
                  const endDate = watch("endDate");

                  if (endDate) {
                    return !(moment(endDate, 'DD/MM/YYYY').endOf('day').isAfter(date));
                  }
                  return false;
                }} //!!endDate && date > endDate}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="endDate"
            control={control}
            // @ts-ignore
            defaultValue={null}
            render={({ field }) => (
              <DatePicker
                sx={{ width: "100%" }}
                onChange={(newValue) => {
                  field.onChange(moment(newValue).endOf('day').utc(true));
                  const startDateValue = getValues("startDate");
                  if (startDateValue) setValue("duration", moment(newValue).endOf('day').utc(true).diff(moment(startDateValue).startOf('day').utc(true), 'days') + 1);
                }}

                value={moment(field.value, 'DD/MM/YYYY')}
                label={`Timeframe end date *`}

                format={defaultDateFormatDisplay}
                // Don't allow the user to pick dates earlier than Start Date
                shouldDisableDate={(date) => {
                  const startDate = watch("startDate");
                  if (startDate) {
                    return !(moment(startDate, 'DD/MM/YYYY').startOf('day').isBefore(date));
                  }
                  return false;
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            {...register("address")}
            fullWidth
            label="Address *"
            error={!!errors.address}
            helperText={errors.address?.message}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            disabled={true}
            {...register("duration", { valueAsNumber: true })}
            type="number"
            fullWidth
            label="Duration *"
            error={!!errors.duration}
            helperText={errors.duration?.message}
            InputProps={{
              endAdornment: <InputAdornment position="end">days</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("description")}
            fullWidth
            label="Description *"
            multiline
            minRows={4}
            error={!!errors.description}
            helperText={errors.description?.message}
          />
        </Grid>
      </Grid>
    </ModalForm >
  );
};

export default EditTimeframeModal;
