import { Button, CircularProgress, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

type Props = {
  isProcessed: boolean;
  status?: string;
  fileStatusError?: string;
  drawingId: number;
  nodeId: number;
};

const DrawingListItemStatus = ({
  isProcessed,
  status,
  fileStatusError,
  drawingId,
  nodeId
}: Props) => {
  const navigate = useNavigate();

  // TODO: Uncomment this
  // if (fileStatusError) {
  //   return (
  //     <Tooltip title={fileStatusError}>
  //       <ErrorIcon color="error" />
  //     </Tooltip>
  //   );
  // }


  // TODO: Uncomment this after initial test on production
  //
  // if (isProcessed || status === undefined || status === "S_COMPLETED") {
  //   return (
  //     <Button
  //       onClick={(e) => {
  //         e.stopPropagation();
  //         navigate(`../nodes/${nodeId}/pids/${drawingId}`);
  //       }}
  //     >
  //       Mark up node
  //     </Button>
  //   );
  // }

  // return (
  //   <>
  //     <Tooltip title={status}>
  //       <CircularProgress size={30} />
  //     </Tooltip>
  //   </>
  // );
  return <></>;
};

export default DrawingListItemStatus;
