import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useUpdateOrganization } from "../../api/organizations";
import ModalForm from "../../components/ModalForm";
import { CompanySimpleDto } from "../../types";

const OrganizationSchema = z.object({
  companyName: z.string().min(1, "Organization name is required").max(256),
  accountId: z.string().max(512),
  companyCode: z.string().max(256)
});

type NewOrganization = z.infer<typeof OrganizationSchema>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  organization: CompanySimpleDto;
};

const EditOrganizationModal = ({
  isOpen,
  handleClose,
  organization
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset
  } = useForm<NewOrganization>({
    resolver: zodResolver(OrganizationSchema),
    defaultValues: {
      accountId: organization?.accountId ?? "",
      companyCode: organization?.companyCode ?? "",
      companyName: organization?.name ?? ""
    }
  });
  const { mutateAsync, isLoading, error } = useUpdateOrganization(
    organization.id
  );

  useEffect(() => {
    if (error) {
      setError("companyName", {
        type: "string",
        message: error.response!.data.name
      });
    }
  }, [error, setError]);

  return (
    <ModalForm
      title={"Edit organization"}
      isOpen={isOpen}
      handleClose={() => { reset(); handleClose(); }}
      onSubmit={handleSubmit(async (updatedOrganization) => {
        await mutateAsync(updatedOrganization);
        reset();
        handleClose();
      })}
      isLoading={isLoading}
      isInAccordion
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("companyName")}
            error={!!errors.companyName?.message}
            helperText={errors.companyName?.message}
            label={`Organization name *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("accountId")}
            error={!!errors.accountId?.message}
            helperText={errors.accountId?.message}
            label={`Account ID *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("companyCode")}
            error={!!errors.companyCode?.message}
            helperText={errors.companyCode?.message}
            label={`Organization code *`}
            fullWidth
          />
        </Grid>
      </Grid>
    </ModalForm >
  );
};

export default EditOrganizationModal;
