import { Autocomplete, Grid, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { useGetNodes, usePatchNode } from "api/node-analysis";
import { NodeListDetailProps } from "modules/node-analysis/manage-nodes/NodesList";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from '@mui/icons-material/Launch';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';

import { useNavigate, useParams } from "react-router-dom";

type RouteParams = {
    assetId: string;
    projectId: string;
    nodeId?: string;
};
const SelectedNodeDetail = ({ node }: NodeListDetailProps) => {
    const { assetId, projectId } = useParams<RouteParams>() as RouteParams;
    return (
        <Grid container direction="column" spacing={1} sx={{ flex: 1, padding: 1 }} data-id={node.id}>
            <Grid item display={"flex"}>
                <Typography fontWeight={"500"} minWidth={200}>Node ID:</Typography>
                <Typography>{node.nodeIdentifier}</Typography>
            </Grid>
            <Grid item display={"flex"}>
                <Typography fontWeight={"500"} minWidth={200}>Node name:</Typography>
                <Typography>{node.name}</Typography>
            </Grid>
            <Grid item display={"flex"}>
                <Typography fontWeight={"500"} minWidth={200}>References:</Typography>
                <Typography>{node?.files.map((file, index) => <span key={`file--${index}`}>
                    {index > 0 ? ', ' : ''}
                    <a href={`/assets/${assetId}/projects/${projectId}/nodeAnalysisResult/${node.id}/pids/${file.id}?showAllNodes=true`} target="_blank" rel="noreferrer">{file.fileName}</a></span>)}
                </Typography>
            </Grid>
            <Grid item display={"flex"}>
                <Typography fontWeight={"500"} minWidth={200}>Design intention:</Typography>
                <Typography>{node.designIntention}</Typography>
            </Grid>
            <Grid item display={"flex"}>
                <Typography fontWeight={"500"} minWidth={200}>Operating conditions:</Typography>
                <Typography display={"inline-flex"}>{node.operatingConditions}</Typography>
            </Grid>
            <Grid item display={"flex"}>
                <Typography fontWeight={"500"} minWidth={200}>Risk classification:</Typography>
                <Typography display={"inline-flex"}>
                    <a href={`/assets/${assetId}/projects/${projectId}/studysetup/riskmatrix#risk-classification`} target="_blank" rel="noreferrer" style={{ display: 'flex' }}>
                        <span>Open in a new window</span> <LaunchIcon style={{ color: 'darkGray', padding: '3px' }} />
                    </a>
                </Typography>
            </Grid>

        </Grid>
    );
};

function NodeSelect() {
    const navigate = useNavigate();

    const { assetId, projectId, nodeId } = useParams<RouteParams>() as RouteParams;
    const { data: nodes, isLoading } = useGetNodes(projectId);
    const { mutateAsync: patchNode } = usePatchNode(projectId, parseInt(nodeId || '0'));

    const linkRef = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        if (nodes?.length && !nodeId) {
            navigate(`/assets/${assetId}/projects/${projectId}/worksheet/${nodes?.[0].id || ''}`)
        }
    }, [assetId, navigate, nodeId, nodes, projectId])

    if (!isLoading && nodes?.length === 0) return <Typography>No nodes</Typography>;

    const handleChange = (e: React.SyntheticEvent<Element, Event>, item: { label: string, value: string } | null) => {
        e.preventDefault();
        navigate(`/assets/${assetId}/projects/${projectId}/worksheet/${item?.value || ''}`)
    };

    const selectedNode = nodes?.find((item) => item.id.toString() === nodeId);
    if (selectedNode)
        return <Grid container direction="row"
            justifyContent="center"
            alignItems="center" spacing={2}>
            <Grid item width={0.5} minWidth={480}>
                <Paper sx={{ marginBottom: '1rem', position: 'relative' }} >

                    <IconButton size={'small'}
                        sx={{ position: 'absolute', left: '-48px', top: '0' }}
                        onClick={() => linkRef.current?.click()}
                        title='Excel file export'
                    >
                        <SimCardDownloadIcon />
                        <a ref={linkRef} href={`/api/hazop/${projectId}/rows/excel`} target="_blank" rel="noreferrer" style={{ display: 'none' }}>download excel</a>
                    </IconButton>

                    <Autocomplete
                        disableClearable={true}
                        disablePortal
                        id="active-node-selector"
                        value={{ label: selectedNode?.name || " - ", value: selectedNode?.id.toString() || " - " }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={handleChange}
                        options={nodes?.map((node) => { return { label: node.name, value: node.id.toString() } }) || []}
                        renderInput={(params) => <TextField {...params} label="Node" />}
                        size={'small'}
                    />

                    <IconButton size={'small'}
                        sx={{ position: 'absolute', right: '-48px', top: '0' }}
                        title={'open in a new window'}
                        onClick={() => {
                            const _screen = window.screen;
                            window.open(
                                `${window.location.origin}${window.location.pathname}?tableOnly=true`,
                                `tableOnly${selectedNode.id}`,
                                `toolbar=yes,scrollbars=yes,resizable=yes,height=${_screen.height},width=${_screen.width},fullscreen=yes`);
                        }}
                    >
                        <LaunchIcon />
                    </IconButton>

                    {nodeId ? <IconButton size={'small'}
                        sx={{ position: 'absolute', right: '-98px', top: '0' }}
                        title={selectedNode?.isCompleted ? 'Worksheet for this node is completed' : 'Worksheet for this node is in progress'}
                        onClick={() =>
                            patchNode({ isCompleted: selectedNode && selectedNode?.isCompleted ? false : true})
                        }
                    >
                        {selectedNode.isCompleted ? <CheckBoxRoundedIcon /> : <CheckBoxOutlineBlankRoundedIcon />}
                    </IconButton> : ''}
                </Paper>
                <Paper sx={{ marginBottom: '2rem' }}>
                    <SelectedNodeDetail node={selectedNode} />
                </Paper>
            </Grid>
        </Grid >;

    //
    // no selected node 
    //
    return <Grid container direction="row"
        justifyContent="center"
        alignItems="center" spacing={2}>
        <Grid item width={0.5} minWidth={480}>
            <Paper sx={{ marginBottom: '1rem', position: 'relative' }} >
                <Autocomplete
                    disableClearable={true}
                    disablePortal
                    id="active-node-selector"
                    value={{ label: " - ", value: " - " }}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={handleChange}
                    options={nodes?.map((node) => { return { label: node.name, value: node.id.toString() } }) || []}
                    renderInput={(params) => <TextField {...params} label="Node" />}
                    size={'small'}
                />
            </Paper>
        </Grid>
    </Grid>;
}

export default NodeSelect;