import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import {
  useAddAssetProperty,
  useUpdateAssetProperty
} from "../../api/asset-properties";
import { AssetProperties, AssetTypeDto, LocationType } from "../../types";

const AssetPropertiesSchema = z.object({
  locationTypeId: z.nativeEnum(LocationType, {
    required_error: "Location type is required"
  }),
  // .nullable()
  // .refine(
  //   (value) => {
  //     return value !== null;
  //   },
  //   { message: "Location type is required" }
  // ),
  assetTypeId: z
    .number({ invalid_type_error: "Asset type is required" })
    .min(1, "Asset type is required")
  // .nullable()
  // .refine(
  //   (value) => {
  //     return value !== null;
  //   },
  //   { message: "Asset type is required" }
  // )
});

type AssetPropertiesType = z.infer<typeof AssetPropertiesSchema>;

type Props = {
  assetProperties: AssetProperties | undefined;
  assetTypes: AssetTypeDto[];
  projectId: string;
  isReadOnly: boolean;
};

const AssetPropertiesForm = ({
  assetProperties,
  assetTypes,
  projectId,
  isReadOnly
}: Props) => {

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<AssetPropertiesType>({
    resolver: zodResolver(AssetPropertiesSchema),
    defaultValues: {
      locationTypeId: assetProperties?.locationTypeId
    }
  });

  const {
    mutateAsync: mutateAddAssetProperties,
    isLoading: isLoadingAddAssetProperty
  } = useAddAssetProperty(projectId);

  const {
    mutateAsync: mutateEditAssetProperties,
    isLoading: isLoadingUpdateAssetProperty
  } = useUpdateAssetProperty(projectId);

  const onSubmit = (newAssetProperties: AssetPropertiesType) => {

    if (assetProperties?.id) {
      mutateEditAssetProperties({
        newAssetProperties,
        assetPropertyId: assetProperties.id
      });
    } else {
      mutateAddAssetProperties(newAssetProperties);
    }
  };

  const options = assetTypes.map((assetType) => assetType.id);
  const defaultValue = assetProperties?.assetTypeId ?? null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl
        sx={{ marginBottom: "0.5rem" }}
        error={Boolean(errors.locationTypeId)}
      >
        <FormLabel id="demo-error-radios">Location type</FormLabel>
        <Controller
          control={control}
          name="locationTypeId"
          render={({ field: { onChange, value, ...rest } }) => {
            return (
              <RadioGroup
                {...rest}
                value={value ?? null}
                onChange={(_, value) => onChange(Number.parseInt(value))}
                aria-labelledby="demo-error-radios"
              >
                <FormControlLabel
                  value={LocationType.OffShore}
                  control={<Radio />}
                  label="Offshore"
                  contentEditable={!isReadOnly || false}
                  disabled={isReadOnly}
                />
                <FormControlLabel
                  value={LocationType.OnShore}
                  control={<Radio />}
                  label="Onshore"
                  contentEditable={!isReadOnly || false}
                  disabled={isReadOnly}
                />
              </RadioGroup>
            );
          }}
        />
        <FormHelperText>{errors.locationTypeId?.message}</FormHelperText>
      </FormControl>

      <Controller
        control={control}
        name="assetTypeId"
        // @ts-ignore need to set value to null to have a controlled input
        defaultValue={defaultValue}
        render={({ field: { onChange, value, ...rest } }) => {
          return (
            <Autocomplete
              onChange={(event, newAssetTypeId) => {
                onChange(newAssetTypeId);
              }}
              value={value}
              sx={{ marginBottom: "0.5rem", maxWidth: "400px" }}
              options={options}
              defaultValue={defaultValue}
              getOptionLabel={(option) =>
                assetTypes.find((assetType) => assetType.id === option)?.name ??
                "No asset types"
              }
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              disabled={isReadOnly}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Asset type *"
                  error={!!errors.assetTypeId}
                  helperText={errors.assetTypeId?.message}
                />
              )}
            />
          );
        }}
      />

      {!isReadOnly && (
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoadingAddAssetProperty || isLoadingUpdateAssetProperty}
        >
          Submit
        </LoadingButton>
      )}
    </form>
  );
};

export default AssetPropertiesForm;
