import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, TextField } from "@mui/material";
import { validCharacters } from "helpers/commonHelpers";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useAddAsset } from "../../api/assets";
import ModalForm from "../../components/ModalForm";

const AssetSchema = z.object({
  name: z
    .string()
    .min(1, "Asset name is required")
    .max(256)
    .refine(
      (value) => validCharacters(value),
      "Asset name should contain only alphanumerical characters and &,- or ()"
    ),
  longitude: z
    .number()
    .min(-180, "Please use value between -180 and 180")
    .max(180, "Please use value between -180 and 180")
    .optional(),
  latitude: z
    .number()
    .min(-90, "Please use value between -90 and 90")
    .max(90, "Please use value between -90 and 90")
    .optional()
});

type NewAsset = z.infer<typeof AssetSchema>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  companyId: number;
};

const AddAssetModal = ({ isOpen, handleClose, companyId }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset
  } = useForm<NewAsset>({
    resolver: zodResolver(AssetSchema)
  });
  const { mutateAsync, isLoading, error } = useAddAsset(companyId);

  useEffect(() => {
    if (error) {
      setError("name", {
        type: "string",
        message: error.response!.data.name
      });
    }
  }, [error, setError]);

  return (
    <ModalForm
      title={"Add Asset"}
      isOpen={isOpen}
      handleClose={() => { reset(); handleClose(); }}
      onSubmit={handleSubmit(async (newAsset) => {
        await mutateAsync({
          ...newAsset
        });
        reset();
        handleClose();
      })}
      isLoading={isLoading}
      isInAccordion
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            label={`Asset name *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="number"
            inputProps={{ step: "any" }}
            {...register("longitude", {
              setValueAs: (v) => (v === "" ? undefined : parseInt(v, 10))
            })}
            error={!!errors.longitude?.message}
            helperText={errors.longitude?.message}
            label={`Longitude`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="number"
            inputProps={{ step: "any" }}
            {...register("latitude", {
              setValueAs: (v) => (v === "" ? undefined : parseInt(v, 10))
            })}
            error={!!errors.latitude?.message}
            helperText={errors.latitude?.message}
            label={`Latitude`}
            fullWidth
          />
        </Grid>
      </Grid>
    </ModalForm >
  );
};

export default AddAssetModal;
