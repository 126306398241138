import axios from "axios";
import {
  defaultDateFormatDisplay,
  defaultDateHewerFormatDisplay
} from "common/date";
import moment from "moment";
import { toast } from "react-toastify";

export const formatDate = (date: string | Date) =>
  moment(date).format(defaultDateFormatDisplay);

export const formatDateToMinutes = (date: string | Date) =>
  moment(date).format(defaultDateHewerFormatDisplay);

export const downloadBlob = (
  fileName: string,
  url: string,
  options: BlobPropertyBag | undefined
) => {
  const link = document.createElement("a");
  link.target = "_blank";
  link.download = fileName;
  axios
    .get(url, {
      responseType: "blob"
    })
    .then((res) => {
      link.href = URL.createObjectURL(new Blob([res.data], options));
      link.click();
    })
    .catch((err) => {
      console.error(err);
      toast.error("Something went wrong.");
    });
};

export const chunkArray = <T>(arr: T[], size: number): T[][] =>
  arr.length > size
    ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)]
    : [arr];

export const validCharacters = (str: string) => {
  const regex = /^[a-z0-9-&' \\(\\)]+$/gi;
  return regex.test(str);
};
