import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, TextField } from "@mui/material";
import { useGetAppRoles } from "api/roles";
import { useGetUsers } from "api/users";
import Loader from "components/Loader";
import TitleWithAddButton from "components/TitleWithAddButton";
import useDebounce from "hooks/useDebounce";
import InviteUserModal from "modules/users/InviteUserModal";
import { useMemo, useState } from "react";
import UsersDatagrid from "modules/admin/UsersDatagrid";

type Props = {};

const AdminPanel = (props: Props) => {
  const { data: roles } = useGetAppRoles();

  const { data: users } = useGetUsers();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query.toLowerCase());

  const filteredUsers = useMemo(
    () =>
      users?.filter((user) => {
        return (
          user.userName?.toLowerCase().includes(debouncedQuery.toLowerCase()) ||
          user.roleName?.toLowerCase().includes(debouncedQuery.toLowerCase()) ||
          user.email?.toLowerCase().includes(debouncedQuery.toLowerCase())
        );
      }) ?? [],
    [users, debouncedQuery]
  );

  if (!users || !roles) return <Loader />;

  return (
    <Box>
      <TitleWithAddButton
        title={"Admin panel"}
        textUnderTitle={"Manage users subscribed to Smart-PHA"}
        modal={
          <InviteUserModal
            isOpen={isInviteModalOpen}
            handleClose={() => setIsInviteModalOpen(false)}
          />
        }
        openModal={() => setIsInviteModalOpen(true)}
        buttonText={"Invite user"}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",

          justifyContent: "flex-end",
          marginBottom: "0.5rem"
        }}
      >
        <TextField
          size="small"
          value={query}
          onChange={(e: { target: { value: string; }; }) => {
            setQuery(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </Box>

      <UsersDatagrid users={filteredUsers} isAdminPage isEditable={true} />
    </Box>
  );
};

export default AdminPanel;
