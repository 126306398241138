import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, SxProps, Theme, Typography } from "@mui/material";
import Title from "./Title";

type Props = {
  title: string;
  textUnderTitle?: string;
  openModal?: () => void;
  buttonText?: string;
  secondaryButtonText?: string;
  secondaryButtonUrl?: string;
  modal?: JSX.Element;
  titleVariant?:
  | "button"
  | "caption"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "inherit"
  | "overline"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | undefined;
  isButtonVisible?: boolean;
  buttonSx?: SxProps<Theme> | undefined;
};

const TitleWithAddButton = ({
  title,
  titleVariant = "h3",
  textUnderTitle,
  buttonText,
  buttonSx,
  secondaryButtonText,
  secondaryButtonUrl,
  modal,
  openModal,
  isButtonVisible = true
}: Props) => {
  return (
    <Title>
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Typography variant={titleVariant}>{title}</Typography>
          {isButtonVisible && <Box>
            {secondaryButtonText && <Button
              sx={{ marginRight: '1em' }}
              href={secondaryButtonUrl}
              startIcon={<DownloadIcon />}
              variant="outlined"
            >
              {secondaryButtonText}
            </Button>}
            {buttonText && openModal ? <Button
              sx={buttonSx}
              startIcon={<AddIcon />}
              variant="contained"
              onClick={openModal}
            >
              {buttonText}
            </Button> : ''}
          </Box>}
        </Box>
        {textUnderTitle && (
          <Typography color={"GrayText"}>{textUnderTitle}</Typography>
        )}
        {modal ? modal : ''}
      </>
    </Title>
  );
};

export default TitleWithAddButton;
