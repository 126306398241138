import { Autocomplete, TextField, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  useGetSelectedMatrixType,
  usePatchMatrix
} from "../../../api/matrixes";
import Loader from "../../../components/Loader";
import Title from "../../../components/Title";
import { MatrixTypes } from "../../../types";
import DnvRiskMatrix from "./DnvRiskMatrix";
import { useGetProfile } from "api/users";
import { isAuthorized, RoleEnum, rolePrioritization } from "helpers/isAuthorized";
import { useGetAsset } from "api/assets";
import { useMemo } from "react";

const OPTIONS = [
  {
    value: MatrixTypes.NULL,
    label: "No matrix"
  },
  {
    value: MatrixTypes.DNV,
    label: "DNV risk matrix"
  },
  {
    value: MatrixTypes.CUSTOM,
    label: "Custom risk matrix"
  }
];

type RouteParams = {
  assetId: string;
  projectId: string;
};

const RiskMatrix = () => {
  const { assetId, projectId } = useParams<RouteParams>() as RouteParams;
  const {
    data: selectedMatrixType,
    isFetching,
    isLoading: isLoadingSelectedMatrixType
  } = useGetSelectedMatrixType(projectId);
  const { mutateAsync: patchSelectedMatrix, isLoading: isLoadingPatchMatrix } =
    usePatchMatrix(projectId);

  const { data: profile } = useGetProfile();
  const { data: asset } = useGetAsset(assetId);

  const organizationRoles: RoleEnum[] = useMemo(() =>
    rolePrioritization([], profile?.organizationRoles, asset?.organizationId)
    , [asset, profile])


  if (isFetching || isLoadingSelectedMatrixType || isLoadingPatchMatrix || !profile || !asset) {
    return <Loader />;
  }

  const isReadOnly = !isAuthorized({
    role: profile.roleName as RoleEnum,
    organizationRoles: organizationRoles,
    requiredRoles: [RoleEnum.Administrator, RoleEnum.OrganizationOwner]
  });

  const defaultValue = OPTIONS.find(
    (option) => option.value === selectedMatrixType
  );

  return (
    <>
      <Title>
        <Typography variant="h3">Risk matrix</Typography>
      </Title>

      <Autocomplete
        sx={{ maxWidth: "400px" }}
        options={OPTIONS}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} label="Risk matrix" />}
        defaultValue={defaultValue}
        value={OPTIONS.find((option) => option.value === selectedMatrixType)}
        onChange={(event, newValue) => {
          patchSelectedMatrix(newValue?.value ?? OPTIONS[0].value);
        }}
        disabled={isReadOnly}
      />

      <DnvRiskMatrix isReadOnly={isReadOnly} isCustomType={selectedMatrixType === MatrixTypes.CUSTOM} />
    </>
  );
};

export default RiskMatrix;
