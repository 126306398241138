import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { useAddConsequenceCategory } from "../../../../../api/matrixes";
import ModalForm from "../../../../../components/ModalForm";

export const ConsequenceCategorySchema = z.object({
  index: z.number({ required_error: "Index is required" }),
  name: z.string({ required_error: "Name is required" }).min(1, "Name is required")
});

export type NewConsequenceCategory = z.infer<typeof ConsequenceCategorySchema>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

type RouteParams = {
  projectId: string;
  assetId: string;
};

const AddConsequenceCategoryModal = ({ isOpen, handleClose }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<NewConsequenceCategory>({
    resolver: zodResolver(ConsequenceCategorySchema)
  });
  const { projectId } = useParams<RouteParams>() as RouteParams;
  const { mutateAsync, isLoading } = useAddConsequenceCategory(projectId);

  return (
    <ModalForm
      title={"Add custom consequence category"}
      isOpen={isOpen}
      handleClose={() => { reset(); handleClose(); }}
      onSubmit={handleSubmit(async (newConsequenceCategory) => {
        await mutateAsync(newConsequenceCategory);
        reset();
        handleClose();
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("index", {
              valueAsNumber: true
            })}
            inputMode="numeric"
            error={!!errors.index?.message}
            helperText={errors.index?.message}
            label={`Index *`}
            type="number"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            label={`Name *`}
            fullWidth
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default AddConsequenceCategoryModal;
