import { Action } from "./action.enum";
import { Box, Button } from "@mui/material";
import { fabric } from "fabric";
import { NewLineCoordsItem, useAddLineCoords, useDeleteDotCoords, useDeleteLineCoords, useGetAllLineCoords, useGetLineCoords } from "api/pid";
import { PADDING_LEFT_RIGHT } from "components/Root";
import { toast } from "react-toastify";
import { useAddSymbolCoords, useChangeSymbolCoords, useGetNodeMarkups, useRemoveSymbolCoords } from "api/nodeMarkups";
import { useBodySize } from "hooks/useBodySize";
import { useGetAdePredictions } from "api/adepredictions";
import { useLocation, useParams } from "react-router-dom";
import Canvas from "./Canvas";
import pidError from "./pidError.png";
import pidLoading from "./pidLoading.png";
import React, { useRef, useState } from "react";
import Predictions from "./Predictions";
import { useGetNodes } from "api/node-analysis";

export type RouteProps = {
  projectId: string;
  assetId: string;
  fileId: string;
  nodeId: string;
};

type Props = {
  showResult?: boolean
  allNodesIds?: number[]
};
// Don't run it on dev with strict mode on because of double render
const AnalyzePid = ({ showResult = false, allNodesIds = [] }: Props) => {
  const fabricRef = React.useRef<fabric.Canvas | null>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const myCanvasRef = React.useRef<Canvas | null>(null);

  const { projectId, nodeId, fileId } = useParams<RouteProps>() as RouteProps;

  const { data: nodes } = useGetNodes(projectId);

  const { data: lineCoords } = useGetLineCoords(nodeId, fileId);
  const { data: lineCoordsArr } = useGetAllLineCoords(allNodesIds, fileId);

  const { mutateAsync: addLineCoords } = useAddLineCoords(nodeId, fileId);
  const { mutateAsync: deleteLineCoords } = useDeleteLineCoords(nodeId, fileId);
  const { mutateAsync: deleteDotCoords } = useDeleteDotCoords(nodeId, fileId);

  const { data: nodeMarkups } = useGetNodeMarkups(nodeId);
  // const { data: nodeMarkupsArr } = useGetAllNodeMarkups(allNodesIds)
  const { mutateAsync: addCircleCoords } = useAddSymbolCoords(nodeId);
  const { mutateAsync: changeCircleCoords } = useChangeSymbolCoords(nodeId);
  const { mutateAsync: deleteSymbolCoords } = useRemoveSymbolCoords(nodeId);

  const { data: adePredictions } = useGetAdePredictions(projectId, nodeId, fileId);

  const [action, setAction] = useState<Action>(Action.Nothing);

  const bodySize = useBodySize();
  const { search } = useLocation();
  const canvasWrapper = useRef<HTMLElement>();

  const changeAction = (newAction: Action) => {
    setAction(newAction);
  };

  const loadBackgroundImage = () => {
    var image = new Image();
    image.onerror = function (e) {
      toast.error("Image not generated - reupload pdf ");
    }
    image.src = `/api/projects/${projectId}/nodes/${nodeId}/images/${fileId}`;
    //
    fabric.Image.fromURL(pidError, function (staticImage) {
      fabric.Image.fromURL(`/api/projects/${projectId}/nodes/${nodeId}/images/${fileId}`, function (img) {
        if (img.width === 0 || img.height === 0) img = staticImage;
        if (canvasWrapper.current) canvasWrapper.current.style.background = ""
        fabricRef.current?.setBackgroundImage(
          img,
          fabricRef.current.renderAll.bind(fabricRef.current)
        );
        // @ts-ignore
        const canvas = fabricRef.current as fabric.Canvas;
        canvas.zoomToPoint(
          new fabric.Point(0, 0),
          canvas.getZoom() / ((img.height as number) / 1600)
        );
      });
    });
  };

  React.useEffect(() => {
    const initFabric = () => {
      fabricRef.current = new fabric.Canvas(canvasRef.current);
      fabricRef.current.setWidth(1024);
      fabricRef.current.setHeight(600);

      myCanvasRef.current = new Canvas(
        fabricRef.current,
        [],
        [],
        changeAction,
        addLineCoordsFromCanvas,
        deleteLineCoordsFromCanvas,
        addSymbolCoordsFromCanvas,
        changeSymbolCoordsOnCanvas,
        deleteSymbolCoordsFromCanvas,
        showResult
      );

      loadBackgroundImage();
      myCanvasRef.current.initEventListeners(fabricRef.current);
    };

    const disposeFabric = () => {
      fabricRef.current?.dispose();
    };

    initFabric();

    return () => {
      disposeFabric();
    };
  }, []); // eslint-disable-line

  const currentNodeColor = React.useMemo(() => parseInt(nodes?.find(n => n.id.toString() === nodeId)?.nodeIdentifier?.split('-')?.[1] || '1') - 1, [nodeId, nodes])
  const colorsIdsArr = React.useMemo(() =>
    allNodesIds.map(nid => nodes?.find(n => n.id === nid)?.nodeIdentifier).map(nodeId => parseInt(nodeId?.split('-')?.[1] || '0') - 1)
    , [allNodesIds, nodes])

  React.useEffect(() => {
    if (lineCoords && search.includes('showAll')) {
      clearCanvas();
      Predictions.Config.nearRange = 0;

      lineCoordsArr?.map((lineCoords, lineCoordsInd) => {
        myCanvasRef.current?.loadLinesFromDatabase(lineCoords, [], [], colorsIdsArr[lineCoordsInd]);
        return true;
      })
    } else {
      Predictions.Config.nearRange = 250;
    }
  }, [lineCoords, lineCoordsArr, nodeId, search, colorsIdsArr])

  React.useEffect(() => {
    if (fabricRef.current !== undefined && bodySize.width && bodySize.height) {
      fabricRef.current?.setWidth(bodySize.width - 2 * PADDING_LEFT_RIGHT);
      fabricRef.current?.setHeight(bodySize.height);
    }
  }, [bodySize]);

  React.useEffect(() => {
    if (myCanvasRef.current) {
      myCanvasRef.current.action = action;
    }
  }, [action]);

  // After fetching from api initialize canvas with lines
  React.useEffect(() => {
    if (lineCoords && nodeMarkups && !search.includes('showAll')) {
      clearCanvas();

      myCanvasRef.current?.loadLinesFromDatabase(lineCoords, nodeMarkups?.circles, nodeMarkups?.rectangle, currentNodeColor);
    }
  }, [currentNodeColor, lineCoords, nodeMarkups, search]);

  React.useEffect(() => {
    if (adePredictions) {
      myCanvasRef.current?.loadAdePredictions(adePredictions);
    }
  }, [adePredictions]);

  const clearCanvas = () => {
    const canvas = fabricRef.current as fabric.Canvas;
    canvas.remove(...canvas.getObjects());
  };

  const clearCanvasAndLine = () => {
    clearCanvas();
    deleteLineCoords();
    myCanvasRef.current?.setActiveCircle(null);
  };

  const leftActions = [
    {
      action: Action.MarkupNode,
      text: "Mark up node"
    },
    {
      action: Action.StartNewLine,
      text: "New line"
    },
    {
      action: Action.AddSymbol,
      text: "Add symbol"
    },
    {
      action: Action.SelectText,
      text: "Select text"
    }
  ];

  const addLineCoordsFromCanvas = (newLineCoords: NewLineCoordsItem) => {
    addLineCoords(newLineCoords).then(newDot => console.log(newDot));
  };

  const deleteLineCoordsFromCanvas = (idCoord: number) => {
    deleteDotCoords(idCoord);
  };

  const addSymbolCoordsFromCanvas = async (newCircleCoords: any, fabricElem: fabric.Circle | fabric.Rect) => {
    const newSymbolCoordsDb = await (addCircleCoords({ ...newCircleCoords, nodeId: parseInt(nodeId) }));
    if (fabricElem && newSymbolCoordsDb.type === '1') fabricElem.name = "circle_" + newSymbolCoordsDb.id;
    if (fabricElem && newSymbolCoordsDb.type === '2') fabricElem.name = "rectangle_" + newSymbolCoordsDb.id;
  };
  const changeSymbolCoordsOnCanvas = (elementId: number, fabricElemData: { x: number; y: number }) => changeCircleCoords({ elementId, data: fabricElemData });

  const deleteSymbolCoordsFromCanvas = (elementId: number) => deleteSymbolCoords(elementId);

  if (showResult)
    return (
      <Box style={{ background: `url(${pidLoading}) no-repeat top left`, backgroundSize: "100% auto", minHeight: 600 }} ref={canvasWrapper}>
        <canvas ref={canvasRef} style={{ border: "1px solid black" }} />
      </Box>
    );

  const menuStyles = { display: search.includes('showAllNodes') ? "none" : "flex", justifyContent: "space-between" };
  return (
    <Box>
      <Box sx={menuStyles}>
        <Box>
          {leftActions.map((leftAction) => {
            return (
              <Button
                key={leftAction.action}
                size="large"
                variant="contained"
                color={action === leftAction.action ? "secondary" : "primary"}
                sx={{ marginBottom: "0.5rem", marginRight: "0.5rem" }}
                onClick={() => {
                  setAction((prev) => {
                    if (prev === leftAction.action) {
                      return Action.Nothing;
                    }
                    return leftAction.action;
                  });
                }}
              >
                {leftAction.text}
              </Button>
            );
          })}
        </Box>
        <Button
          size="large"
          variant="contained"
          color="warning"
          sx={{ marginBottom: "0.5rem", marginLeft: "0.5rem" }}
          onClick={() => clearCanvasAndLine()}
        >
          Clear
        </Button>
      </Box>
      <Box style={{ background: `url(${pidLoading}) no-repeat top left`, backgroundSize: "100% auto", minHeight: 600 }} ref={canvasWrapper}>
        <canvas ref={canvasRef} style={{ border: "1px solid black" }} />
      </Box>
    </Box>
  );
};

export default AnalyzePid;
