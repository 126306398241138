import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, TextField } from "@mui/material";
import { HazopColumnDto, useEditHazopColumn, useGetHazopColumns } from "api/hazop";
import ModalForm from "components/ModalForm";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

export const HazopColumnSchema = z.object({
  title: z.string({ required_error: "Title is required" })
});

export type NewHazopColumn = z.infer<typeof HazopColumnSchema>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  column: HazopColumnDto;
};

type RouteParams = {
  projectId: string;
  assetId: string;
};

const EditHazopColumnModal = ({ isOpen, handleClose, column }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<NewHazopColumn>({
    resolver: zodResolver(HazopColumnSchema),
    defaultValues: { title: column.title }
  });
  const { projectId } = useParams<RouteParams>() as RouteParams;
  const { data: hazopColumns } = useGetHazopColumns(projectId);
  const { mutateAsync, isLoading } = useEditHazopColumn(projectId);
  const [duplicationError, setDuplicationError] = useState<string | undefined>();

  return (
    <ModalForm
      title={"Edit PHA column"}
      isOpen={isOpen}
      handleClose={() => { reset(); handleClose(); }}
      onSubmit={handleSubmit(async (newHazopColumn) => {
        if (newHazopColumn.title.trim() !== newHazopColumn.title.replace(/[^\w\s]/g, '').trim()) {
          setDuplicationError('Column name can\'t contain special characters');
        } else if (hazopColumns?.map(col => col.title.toLocaleLowerCase()).includes(newHazopColumn.title.toLocaleLowerCase())) {
          setDuplicationError('Column name must be unique in PHA table');
        } else {
          await mutateAsync({ ...column, title: newHazopColumn.title });

          reset();
          handleClose();
        }
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("title")}
            error={!!(errors.title?.message || duplicationError)}
            helperText={errors.title?.message || duplicationError}
            fullWidth
            label="Title"
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default EditHazopColumnModal;
