import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, TextField } from "@mui/material";
import { useGetSelectedMatrix } from "api/matrixes";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";
import { useAddCustomLikelihood } from "../../../../../api/likelihood";
import ModalForm from "../../../../../components/ModalForm";

export const LikelihoodSchema = z.object({
  index: z.number({ required_error: "Index is required" }),
  name: z.string({ required_error: "Name is required" }).min(1, "Name is required"),
  description: z.string({ required_error: "Description is required" }).min(1, "Description is required")
});

export type LikelihoodType = z.infer<typeof LikelihoodSchema>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

type RouteParams = {
  projectId: string;
  assetId: string;
};

const AddLikelihoodModal = ({ isOpen, handleClose }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<LikelihoodType>({
    resolver: zodResolver(LikelihoodSchema)
  });
  const { projectId } = useParams<RouteParams>() as RouteParams;
  const { mutateAsync, isLoading } = useAddCustomLikelihood(projectId);
  const { data: selectedMatrix } = useGetSelectedMatrix(projectId);

  return (
    <ModalForm
      title={"Add custom likelihood"}
      isOpen={isOpen}
      handleClose={() => { reset(); handleClose(); }}
      onSubmit={handleSubmit(async (newCustomLikelihood) => {
        if (selectedMatrix) {
          await mutateAsync(newCustomLikelihood);
          reset();
          handleClose();
        } else {
          toast.error("Selected matrix could not be fetched.");
        }
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("index", {
              valueAsNumber: true
            })}
            inputMode="numeric"
            type="number"
            error={!!errors.index?.message}
            helperText={errors.index?.message}
            label={`Index *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            label={`Name *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("description")}
            error={!!errors.description?.message}
            helperText={errors.description?.message}
            label={`Description *`}
            fullWidth
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default AddLikelihoodModal;
