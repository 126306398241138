import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography
} from "@mui/material";
import { grey, lightBlue } from "@mui/material/colors";
import { Box } from "@mui/system";
import { useGetFileStatus } from "api/node-analysis";
import { useParams } from "react-router-dom";
import { FileMetaData, StatusDto } from "types";
// import DrawingListDetailsTable from "./DrawingListDetailsTable";
import DrawingListItemStatus from "./DrawingListItemStatus";

type Props = { drawings: FileMetaData[]; nodeId: number };
type RouteProps = {
  projectId: string;
};

export const DrawingListItem = ({
  drawing,
  nodeId
}: {
  drawing: FileMetaData;
  nodeId: number;
}) => {
  const { projectId } = useParams<RouteProps>() as RouteProps;

  const isProcessed = drawing.predictionCount > 0;
  const { data: fileStatus, error: fileStatusError } = useGetFileStatus(
    projectId,
    drawing.id,
    !isProcessed
  );

  return (
    <Accordion key={drawing.id} sx={{ width: "100%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          width: "100%",
          "&.Mui-expanded": {
            background: lightBlue[50]
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%"
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>{drawing.fileName}</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "30px"
            }}
          >
            <DrawingListItemStatus
              isProcessed={isProcessed}
              fileStatusError={
                (fileStatusError as StatusDto | undefined)?.status
              }
              status={fileStatus?.status}
              drawingId={drawing.id}
              nodeId={nodeId}
            />
          </Box>
        </Box>
      </AccordionSummary>
      <Divider />
      <AccordionDetails
        sx={{
          borderBottom: `1px solid ${grey[300]}`,
          display: "flex",
          justifyContent: "space-between",
          padding: 0
        }}
      >
        {/* TODO: <DrawingListDetailsTable /> - no data so hidden */}
      </AccordionDetails>
    </Accordion>
  );
};

const DrawingsList = ({ drawings, nodeId }: Props) => {
  return (
    <Box sx={{ flex: 1 }}>
      <Typography variant="h6" sx={{ marginBottom: "0.5rem" }}>
        Drawings
      </Typography>
      {drawings.length === 0 && <Typography>No drawings</Typography>}
      {drawings?.map((drawing) => {
        return (
          <DrawingListItem key={drawing.id} drawing={drawing} nodeId={nodeId} />
        );
      })}
    </Box>
  );
};

export default DrawingsList;
