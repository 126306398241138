import ProtectedRoute from "components/ProtectedRoute";
import { RoleEnum } from "helpers/isAuthorized";
import AdminPanel from "modules/admin/AdminPanel";
import Hazop from "modules/hazop/Hazop";
import ManageNodes from "modules/node-analysis/manage-nodes/ManageNodes";
import NodeAnalysis from "modules/node-analysis/NodeAnalysis";
import Pids from "modules/node-analysis/Pids";
import Predictions from "modules/node-analysis/Predictions";
import HazopSetup from "modules/study-setup/hazop-setup/HazopSetup";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route
} from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import Root from "./components/Root";
import Organizations from "./modules/organizations/Organizations";
import Participants from "./modules/participants/Participants";
import ProjectDetail from "./modules/project/ProjectDetail";
import ProjectWithBreadcrumbs from "./modules/project/ProjectWithBreadcrumbs";
import AssetProperties from "./modules/study-setup/AssetProperties";
import Deviations from "./modules/study-setup/deviations/Deviations";
import DocumentsUpload from "./modules/study-setup/documents/DocumentsUpload";
import RiskMatrix from "./modules/study-setup/risk-matrix/RiskMatrix";
import StudySetup from "./modules/study-setup/StudySetup";
import Users from "./modules/users/Users";
import TimeFramesWithSessions from "modules/workshop/timeframe-sessions/TimeframesWithSessions";
import Workshop from "./modules/workshop/Workshop";
import WorkshopTimeFramesDataGrid from "./modules/workshop/WorkshopTimeFramesDataGrid";
import HazopStatus from "modules/hazop/HazopStatus";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<ErrorPage />}>
      <Route
        path="/admin-panel"
        element={
          <ProtectedRoute requiredRoles={[RoleEnum.Administrator]}>
            <AdminPanel />
          </ProtectedRoute>
        }
      />
      <Route path="/" element={<Organizations />} />

      <Route path="/organizations/:organizationId/users" element={<Users />} />

      <Route
        path="/assets/:assetId/projects/:projectId/studysetup"
        element={<StudySetup />}
        handle={{
          crumb: "Study setup"
        }}
      >
        <Route
          path="/assets/:assetId/projects/:projectId/studysetup/properties"
          element={<AssetProperties />}
          handle={{
            crumb: "Asset properties"
          }}
        />
        <Route
          path="/assets/:assetId/projects/:projectId/studysetup/pha"
          element={<HazopSetup />}
          handle={{
            crumb: "PHA worksheet"
          }}
        />
        <Route
          path="/assets/:assetId/projects/:projectId/studysetup/deviations"
          element={<Deviations />}
          handle={{
            crumb: "Deviations"
          }}
        />
        <Route
          path="/assets/:assetId/projects/:projectId/studysetup/riskmatrix"
          element={<RiskMatrix />}
          handle={{
            crumb: "Risk matrix"
          }}
        />
        <Route
          path={`/assets/:assetId/projects/:projectId/studysetup/documentsCategories/:documentCategoryId`}
          element={<DocumentsUpload />}
          handle={{
            crumb: "Documents"
          }}
        />
      </Route>

      <Route
        path="/assets/:assetId/projects"
        element={<ProjectWithBreadcrumbs />}
      >
        <Route
          path="/assets/:assetId/projects/:projectId"
          element={<ProjectDetail />}
        />

        <Route
          path="/assets/:assetId/projects/:projectId/participants"
          element={<Participants />}
          handle={{
            crumb: "Participants"
          }}
        />

        <Route
          path="/assets/:assetId/projects/:projectId/workshop"
          element={<Workshop />}
          handle={{
            crumb: "Workshop Schedule"
          }}
        >
          <Route
            path="/assets/:assetId/projects/:projectId/workshop/schedule"
            element={<WorkshopTimeFramesDataGrid />}
          />
          <Route
            path="/assets/:assetId/projects/:projectId/workshop/sessions"
            element={<TimeFramesWithSessions />}
          />
        </Route>

        <Route
          path="/assets/:assetId/projects/:projectId/nodeAnalysisResult/:nodeId/pids/:fileId"
          element={<Pids edit={false} />}
          handle={{
            crumb: "Node analysis result"
          }}
        />
        <Route
          path="/assets/:assetId/projects/:projectId/nodeanalysis"
          element={<NodeAnalysis />}
          handle={{
            crumb: "Node analysis"
          }}
        >
          <Route
            path="/assets/:assetId/projects/:projectId/nodeanalysis/nodes"
            element={<ManageNodes />}
          />
          <Route
            path="/assets/:assetId/projects/:projectId/nodeanalysis/nodes/:nodeId/pids/:fileId"
            element={<Pids />}
          />
          <Route
            path="/assets/:assetId/projects/:projectId/nodeanalysis/predictions"
            element={<Predictions />}
          />
        </Route>

        <Route
          path="/assets/:assetId/projects/:projectId/worksheet"
          element={<Hazop />}
        />
        <Route
          path="/assets/:assetId/projects/:projectId/worksheet/:nodeId"
          element={<Hazop />}
        />

        <Route
          path="/assets/:assetId/projects/:projectId/status"
          element={<HazopStatus />}
        />
      </Route>
    </Route>
  )
);
