import { Container } from "@mui/material";
import NodesListTitle from "./NodesListTitle";
import NodesList from "./NodesList";
import Loader from "components/Loader";
import { useGetProfile } from "api/users";
import { isAuthorized, RoleEnum, rolePrioritization } from "helpers/isAuthorized";
import { useParams } from "react-router-dom";
import { useGetAsset } from "api/assets";
import { useMemo } from "react";

type Props = {};

type RouteParams = {
  assetId: string;
  projectId: string;
};

const ManageNodes = (props: Props) => {
  const { assetId } = useParams<RouteParams>() as RouteParams;
  const { data: profile } = useGetProfile();
  const { data: asset } = useGetAsset(assetId);

  const organizationRoles: RoleEnum[] = useMemo(() =>
    rolePrioritization([], profile?.organizationRoles, asset?.organizationId)
    , [asset, profile])


  if (!profile || !asset) return <Loader />;

  const isReadOnly = !isAuthorized({
    role: profile.roleName as RoleEnum,
    organizationRoles: organizationRoles,
    requiredRoles: [RoleEnum.Administrator, RoleEnum.OrganizationOwner]
  });

  return (
    <Container>
      <NodesListTitle isReadOnly={isReadOnly} />
      <NodesList isReadOnly={isReadOnly} />
    </Container>
  );
};

export default ManageNodes;
