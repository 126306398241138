import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, InputAdornment, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { useAddTimeframeToWorkshop } from "../../api/timeframes";
import { defaultDateFormatDisplay } from "../../common/date";
import ModalForm from "../../components/ModalForm";
// import { TimeframeDto } from "types";

export const TimeframeSchema = z.object({
  startDate: z.coerce.date(),
  endDate: z.coerce.date({ invalid_type_error: "End date is required" }),
  duration: z.number({ invalid_type_error: "Duration is required" }).min(1),
  description: z.string().min(1, { message: "Description is required" }).max(1500, { message: "Description is to long" }),
  address: z.string().min(1, { message: "Address is required" })
});

export type TimeframeType = z.infer<typeof TimeframeSchema>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

type RouteProps = {
  projectId: string;
};

const AddTimeframeModal = ({ isOpen, handleClose }: Props) => {
  const {
    register, handleSubmit, getValues, setValue,
    control, reset, watch, formState: { errors }
  } = useForm<TimeframeType>({
    resolver: zodResolver(TimeframeSchema),
    mode: "all",
    defaultValues: {
      duration: 0,
    }
  });
  const { projectId } = useParams<keyof RouteProps>() as RouteProps;

  const { mutateAsync, isLoading } = useAddTimeframeToWorkshop(
    Number.parseInt(projectId, 10)
  );

  return (
    <ModalForm
      title={"Add time frame"}
      isOpen={isOpen}
      handleClose={() => { reset(); handleClose(); }}
      onSubmit={handleSubmit(async (newTimeframe) => {
        const startDate = moment(newTimeframe.startDate).utc(true).startOf('day').toISOString();
        const endDate = moment(newTimeframe.endDate).utc(true).subtract(1, 'day').endOf('day').toISOString();
        await mutateAsync({
          ...newTimeframe,
          startDate,
          endDate
        });
        reset();
        handleClose();
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Controller
            name="startDate"
            control={control}
            // @ts-ignore
            defaultValue={null}
            render={({ field }) => (
              <DatePicker
                sx={{ width: "100%" }}
                onChange={(newValue) => {
                  field.onChange(moment(newValue).startOf('day').utc(true));
                  const endDateValue = getValues("endDate")
                  if (endDateValue) setValue("duration", moment(endDateValue).utc(true).endOf('day').diff(moment(newValue).utc(true).startOf('day'), 'days') + 1);
                }}
                value={moment(field.value)}
                label="Timeframe start date *"
                format={defaultDateFormatDisplay}
                // Don't allow the user to pick dates later than End Date
                shouldDisableDate={(date) => {
                  const endDate = watch("endDate");
                  if (endDate) {
                    return !(moment(endDate).endOf('day').isAfter(date));
                  }
                  return false;
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="endDate"
            control={control}
            // @ts-ignore
            defaultValue={null}
            render={({ field }) => (
              <DatePicker
                sx={{ width: "100%" }}
                onChange={(newValue) => {
                  field.onChange(moment(newValue).endOf('day').utc(true))
                  const startDateValue = getValues("startDate");
                  if (startDateValue) setValue("duration", moment(newValue).utc(true).endOf('day').diff(moment(startDateValue).utc(true).startOf('day'), 'days') + 1);
                }}
                value={moment(field.value)}
                label="Timeframe end date *"
                format={defaultDateFormatDisplay}
                // Don't allow the user to pick dates earlier than Start Date
                shouldDisableDate={(date) => {
                  const startDate = watch("startDate") as Date;
                  if (startDate) {
                    return !(moment(startDate).subtract(1, 'day').startOf('day').isBefore(date));
                  }
                  return false;
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            {...register("address")}
            fullWidth
            label="Address *"
            error={!!errors.address}
            helperText={errors.address?.message}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            disabled={true}
            {...register("duration", { valueAsNumber: true })}
            type="number"
            fullWidth
            label="Duration *"
            error={!!errors.duration}
            helperText={errors.duration?.message}
            InputProps={{
              endAdornment: <InputAdornment position="end">days</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("description")}
            fullWidth
            label="Description *"
            multiline
            minRows={4}
            error={!!errors.description}
            helperText={errors.description?.message}
          />
        </Grid>
      </Grid>
    </ModalForm >
  );
};

export default AddTimeframeModal;
