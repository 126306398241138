import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { useGetSelectedMatrixConsequenceCategories } from "api/matrixes";
import {
  SeverityDto,
  useDeleteSeverity,
  useGetSelectedSeverity
} from "api/severity";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import TitleWithAddButton from "../../../../../components/TitleWithAddButton";
import AddSeverityModal from "./AddSeverityModal";
import EditSeverityModal from "./EditSeverityModal";

type RouteParams = {
  projectId: string;
  assetId: string;
};

type Props = {
  isReadOnly: boolean;
  isDnv?: boolean;
};

const CustomSeverity = ({ isReadOnly, isDnv }: Props) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedSeverity, setSelectedSeverity] = useState<SeverityDto | null>(
    null
  );

  const { projectId } = useParams<RouteParams>() as RouteParams;

  const { data: customSeverities } = useGetSelectedSeverity(projectId);
  const { data: consequenceCategories } =
    useGetSelectedMatrixConsequenceCategories(projectId);
  const { mutateAsync: deleteSeverity } = useDeleteSeverity(projectId);

  const mappedSeverities = useMemo(() => customSeverities?.map((x) => ({
    ...x,
    customColumns: x?.customColumns.map((y) => {
      const consequenceCategory = consequenceCategories?.find(
        (z) => z.id === y.id
      );

      return {
        ...y,
        ...consequenceCategory
      };
    }) || []
  })), [consequenceCategories, customSeverities]);

  return (
    <>
      <TitleWithAddButton
        title={"Severity Index"}
        textUnderTitle={""}
        modal={
          <AddSeverityModal
            isOpen={isAddModalOpen}
            handleClose={() => setIsAddModalOpen(false)}
          />
        }
        openModal={() => setIsAddModalOpen(true)}
        buttonText={"Add Severity"}
        titleVariant="h4"
        isButtonVisible={!isDnv && !isReadOnly}
      />

      <TableContainer component={Paper}>
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>Name</TableCell>
              {mappedSeverities?.[0]?.customColumns?.map((customColumn, i) => (
                <TableCell key={i}>{customColumn.name}</TableCell>
              ))}
              <TableCell sx={{ width: "5%" }}></TableCell>
              <TableCell sx={{ width: "5%" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappedSeverities?.map((customSeverity) => (
              <TableRow key={customSeverity.id}>
                <TableCell>{customSeverity.index}</TableCell>
                <TableCell>{customSeverity.name}</TableCell>
                {customSeverity?.customColumns.map((x) => (
                  <TableCell key={`${x.id}-column`}>{x.value}</TableCell>
                ))}
                {!isDnv && !isReadOnly && (
                  <>
                    <TableCell align="right" sx={{ padding: "4px" }}>
                      <IconButton
                        onClick={() => {
                          setIsDeleteConfirmationOpen(true);
                          setSelectedSeverity(customSeverity);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ padding: "4px", background: "#fff" }}
                    >
                      <IconButton
                        onClick={() => {
                          setIsEditModalOpen(true);
                          setSelectedSeverity(customSeverity);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isEditModalOpen && selectedSeverity && (
        <EditSeverityModal
          isOpen={isEditModalOpen}
          handleClose={() => setIsEditModalOpen(false)}
          severity={selectedSeverity}
        />
      )}

      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        handleClose={() => setIsDeleteConfirmationOpen(false)}
        title={"Delete severity"}
        text={"Are you sure you want to delete severity?"}
        onAgree={() => {
          // TODO: Write logic for deleting custom severity
          deleteSeverity(selectedSeverity!.id);
          setIsDeleteConfirmationOpen(false);
          // deleteSession(selectedSession!.id);
          // setIsDeleteConfirmationOpen(false);
        }}
      />
    </>
  );
};

export default CustomSeverity;
