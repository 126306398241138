import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  RawScenarioRiskClassification,
  useGetMatrixClassifications,
  useUpsertMatrixClassification
} from "api/classifications";
import { useGetSelectedMatrixLikelihoods } from "api/likelihood";
import {
  useGetSelectedMatrix,
  useGetSelectedMatrixRiskRanks
} from "api/matrixes";
import { useGetSelectedSeverity } from "api/severity";
import Loader from "components/Loader";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { CELL_LENGTH, OFFSET } from "./constants";

type SquareProps = {
  color: string;
  text: string;
  cellLength: number;
  square?: RawScenarioRiskClassification;
  onClick?: () => void;
};

const Square = ({ color, text, cellLength, onClick }: SquareProps) => {
  if (!onClick)
    return (
      <Box
        sx={{
          display: "flex",
          width: cellLength,
          height: cellLength,
          backgroundColor: color,
          border: `1px solid #fff`,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {text === '0' ? <span>0</span> : <strong>{text}</strong>}
      </Box >)

  return (
    <Box
      sx={{
        display: "flex",
        width: cellLength,
        height: cellLength,
        backgroundColor: color,
        border: `1px solid #fff`,
        justifyContent: "center",
        alignItems: "center"
      }}
      onClick={onClick}
    >
      {text}
    </Box >
  );
};

type RouteProps = {
  projectId: string;
};

type Props = {
  settings: { isSmaller?: boolean, dataMatrix?: number[][], isDnv: boolean, isReadOnly: boolean }
};

const Squares = ({ settings }: Props) => {
  const { projectId } = useParams<RouteProps>() as RouteProps;
  const { isSmaller, dataMatrix, isDnv, isReadOnly } = settings;

  const { data: selectedMatrix } = useGetSelectedMatrix(projectId);
  const { data: classifications } = useGetMatrixClassifications(
    projectId,
    selectedMatrix?.id
  );
  const { data: likelihoods } = useGetSelectedMatrixLikelihoods(projectId);
  const { data: severities } = useGetSelectedSeverity(projectId);
  const { data: riskRanks } = useGetSelectedMatrixRiskRanks(projectId);

  const { mutateAsync: updateClassification } = useUpsertMatrixClassification(
    projectId,
    selectedMatrix?.id
  );

  const [selectedRiskRankId, setSelectedRiskRankId] = useState(0);

  if (!likelihoods || !severities || !selectedMatrix || !classifications || !riskRanks)
    return <Loader />;

  const cellLength = isSmaller ? CELL_LENGTH / 2 : CELL_LENGTH;
  const offset = OFFSET;

  const dimensions = severities?.length || 0;
  const width = dimensions * cellLength;

  const handleUpdateClassification = async (
    classification: RawScenarioRiskClassification,
    newRiskRankId: number
  ) => {
    await updateClassification({
      ...classification,
      riskRankId: newRiskRankId
    });
  };

  const stylesForBig = { display: "flex", justifyContent: "space-between", gap: '64px' };
  const stylesForSmall = { display: "flex", justifyContent: "flex-start", gap: '24px' };
  const stylesForBoxesMatrix = {
    height: width, display: "flex", flexWrap: "wrap", flexDirection: "column",
    flex: '0 0 auto', minWidth: `${(severities?.length || 0) * 50 + 40}px`
  }

  return (
    <Box sx={isSmaller ? stylesForSmall : stylesForBig}>
      <Box sx={stylesForBoxesMatrix}>
        {severities?.map((severity) => {
          return (
            <Typography
              key={severity.id}
              sx={{
                width: offset,
                height: cellLength,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: grey[500],
                borderLeft: `1px solid ${grey[300]}`
              }}
            >
              {severity.index}
            </Typography>
          );
        })}

        {likelihoods?.map((likelihood) => severities?.map((severity) => {
          const classification =
            classifications?.find(c => c.likelihoodId === likelihood.id && c.severityId === severity.id) ||
            { likelihoodId: likelihood.id, severityId: severity.id, riskRankId: 0 };

          const riskRank = riskRanks?.find((riskRank) => riskRank.id === classification.riskRankId);
          const riskName = riskRank?.name || "N/A";
          const numberValue = dataMatrix?.[likelihood.index]?.[severity.index] || 0;

          return (
            <Square
              key={`${classification.likelihoodId}-${classification.severityId}`}
              color={riskRank?.color || "white"}
              text={!isSmaller ? riskName : numberValue.toString()}
              cellLength={cellLength}
              onClick={!isSmaller ? () => {
                if (selectedRiskRankId) {
                  handleUpdateClassification(classification, selectedRiskRankId);
                }
              } : undefined}
            />
          );
        }))}
      </Box>

      {isSmaller && (
        <Box sx={{
          display: "flex",
          flexDirection: "column-reverse",
          gap: '10px',
          alignContent: 'flex-start'
        }}>
          {riskRanks?.map((riskRank) => {
            return (
              <Box key={riskRank.id}
                sx={{
                  width: cellLength * 3, display: "flex",
                  alignItems: 'center',
                  gap: '10px'
                }}><Square
                  color={riskRank.color}
                  text={''}
                  cellLength={cellLength}
                />{riskRank.name}</Box>
            );
          })}
        </Box>
      )
      }
      {
        !isDnv && !isReadOnly && (
          <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
            {riskRanks?.map((riskRank) => {
              return (
                <Square
                  key={riskRank.id}
                  color={riskRank.color}
                  text={riskRank.name}
                  cellLength={cellLength}
                  onClick={() => setSelectedRiskRankId(riskRank.id)}
                />
              );
            })}
          </Box>
        )
      }
    </Box >
  );
};

export default Squares;
