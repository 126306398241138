import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, TextField } from "@mui/material";
import { useGetSelectedMatrix } from "api/matrixes";
import { useController, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { useAddCustomRiskRank } from "../../../../../api/risk-rank";
import ModalForm from "../../../../../components/ModalForm";
import ChromePicker from "react-color/lib/components/chrome/Chrome";

export const RiskRanking = z.object({
  index: z.number({ required_error: "Index is required" }),
  name: z.string({ required_error: "Name is required" }).min(1, "Name is required"),
  description: z.string({ required_error: "Description is required" }).min(1, "Description is required"),
  color: z.string({ required_error: "Color is required" }).min(1, "Color is required")
});

export type RiskRankingType = z.infer<typeof RiskRanking>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

type RouteParams = {
  projectId: string;
  assetId: string;
};

const AddRiskRankModal = ({ isOpen, handleClose }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm<RiskRankingType>({
    resolver: zodResolver(RiskRanking)
  });
  const { field } = useController({
    name: "color",
    control,
    defaultValue: "#FFF"
  });

  const { projectId } = useParams<RouteParams>() as RouteParams;

  const { mutateAsync: addCustomRiskRank, isLoading } = useAddCustomRiskRank(projectId);
  const { data: selectedMatrix } = useGetSelectedMatrix(projectId);

  return (
    <ModalForm
      title={"Add custom risk rank"}
      isOpen={isOpen}
      handleClose={() => { reset(); handleClose(); }}
      onSubmit={handleSubmit(async (newCustomRiskRanking) => {
        await addCustomRiskRank({
          ...newCustomRiskRanking,
          riskMatrixId: selectedMatrix!.id
        });
        reset();
        handleClose();
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("index", {
              valueAsNumber: true
            })}
            inputMode="numeric"
            error={!!errors.index?.message}
            helperText={errors.index?.message}
            label={`Index *`}
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            label={`Name *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("description")}
            error={!!errors.description?.message}
            helperText={errors.description?.message}
            label={`Description *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={7}>
          <TextField
            {...register("color")}
            value={field.value}
            onChange={field.onChange}
            error={!!errors.color?.message}
            helperText={errors.color?.message}
            label={`Color *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={5}>
          <ChromePicker
            {...field}
            onChange={(newColor) => {
              field.onChange(newColor.hex);
            }}
            color={field.value}
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default AddRiskRankModal;
