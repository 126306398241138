import { Box, Typography } from "@mui/material";
import { CELL_LENGTH, OFFSET } from "./constants";
import { grey } from "@mui/material/colors";
import { Likelihood } from "types";
import { SeverityDto, useGetSelectedSeverity } from "api/severity";

import { useEffect } from "react";
import { useGetSelectedMatrixLikelihoods } from "api/likelihood";
import { useParams } from "react-router-dom";

import Loader from "components/Loader";
import Squares from "./Squares";

export const RiskClassificationSquare = ({ severities, likelihoods, settings }: { severities: SeverityDto[], likelihoods: Likelihood[], settings: { isSmaller?: boolean, dataMatrix?: number[][], isDnv: boolean, isReadOnly: boolean } }) => {
  const { isSmaller } = settings;

  const cellLength = isSmaller ? CELL_LENGTH / 2 : CELL_LENGTH;
  const offset = OFFSET;

  const severityTitleStyles = {
    transform: `rotate(90deg)`,
    transformOrigin: `0 0`,
    width: severities.length * cellLength,
    textAlign: "center",
    position: "absolute",
    top: isSmaller ? '74px' : '104px',
  };
  const likelihoodTitleStyles = {
    textAlign: "center",
    width: likelihoods.length * cellLength
  };

  const likelihoodNumberStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: offset,
    width: cellLength,
    borderTop: `1px solid ${grey[300]}`,
    color: grey[500]
  };

  return <Box sx={{ marginLeft: `${offset}px`, position: 'relative' }}>
    <Typography variant="h6" sx={severityTitleStyles}> Severities </Typography>

    <Box sx={{ marginLeft: `${offset}px` }}>
      <Typography variant="h6" sx={likelihoodTitleStyles}> Likelihood </Typography>

      <Box sx={{ display: "flex" }}>
        {likelihoods.map((likelihood) =>
          <Typography key={likelihood.id} sx={likelihoodNumberStyles}> {likelihood.index} </Typography>
        )}
      </Box>
    </Box>

    <Squares settings={settings} />
  </Box>
}

type RouteProps = {
  projectId: string;
};

type Props = {
  isReadOnly: boolean;
  isDnv?: boolean;
  noTitle?: boolean;
};

const CustomRiskClassification = ({ isReadOnly, isDnv = false }: Props) => {
  const { projectId } = useParams<RouteProps>() as RouteProps;
  const { data: likelihoods } = useGetSelectedMatrixLikelihoods(projectId);
  const { data: severities } = useGetSelectedSeverity(projectId);

  useEffect(() => {
    if (document.location.hash !== '')
      document.querySelector(document.location.hash)?.scrollIntoView(
        { behavior: "smooth", inline: "nearest" }
      );
  }, [likelihoods, severities])

  if (!likelihoods || !severities) return <Loader />;

  return (
    <Box sx={{ marginLeft: "1rem" }} id="risk-classification">
      <Typography variant="h4">Risk classification</Typography>

      <RiskClassificationSquare severities={severities} likelihoods={likelihoods} settings={{ isReadOnly: isReadOnly, isDnv: isDnv }} />
    </Box>
  );
};

export default CustomRiskClassification;
