import axios from "axios";
import { handleError } from "helpers/handleError";
import { useMutation, useQuery, useQueryClient } from "react-query";

export type NewLineCoordsItem = {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};

export type LineCoordsItem = {
  id: number;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};

export const useGetLineCoords = (nodeId: string, fileId: string) => {
  return useQuery(
    ["line-coords", nodeId, fileId],
    async () => {
      const { data } = await axios.get<LineCoordsItem[]>(
        `/api/files/${fileId}/markups/${nodeId}`
      );
      return data;
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const useGetAllLineCoords = (nodeIds: number[], fileId: string) => {
  return useQuery(
    ["line-coords", nodeIds, fileId],
    async () => {
      const results = await axios
        .all(
          nodeIds.map((nodeId) =>
            axios.get<LineCoordsItem[]>(
              `/api/files/${fileId}/markups/${nodeId.toString()}`
            )
          )
        )
        .then(async (resultArr) => resultArr.map((result) => result.data));
      return results;
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const useAddLineCoords = (nodeId: string, fileId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (newLineCoords: NewLineCoordsItem) => {
      const { data: lineCoordsFromApiResponse } =
        await axios.post<LineCoordsItem>(
          `/api/files/${fileId}/markups/${nodeId}`,
          newLineCoords
        );
      queryClient.invalidateQueries(["line-coords", nodeId, fileId]);

      return lineCoordsFromApiResponse;
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const useDeleteLineCoords = (nodeId: string, fileId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      try {
        await axios.delete(`/api/nodeMarkups/${nodeId}/elements`);
      } catch (error) {}

      const { data } = await axios.delete(
        `/api/files/${fileId}/markups/${nodeId}`
      );
      queryClient.invalidateQueries(["line-coords", nodeId, fileId]);

      return data;
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const useDeleteDotCoords = (nodeId: string, fileId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (dotId: number) => {
      const { data } = await axios.delete(
        `/api/files/${fileId}/markups/${nodeId}/${dotId.toString()}`
      );

      queryClient.invalidateQueries(["line-coords", nodeId, fileId]);

      return data;
    },
    {
      onError: handleError<unknown>
    }
  );
};
