import axios, { AxiosError } from "axios";
import { handleError } from "helpers/handleError";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { NodeDto, StatusDto } from "types";

export interface Tag {
  tag: string;
  name: string;
}
interface NodeAddDto {
  nodeIdentifier: string;
  name: string;
  designIntention: string;
  operatingConditions: string;
  comments?: string;
  fileIds: number[];
}
export interface UpdateNodeDto {
  name: string;
  designIntention: string;
  operatingConditions: string;
  comments?: string;
  isCompleted?: boolean;
  fileIds?: number[];
}

export const addNode = async (projectId: string, newNode: NodeAddDto) => {
  const { data: node } = await axios.post<NodeDto>(
    `/api/projects/${projectId}/nodes`,
    { ...newNode, isCompleted: false }
  );
  return node;
};

export const useAddNode = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation((newNode: NodeAddDto) => addNode(projectId, newNode), {
    onError: handleError<{ guideword?: string; parameter?: string }>,
    onSuccess: () => {
      toast.success("Successfully added new node.");
      queryClient.invalidateQueries(["nodes", projectId]);
    }
  });
};

export const editNode = async (
  projectId: string,
  nodeId: number,
  updatedNode: UpdateNodeDto
) => {
  const { data: node } = await axios.put<NodeDto>(
    `/api/projects/${projectId}/nodes/${nodeId}`,
    updatedNode
  );
  return node;
};

export const useEditNode = (projectId: string, nodeId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (updatedNode: UpdateNodeDto) => editNode(projectId, nodeId, updatedNode),
    {
      onError: handleError<{ guideword?: string; parameter?: string }>,
      onSuccess: () => {
        toast.success("Successfully edited node.");
        queryClient.invalidateQueries(["nodes", projectId]);
      }
    }
  );
};

export const patchNode = async (
  projectId: string,
  nodeId: number,
  updatedNode: { isCompleted: boolean }
) => {
  const { data: isSuccess } = await axios.patch<NodeDto>(
    `/api/projects/${projectId}/nodes/${nodeId}`,
    updatedNode
  );
  return isSuccess;
};

export const usePatchNode = (projectId: string, nodeId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (updatedNode: { isCompleted: boolean }) =>
      patchNode(projectId, nodeId, updatedNode),
    {
      onError: handleError<{ guideword?: string; parameter?: string }>,
      onSuccess: () => {
        toast.success("Successfully changed node status.");
        queryClient.invalidateQueries(["nodes", projectId]);
      }
    }
  );
};

export const useGetDrawingTags = (drawingId: number) => {
  return useQuery(["drawings/tags", drawingId], async () => {
    const { data } = await axios.get<Tag[]>(
      `/api/projects/drawings/${drawingId}/tags`
    );
    return data;
  });
};

export const useGetNodes = (projectId: string) => {
  return useQuery(
    ["nodes", projectId],
    async () => {
      const { data } = await axios.get<NodeDto[]>(
        `/api/projects/${projectId}/nodes`
      );
      return data;
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const deleteNode = async (projectId: string, nodeId: number) => {
  const { data: isSuccess } = await axios.delete<boolean>(
    `/api/projects/${projectId}/nodes/${nodeId}`
  );
  return isSuccess;
};

export const useDeleteNode = (projectId: string, nodeId: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => deleteNode(projectId, nodeId), {
    onError: handleError<unknown>,
    onSuccess: () => {
      toast.success("Successfully deleted node.");
      queryClient.invalidateQueries(["nodes", projectId]);
    }
  });
};

export const handleErrorGetFileStatus = (error: AxiosError<StatusDto>) => {
  console.error(error);
};

const getFileStatus = async (
  projectId: string,
  fileMetadataId: number,
  isProcessed: boolean
) => {
  try {
    const { data } = await axios.get<StatusDto>(
      `/api/studysetup/${projectId}/status/${fileMetadataId}`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<StatusDto>;
    throw error.response?.data;
  }
};

export const useGetFileStatus = (
  projectId: string,
  fileMetadataId: number,
  isProcessed: boolean
) => {
  return useQuery(
    ["file-status", fileMetadataId],
    async () => getFileStatus(projectId, fileMetadataId, isProcessed),
    {
      // onError: handleErrorGetFileStatus,
      enabled: isProcessed,
      refetchInterval: (statusDto) => {
        // @ts-ignore
        if (statusDto?.status?.length > 0) return 60000;
        return false;
      }
    }
  );
};
