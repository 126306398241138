import EventNoteIcon from "@mui/icons-material/EventNote";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useGetAsset } from "api/assets";
import { useGetProfile } from "api/users";
import Loader from "components/Loader";
import { isAuthorized, RoleEnum, rolePrioritization } from "helpers/isAuthorized";
import { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import TitleWithAddButton from "../../components/TitleWithAddButton";
import AddTimeframeModal from "./AddTimeframeModal";

type RouteParams = {
  assetId: string;
  projectId: string;
};

type Props = {};

const WorkshopTabs = () => {
  const [tab, setTab] = useState(0);
  const { pathname } = useLocation();

  // When user refreshes make sure the url sets active tab properly
  useEffect(() => {
    if (pathname.includes("schedule")) {
      setTab(0);
    } else {
      setTab(1);
    }
  }, [pathname]);

  const tabDescription =
    tab === 1
      ? "Set a time frame for the workshop"
      : "Keep track of workshop sessions and attendance";

  return (
    <Box sx={{ textAlign: "center" }}>
      <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)} centered>
        <Tab
          label="Schedule"
          to="schedule"
          component={Link}
          icon={<ScheduleIcon />}
          iconPosition="start"
          sx={{ paddingBottom: 0 }}
        />
        <Tab
          label="Sessions"
          to="sessions"
          component={Link}
          icon={<EventNoteIcon />}
          iconPosition="start"
          sx={{ paddingBottom: 0 }}
        />
      </Tabs>
      <Typography sx={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
        {tabDescription}
      </Typography>
    </Box>
  );
};

const Workshop = (props: Props) => {
  const { assetId } = useParams<RouteParams>() as RouteParams;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: profile } = useGetProfile();
  const { data: asset } = useGetAsset(assetId);

  const organizationRoles: RoleEnum[] = useMemo(() =>
    rolePrioritization([], profile?.organizationRoles, asset?.organizationId)
    , [asset, profile])


  if (!profile || !asset) return <Loader />;

  return (
    <>
      <TitleWithAddButton
        title={"Workshop schedule"}
        textUnderTitle={"Set workshop time and manage workshop"}
        modal={
          <AddTimeframeModal
            isOpen={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
          />
        }
        openModal={() => setIsModalOpen(true)}
        buttonText={"Add time frame"}
        isButtonVisible={isAuthorized({
          role: profile?.roleName as RoleEnum,
          requiredRoles: [RoleEnum.Administrator, RoleEnum.OrganizationOwner],
          organizationRoles
        })}
      />

      <WorkshopTabs />

      <Box sx={{ width: "100%" }}>
        <Outlet />
      </Box>
    </>
  );
};

export default Workshop;
