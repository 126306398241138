import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowsProp
} from "@mui/x-data-grid";
import { useGetParticipants } from "api/participants";
import {
  useDeleteSession,
  useGetTimeframeSessions
} from "api/timeframe-sessions";
import ConfirmationDialog from "components/ConfirmationDialog";
import moment from "moment";
import { formatDateToMinutes } from "helpers/commonHelpers";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { TimeframeDto, TimeframeSession } from "types";
import EditSessionModal from "./EditSessionModal";

type RouteParams = {
  projectId: string;
};

type Props = {
  timeframe: TimeframeDto;
  isEditable: boolean;
};

const SessionDatagrid = ({ timeframe, isEditable }: Props) => {
  const { projectId } = useParams<RouteParams>() as RouteParams;

  const { data: sessions } = useGetTimeframeSessions(timeframe.id);
  const { data: participants } = useGetParticipants(projectId);

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedSession, setSelectedSession] =
    useState<TimeframeSession | null>(null);
  const { mutateAsync: deleteSession } = useDeleteSession(timeframe.id);

  const rows: GridRowsProp =
    sessions?.map((session) => ({
      id: session.id,
      sessionTime: moment(session.sessionTime),
      durationMinutes: session.durationMinutes,
      facilitatorId: session.facilitatorId,
      scribeId: session.scribeId,
      comments: session.comments,
      participantIds: session.participants || []
    })) ?? [];

  const columns: GridColDef[] = [
    {
      flex: 1,
      field: "sessionTime",
      headerName: "Session",
      valueGetter: (value) => formatDateToMinutes(value)
    },
    {
      flex: 1,
      field: "durationMinutes",
      headerName: "Duration (minutes)"
    },
    {
      flex: 1,
      field: "facilitatorId",
      headerName: "Facilitator",
      valueGetter: (value) => participants?.find((p) => p.userId.toString() === '' + value)?.name || ''
    },
    {
      flex: 1,
      field: "scribeId",
      headerName: "Scribe",
      valueGetter: (value) => participants?.find((p) => p.userId.toString() === '' + value)?.name || ''
    },
    {
      flex: 1,
      field: "comments",
      headerName: "Comments",
      valueGetter: (value) => value
    },
    {
      flex: 1,
      field: "participantIds",
      headerName: "Participants",
      valueGetter: (value: number[]) => value.map(v => participants?.find((p) => p.userId.toString() === '' + v)?.name || '').join(', ')
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) =>
        [
          // @ts-ignore
          <GridActionsCellItem
            icon={<DeleteIcon />}
            onClick={() => {
              setIsDeleteConfirmationOpen(true);
              setSelectedSession(params.row);
            }}
            label="Delete"
          />,
          // @ts-ignore
          <GridActionsCellItem
            icon={<EditIcon />}
            onClick={() => {
              setIsEditModalOpen(true);
              setSelectedSession(params.row);
            }}
            label="Edit"
          />
        ].filter((x) => isEditable)
    }
  ];

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        disableRowSelectionOnClick
      />

      {isEditModalOpen && selectedSession && (
        <EditSessionModal
          isOpen={isEditModalOpen}
          handleClose={() => setIsEditModalOpen(false)}
          session={selectedSession}
          timeframe={timeframe}
        />
      )}

      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        handleClose={() => setIsDeleteConfirmationOpen(false)}
        title={"Delete session"}
        text={"Are you sure you want to delete session?"}
        onAgree={() => {
          deleteSession(selectedSession!.id);
          setIsDeleteConfirmationOpen(false);
        }}
      />
    </div>
  );
};

export default SessionDatagrid;
